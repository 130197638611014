import {
  ADD_CHAT_IN_LIST,
  DELETE_CHAT_FROM_LIST,
  EDIT_CHAT_NAME,
  EDIT_CHAT_NAME_IN_LIST,
  GET_ALL_CHAT_LIST,
  IS_LOADING_CHAT,
  SELECTED_CHAT_ID,
  SELECTED_CHAT_MESSAGES_ID,
  SEND_USER_QUESTION_IN_CHAT,
  SET_ALL_CHAT_LIST
} from "./constants";

export const getAllChatListAction = value => {
  return {
    type: GET_ALL_CHAT_LIST,
    payload: value
  };
};

export const setAllChatListAction = value => {
  return {
    type: SET_ALL_CHAT_LIST,
    payload: value
  };
};
export const selectedEditChatIdAction = value => {
  return {
    type: SELECTED_CHAT_ID,
    payload: value
  };
};
export const editChatNameAction = value => {
  return {
    type: EDIT_CHAT_NAME,
    payload: value
  };
};
export const deleteChatFromListAction = value => {
  return {
    type: DELETE_CHAT_FROM_LIST,
    payload: value
  };
};
export const editChatNameInListAction = value => {
  return {
    type: EDIT_CHAT_NAME_IN_LIST,
    payload: value
  };
};
export const addChatInListAction = value => {
  return {
    type: ADD_CHAT_IN_LIST,
    payload: value
  };
};
export const selectedChatMessageIdAction = value => {
  return {
    type: SELECTED_CHAT_MESSAGES_ID,
    payload: value
  };
};
export const sendUserQuestionAction = value => {
  return {
    type: SEND_USER_QUESTION_IN_CHAT,
    payload: value
  };
};
export const isLoadingChatAction = value => {
  return {
    type: IS_LOADING_CHAT,
    payload: value
  };
};
