// // const awsmobile = {
// //   aws_project_region: "ap-south-1",
// //   aws_cognito_identity_pool_id:
// //     "ap-south-1:ee88e8ad-4fb9-4895-9737-6422dbdf287b",
// //   aws_cognito_region: "ap-south-1",
// //   aws_user_pools_id: "ap-south-1_0bEyQp0y4",
// //   aws_user_pools_web_client_id: "1rtdbep8jrlk68nu4asn0s92v3",
// //   bucket: "smart-contract-nft",
// //   ACCESS_KEY_ID: "AKIA47ZIJEVO67IOYK6V",
// //   SECRET_ACCESS_KEY: "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",
// //   aws_user_files_s3_bucket_region: "ap-south-1",
// //   oauth: {}
// // };

// const awsmobile = {
//   aws_project_region: "us-east-1",
//   aws_cognito_identity_pool_id: "us-east-1:9b2bfe98-5497-46fc-80f0-8eedd1ba8d59",
//   aws_cognito_region: "us-east-1",
//   aws_user_pools_id: "us-east-1_cJQGd04jM",
//   aws_user_pools_web_client_id: "5p95qud4aka9bs2msrcljm63e9",
//   bucket: "smart-contract-nft",
//   ACCESS_KEY_ID: "AKIA47ZIJEVO67IOYK6V",
//   SECRET_ACCESS_KEY: "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",
//   aws_user_files_s3_bucket_region: "ap-south-1",
//   oauth: {}
// };

// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:9b2bfe98-5497-46fc-80f0-8eedd1ba8d59",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_cJQGd04jM",
//     "aws_user_pools_web_client_id": "5p95qud4aka9bs2msrcljm63e9",
//     "bucket": "smart-contract-nft",
//     "ACCESS_KEY_ID": "AKIA47ZIJEVO67IOYK6V",
//     "SECRET_ACCESS_KEY": "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",
//     "aws_user_files_s3_bucket_region": "ap-south-1",
//     "oauth": {
//         "domain": "metaontheblock164d0383-164d0383-dev.auth.us-east-1.amazoncognito.com",
//         "scope": [
//             "phone",
//             "email",
//             "openid",
//             "profile",
//             "aws.cognito.signin.user.admin"
//         ],
//         "redirectSignIn": "http://localhost:3000/quickMinter/",
//         "redirectSignOut": "http://localhost:3000/quickMinter/",
//         "responseType": "token"
//     },
//     "federationTarget": "COGNITO_USER_POOLS",
//     "aws_cognito_username_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_social_providers": [
//         "GOOGLE"
//     ],
//     "aws_cognito_signup_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_mfa_configuration": "OFF",
//     "aws_cognito_mfa_types": [
//         "SMS"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": []
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ]
// };

//client credential

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:9d2b49c6-7170-4363-9bbb-a10b42d3d438",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_Z8XPrKSun",
  aws_user_pools_web_client_id: "6ocdjece5fhavijl3fpgd0tck8",
  oauth: {
    domain: "polyadmin79c8d80e-79c8d80e-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://srslynfts.xyz/quickMinter/",
    redirectSignOut: "https://srslynfts.xyz/quickMinter/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

//old credential

// const awsmobile = {
//   aws_project_region: "us-east-1",
//   aws_cognito_identity_pool_id:
//     "us-east-1:9b2bfe98-5497-46fc-80f0-8eedd1ba8d59",
//   aws_cognito_region: "us-east-1",
//   aws_user_pools_id: "us-east-1_cJQGd04jM",
//   aws_user_pools_web_client_id: "5p95qud4aka9bs2msrcljm63e9",
//   bucket: "smart-contract-nft",
//   ACCESS_KEY_ID: "AKIA47ZIJEVO67IOYK6V",
//   SECRET_ACCESS_KEY: "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",
//   aws_user_files_s3_bucket_region: "ap-south-1",
//   oauth: {
//     domain:
//       "metaontheblock164d0383-164d0383-dev.auth.us-east-1.amazoncognito.com",
//     scope: [
//       "phone",
//       "email",
//       "openid",
//       "profile",
//       "aws.cognito.signin.user.admin",
//     ],
//     redirectSignIn: "http://localhost:3000/quickMinter/",
//     redirectSignOut: "http://localhost:3000/quickMinter/",
//     responseType: "token",
//   },
//   federationTarget: "COGNITO_USER_POOLS",
//   aws_cognito_username_attributes: ["EMAIL"],
//   aws_cognito_social_providers: ["GOOGLE"],
//   aws_cognito_signup_attributes: ["EMAIL"],
//   aws_cognito_mfa_configuration: "OFF",
//   aws_cognito_mfa_types: ["SMS"],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [],
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"],
// };

export default awsmobile;
