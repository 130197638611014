import { getAxiosInstance } from "../../utils/axios";

const API = "http://143.244.187.173/";

export const editprofileApi = async ({ values }) => {
  try {
    let res = await getAxiosInstance().put(`${API}updateUserData`, values);
    return res;
  } catch (err) {
    return { err };
  }
};

export const setWalletAddress = async (values) => {
  try {
    let res = await getAxiosInstance().post(`${API}setWalletAddress`, {
      walletAddress: values,
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};
