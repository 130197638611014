import { call, put, takeEvery } from "redux-saga/effects";
import { setRequestPendingLoader, setRequestedData } from "./action";
import { getRequestedData } from "./api";
import { GET_REQUESTED_PENDING_POLICIES_DATA } from "./constant";

function* getRequestedPolicies(action) {
  try {
    const response = yield call(getRequestedData);

    if (response.status === 200) {
      yield put(setRequestedData(response.data));
      yield put(setRequestPendingLoader(false));
    }
  } catch (error) {
    console.log(error);
    yield put(setRequestPendingLoader(false));
  }
}

export function* RequestedPendingPoliciesSaga() {
  yield takeEvery(GET_REQUESTED_PENDING_POLICIES_DATA, getRequestedPolicies);
}
