import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";
import React from "react";

const Roadmap = props => {
  const theme = useTheme();
  let checkAddress = window.location.href;
  let check = checkAddress.includes("customLandingPage");

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true
  });

  const timeline = [
    {
      date: "October 2016",
      title: "Secured Series-A funding"
    },
    {
      date: "April 2017",
      title: "New CTO: Joe Schultz"
    },
    {
      date: "October 2017",
      title: "v1.0 Ships"
    },
    {
      date: "February 2018",
      title: "Featured in Wired"
    },
    {
      date: "September 2018",
      title: "v.20 Ships"
    }
  ];

  const TimeLineMobileView = ({ timeline = [] }) => (
    <Grid container spacing={2}>
      {props.data ? (
        props.data.properties.roadmap.data.map((item, i) => (
          <Grid item xs={12} key={i}>
 <Box
              display={"flex"}
              alignItems={"center"}
              data-aos={check ? null : "fade-up"}
            >
              <Box
                width={10}
                height={10}
                borderRadius={"100%"}
                bgcolor={theme.palette.primary.main}
                marginRight={2}
              />
              <Box>
                <Typography
                  variant={"subtitle1"}
                  color={"textSecondary"}
                  gutterBottom
                >
                  {item.percentage}
                </Typography>

                <Typography
                  variant={"h6"}
                  dangerouslySetInnerHTML={{
                    __html: props.data
                      ? draftToHtml(JSON.parse(item.description))
                      : null
                  }}
                />
              </Box>
            </Box>
           
          </Grid>
        ))
      ) : (
        <>
          {timeline.map((item, i) => (
            <Grid item xs={12} key={i}>
              <Box
                display={"flex"}
                alignItems={"center"}
                data-aos={check ? null : "fade-up"}
              >
                <Box
                  width={10}
                  height={10}
                  borderRadius={"100%"}
                  bgcolor={theme.palette.primary.main}
                  marginRight={2}
                />
                <Box>
                  <Typography
                    variant={"subtitle1"}
                    color={"textSecondary"}
                    gutterBottom
                  >
                    {item.date}
                  </Typography>
                  <Typography variant={"h6"}>{item.title}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );

  TimeLineMobileView.propTypes = {
    timeline: PropTypes.array.isRequired
  };

  const TimeLineDesktopView = ({ timeline = [] }) => (
    <Grid container spacing={2}>
      {props.data ? (
        props.data.properties.roadmap.data.map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box
              paddingBottom={4}
              display={"flex"}
              alignItems={"center"}
              flexDirection={i % 2 === 1 ? "row-reverse" : "row"}
              marginRight={i % 2 === 1 ? "calc(50% - 5px)" : 0}
              marginLeft={i % 2 !== 1 ? "calc(50% - 5px)" : 0}
              data-aos={check ? null : i % 2 === 1 ? "fade-right" : "fade-left"}
            >
              {item.percentage && (
           <Box
                width={10}
                height={10}
                borderRadius={"100%"}
                bgcolor={theme.palette.primary.main}
                marginRight={i % 2 !== 1 ? 5 : 0}
                marginLeft={i % 2 === 1 ? 5 : 0}
              />
              )}
              
              <Box>
                <Typography
                  variant={"subtitle1"}
                  color={"textSecondary"}
                  gutterBottom
                >
                  {item.percentage}
                </Typography>
                <Typography
                  variant={"h6"}
                  dangerouslySetInnerHTML={{
                    __html: props.data
                      ? draftToHtml(JSON.parse(item.description))
                      : null
                  }}
                ></Typography>
              </Box>
            </Box>
          </Grid>
        ))
      ) : (
        <>
          {timeline.map((item, i) => (
            <Grid item xs={12} key={i}>
              <Box
                paddingBottom={4}
                display={"flex"}
                alignItems={"center"}
                flexDirection={i % 2 === 1 ? "row-reverse" : "row"}
                marginRight={i % 2 === 1 ? "calc(50% - 5px)" : 0}
                marginLeft={i % 2 !== 1 ? "calc(50% - 5px)" : 0}
                data-aos={
                  check ? null : i % 2 === 1 ? "fade-right" : "fade-left"
                }
              >
                <Box
                  width={10}
                  height={10}
                  borderRadius={"100%"}
                  bgcolor={theme.palette.primary.main}
                  marginRight={i % 2 !== 1 ? 5 : 0}
                  marginLeft={i % 2 === 1 ? 5 : 0}
                />
                <Box>
                  <Typography
                    variant={"subtitle1"}
                    color={"textSecondary"}
                    gutterBottom
                  >
                    {item.date}
                  </Typography>
                  <Typography variant={"h6"}>{item.title}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );

  TimeLineDesktopView.propTypes = {
    timeline: PropTypes.array.isRequired
  };

  return (
    <>
      <Box>
        <Box>
          {props.data ? (
            <Box>
              <Box marginBottom={4}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "medium"
                  }}
                  gutterBottom
                  color={"secondary"}
                  align={isSm ? "center" : "left"}
                >
                  {props.data
                    ? props.data.properties.roadmap.title.value
                    : "Roadmap Title"}
                </Typography>
                <Typography
                  component={Typography}
                  fontWeight={700}
                  variant={"h3"}
                  align={isSm ? "center" : "left"}
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: props.data
                      ? draftToHtml(
                          JSON.parse(
                            props.data.properties.roadmap.description.value1
                          )
                        )
                      : "Roadmap Description1"
                  }}
                />
                <Typography
                  variant={"h6"}
                  component={"p"}
                  color={"textSecondary"}
                  align={isSm ? "center" : "left"}
                  dangerouslySetInnerHTML={{
                    __html: props.data
                      ? draftToHtml(
                          JSON.parse(
                            props.data.properties.roadmap.description.value2
                          )
                        )
                      : "Roadmap Description2"
                  }}
                />
              </Box>
              {isSm ? (
                <TimeLineDesktopView timeline={timeline} />
              ) : (
                <TimeLineMobileView timeline={timeline} />
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default Roadmap;
