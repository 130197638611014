import { call, put, takeLatest } from "redux-saga/effects";
import { registerUser, verifyUserApi } from "../../api";
// import { messageAction } from "../CustomLandingPage/actions";
import { messageDataAction } from "../Login/actions";
import { signupEmail, toPaymentCheckOutRedirect } from "./actions";
import { chargeApiSaga, paymentDataVerification, sendMailToUser, signupApi } from "./api";
import {
  CHECK_OUT_TO_PAYMENT_PAGE,
  POST_DATA_SIGN_UP,
  SIGNUP_REGISTER,
  VERIFICATION_SIGNUP
} from "./constant";
function* postDataSaga(action) {
  try {
    const result = yield call(signupApi, action.payload);
  } catch (err) {
    console.log(err);
  }
}

function* RegisterSaga(action) {
  try {

    const result = yield call(registerUser, action.payload.data);

    if (result.codeDeliveryDetails) {
      yield put(signupEmail(action.payload.data.username));
      action.payload.history.push("/verificationpage");
    } else if (result.err) {
      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error"
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}
function* verificationsaga(action) {
  try {

    const result = yield call(verifyUserApi, action.payload);

    if (result === "SUCCESS") {
      yield put(
        messageDataAction({
          message: "Successfully SignUp",
          status: "success"
        })
      );
      yield call(sendMailToUser, { email: action.payload.username })
      action.payload.history.push("/login");
    } else if (result.err) {
      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error"
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* redirectToCheckOut(action) {
  try {
    const verifyPayment = yield call(
      paymentDataVerification,
      action.payload.data
    );

    if (verifyPayment.data.orders === 0) {
      yield call(chargeApiSaga, action.payload.chargeData);

      yield put(toPaymentCheckOutRedirect(action.payload.email));
      if (process.env.REACT_APP_STOP_PAYMENT === "false") {
        return;
      }

      action.payload.history.push(
        `/checkout/${action.payload.chargeData.productId}/${action.payload.chargeData.orderId}?newUser=true&email=${action.payload.email}&pass=${action.payload.data.password}`
      );
    } else {
      const result = yield call(registerUser, action.payload.data);

      if (result.codeDeliveryDetails) {
        yield put(signupEmail(action.payload.data.username));
        action.payload.history.push("/verificationpage");
      } else if (result.err) {
        yield put(
          messageDataAction({
            message: result.err.message,
            status: "error"
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* signUpSaga() {
  yield takeLatest(SIGNUP_REGISTER, RegisterSaga);
  yield takeLatest(POST_DATA_SIGN_UP, postDataSaga);
  yield takeLatest(VERIFICATION_SIGNUP, verificationsaga);
  yield takeLatest(CHECK_OUT_TO_PAYMENT_PAGE, redirectToCheckOut);
}

export default signUpSaga;
