export const ADD_USER_EMAILS_POLICIE = "ADD_USER_EMAILS_POLICIE";
export const GET_USER_EMAILS_POLICIE = "GET_USER_EMAILS_POLICIE";
export const SET_ADDED_USER_EMAILS_POLICIE = "SET_ADDED_USER_EMAILS_POLICIE";
export const SET_NEW_ADDED_USER_EMAILS_POLICIE =
  "SET_NEW_ADDED_USER_EMAILS_POLICIE";
export const DELETE_EMAIL_ACTION_POLICIE = "DELETE_EMAIL_ACTION_POLICIE";

export const SET_MODAL_STATUS = "SET_MODAL_STATUS";
export const SET_INVITE_BTN_LOADER = "SET_INVITE_BTN_LOADER";
export const CHANGE_POLICY_ROLE = "CHANGE_POLICY_ROLE";
export const SEND_TOKEN = "SEND_TOKEN";
export const USER_TABLE_LOADER = "USER_TABLE_LOADER";
