// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import AnimateButton from "../../HeaderLayout/ui-component/extended/AnimateButton";

const AuthForgetVerify = props => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const { signupProp, ...others } = props;
  const history = useHistory();
  let referral_id;

  if (window.location.search) {
    let query = queryString.parse(window.location.search);
    referral_id = query.referral_id;
  }

  const handleSubmit = values => {
    let res = props.forgetverification({
      emailId: props.email,
      code: values.verificationCode,
      password: values.password,
      history: history
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Formik
        initialValues={{
          verificationCode: ""
        }}
        onSubmit={async (values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 500);
          await handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(
                touched.verificationCode && errors.verificationCode
              )}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Verification Code
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                value={values.verificationCode}
                name="verificationCode"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.verificationCode && errors.verificationCode && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.verificationCode}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-password-register">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-register"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                label="Password"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-register"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              </AnimateButton>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                cursor: "pointer"
              }}
              onClick={async () => {
                props.forgetverifyEmail({
                  username: props.email,
                  history: history
                });
              }}
            >
              Resend OTP?
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthForgetVerify;
