import { call, put, takeLatest } from "redux-saga/effects";
import { setCreatePolicyModal } from "../../CreatePolicies/Redux/action";
import { setViewPolicyData } from "./action";
import { getViewPolicyapi } from "./api";
import { GET_POLICY_BYID } from "./constant";

function* getAllViewUersData(action) {
  try {
    const response = yield call(getViewPolicyapi, action.payload.id);
    if (response.status == 200) {
      yield put(setViewPolicyData(response.data.data));
      yield put(setCreatePolicyModal(false));
    }
    if (response.status == 400) {
      action.payload.history.push("/createPolicy");
    }
  } catch (err) {
    yield put(setCreatePolicyModal(false));
    action.payload.history.push("/createPolicy");
    console.log(err);
  }
}
function* viewPolicysaga() {
  yield takeLatest(GET_POLICY_BYID, getAllViewUersData);
}
export default viewPolicysaga;
