
export async function mintNftFunction(props, _, mint, nftData) {
  try {
    props.waitingAction(true);

    if (!nftData.nftContract) {
      props.waitingAction(false);
      return false;
    }
    if (props.headerAccount && props.headerAccount[0]) {
      const networkId = await window.ethereum.request({
        method: "net_version"
      });
      if (nftData.nftContract.network === "ethereum") {
        if (networkId !== "4") {
          props.messageAction({
            message: `please switch your metamask chain to etherium.`,
            status: "error"
          });
          props.waitingAction(false);
          return false;
        }
      } else if (nftData.nftContract.network === "polygon_mainnet") {
        if (networkId !== "137") {
          props.messageAction({
            message: `please switch your metamask chain to polygon mainnet.`,
            status: "error"
          });
          props.waitingAction(false);
          return false;
        }
      } else {
        if (networkId !== "80001") {
          props.messageAction({
            message: `please switch your metamask chain to polygon.`,
            status: "error"
          });
          props.waitingAction(false);
          return false;
        }
      }

      if (!mint) {
        props.messageAction({
          message: `please mint atleast 1 NFT.`,
          status: "error"
        });
        props.waitingAction(false);
        return false;
      }
      props.messageAction({
        message: `Successfully minted ${mint} NFTs.`,
        status: "success"
      });
      props.waitingAction(false);
      return true;
    } else {
      props.waitingAction(false);
      return false;
    }
  } catch (error) {
    props.waitingAction(false);
    if (error.code === 4001) {
      props.messageAction({
        message: `You have rejected transaction.`,
        status: "error"
      });
    } else {
      props.messageAction({
        message: `something went wrong please try refreshing page.`,
        status: "error"
      });
    }
    console.log(error);
    return false;
  }
}
