import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getMetricsDataApi = async (data) => {
  try {
    const result = await getAxiosInstance().get(`${API}get_daily_activity`);
    return result;
  } catch (e) {
    console.log(e);
  }
};
