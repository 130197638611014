import { getAxiosInstance } from "../../utils/axios";
import { API } from "../../utils/config";

export const signupApi = async ({ formData, referral_id }) => {
  try {
    let data = await getAxiosInstance().post(`${API}newUserLogin`, {
      family_name: formData.lastName,
      given_name: formData.firstName,
      email: formData.email,
      referral_id
    });
  } catch (err) {
    console.log(err);
  }
};

export const paymentDataVerification = async dat => {
  try {
    let data = await getAxiosInstance().post(`${API}checkUserPaymentStatus`, {
      email: dat.username
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const chargeApiSaga = async value => {
  try {
    let res = await getAxiosInstance().post(`${API}products/stripe/charge`, {
      data: value
    });
    return res;
  } catch (err) {
    console.log("err", err);

    return { err };
  }
};
export const sendMailToUser = async value => {
  try {
    let res = await getAxiosInstance().post(`${API}sendInviterMail`, value);
    return res;
  } catch (e) {
    console.log(e);
  }
};
