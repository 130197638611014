import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getViewPolicyapi = async data => {
  try {

    const result = await getAxiosInstance().get(`${API}getPolicyById/${data}`);
    return result;
  } catch (e) {
    console.log(e);
  }
};
