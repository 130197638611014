import { call, put, select, takeLatest } from "redux-saga/effects";
// import {
//   customTabValueAction,
//   uploadCustomImageTabAction
// } from "../CustomLandingPage/actions";
import { waitingAction } from "../Login/actions";
import {
  customLandingPageLoader,
  deleteLandingPageSuccess,
  fetchLandingDataSuccess,
  headerAirDropData,
  headerColorAction,
  landingPageSuccessData,
  loadingAction,
  messageAction,
  setCustomImageListAction,
  setMatrixTableDataAction
} from "./actions";
import {
  deleteCustomImageApi,
  deleteLandingPagesByUser,
  getCustomImageListApi,
  getLandingPagesByUser,
  getMatrixTableDataApi,
  landingPageDataAPi,
  registerUserApi,
  storeMatrixDataApi,
  uploadCustomImageApi
} from "./api";
import {
  DELETE_CUSTOM_IMAGE,
  DELETE_LANDING_PAGE,
  FETCH_LANDING_PAGE,
  GET_CUSTOM_IMAGE_LIST,
  GET_MATRIX_TABLE_DATA,
  LANDINGPAGE_DATA,
  REGISTER_USER,
  STORE_MATRIX_DATA,
  UPLOAD_CUSTOM_IMAGE
} from "./constants";

function* sagaLandingFetch(action) {
  try {
    yield put(loadingAction(true));
    const result = yield call(getLandingPagesByUser, action.payload);
    yield put(
      fetchLandingDataSuccess({
        landingPages: result.res.data.data,
        imagesArr: result.imagesArr
      })
    );
    yield put(loadingAction(false));
  } catch (error) {
    yield put(loadingAction(false));

    console.log(error);
  }
}

function* deleteLandingSaga(action) {
  try {
    const result = yield call(deleteLandingPagesByUser, action.payload);
    yield put(deleteLandingPageSuccess(action.payload));
    yield put(loadingAction(false));
    if (result === 202) {
      yield put(
        messageAction({
          message: `Successfully deleted landing page`,
          status: "success"
        })
      );
    }
  } catch (error) {
    yield put(loadingAction(false));
    console.log(error);
  }
}

function* landingPageDataSaga(action) {
  try {
    yield put(loadingAction(true));
    yield put(waitingAction(true));

    const response = yield call(landingPageDataAPi, action.payload);

    if (response && response.status === 201) {
      yield put(landingPageSuccessData(response.data.data));
      yield put(customLandingPageLoader(false));
      yield put(waitingAction(false));
      yield put(loadingAction(false));

      if (response.data.data.airdropData && response.data.data.airdropData[0]) {
        yield put(headerAirDropData(response.data.data.airdropData[0]));
      }
      yield put(
        headerColorAction(
          response.data.data.properties.headerTabBar.defaultColor
        )
      );
      yield put(waitingAction(false));
    } else if (response && response.err) {
      yield put(waitingAction(false));
    } else {
      yield put(waitingAction(false));
    }
    yield put(loadingAction(false));
  } catch (error) {
    yield put(loadingAction(false));
    yield put(waitingAction(false));
    console.log(error);
  }
}

function* registerUserSaga(action) {
  try {
    const data = {
      email: action.payload.values,
      landingPageId: action.payload.landingPageId
    };
    const response = yield call(registerUserApi, data);
    if (response && response.status === 201) {
      yield put(
        messageAction({
          message: "Successfully registered",
          status: "success"
        })
      );
      action.payload.setEmail("");
    } else if (response && response.err) {
      yield put(
        messageAction({
          message: response.err.response.data.message,
          status: "error"
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCustomImageListSaga(action) {
  try {
    const response = yield call(getCustomImageListApi);
    if (response.status === 200) {
      yield put(setCustomImageListAction(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* UploadCustomImageSaga(action) {
  try {
    const state = yield select();

    yield put(waitingAction(true));
    const formData = new FormData();

    if (action.payload.attachment) {
      formData.append("attachment", action.payload.attachment);
    }
    if (action.payload.userId) {
      formData.append("userId", action.payload.userId);
    }
    const data = {
      formData: formData
    };
    const response = yield call(uploadCustomImageApi, data);
    if (response && response.status === 200) {
      const array = [
        response.data.data,
        ...state.landingPageData.customImageList
      ];

      yield put(setCustomImageListAction(array));
      // yield put(uploadCustomImageTabAction(""));
      // yield put(customTabValueAction(0));
      yield put(
        messageAction({
          message: `Successfully Upload Image`,
          status: "success"
        })
      );
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));
    console.log(err);
  }
}

function* deleteCustomImageSaga(action) {
  try {
    yield put(waitingAction(true));

    const state = yield select();

    const response = yield call(deleteCustomImageApi, action.payload);
    if (response && response.status === 200) {
      const array = state.landingPageData.customImageList.filter(
        e => e._id != action.payload
      );

      yield put(setCustomImageListAction(array));
      yield put(
        messageAction({
          message: `Successfully Delete Image`,
          status: "success"
        })
      );
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));
    console.log(err);
  }
}
function* storeMatrixDataSaga(action) {
  try {
    const response = yield call(storeMatrixDataApi, action.payload);
    if (response && response.status === 200) {
      yield put(
        messageAction({
          message: `Successfully submitted data.`,
          status: "success"
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}
function* getMatrixTableSaga(action) {
  try {
    yield put(waitingAction(true));
    const response = yield call(getMatrixTableDataApi, action.payload);
    if (response && response.status === 200) {
      yield put(setMatrixTableDataAction(response.data.data));
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));
    console.log(err);
  }
}

function* landingSaga() {
  yield takeLatest(FETCH_LANDING_PAGE, sagaLandingFetch);
  yield takeLatest(DELETE_LANDING_PAGE, deleteLandingSaga);
  yield takeLatest(LANDINGPAGE_DATA, landingPageDataSaga);
  yield takeLatest(REGISTER_USER, registerUserSaga);
  yield takeLatest(GET_CUSTOM_IMAGE_LIST, getCustomImageListSaga);
  yield takeLatest(UPLOAD_CUSTOM_IMAGE, UploadCustomImageSaga);
  yield takeLatest(DELETE_CUSTOM_IMAGE, deleteCustomImageSaga);
  yield takeLatest(STORE_MATRIX_DATA, storeMatrixDataSaga);
  yield takeLatest(GET_MATRIX_TABLE_DATA, getMatrixTableSaga);
  // yield takeLatest(UPDATE_NFT_BLOCKCHAINTYPE, updateNftblockchaintpye);
}

export default landingSaga;
