import { call, put, takeLatest } from "redux-saga/effects";
// import {signupApi,} from './api'
import { forgotPasswordApi, forgotPasswordSubmitApi } from "../../api";
import { messageDataAction, waitingAction } from "../Login/actions";
import { forgotEmail } from "./action";
import {
  FORGOT_PASSWORD_EMAIL,
  FORGOT_VERIFICATION_PASSWORD
} from "./constant";

function* forgotemailSaga(action) {
  try {
    yield put(waitingAction(true));
    const result = yield call(forgotPasswordApi, action.payload);
    if (result.CodeDeliveryDetails) {
      yield put(forgotEmail(action.payload.username));
      action.payload.history.push("/forgotPasswordVerify");
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));

    console.log(err);
  }
}
function* forgotverificationsaga(action) {
  try {
    yield put(waitingAction(true));
    const result = yield call(forgotPasswordSubmitApi, action.payload);
    if (result === "SUCCESS") {
      yield put(
        messageDataAction({
          message: "Successfully Change Password",
          status: "success"
        })
      );
      yield put(waitingAction(false));
      action.payload.history.push("/login");
    } else if (result.err) {
      yield put(waitingAction(false));
      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error"
        })
      );
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));
    console.log(err);
  }
}

function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_EMAIL, forgotemailSaga);
  yield takeLatest(FORGOT_VERIFICATION_PASSWORD, forgotverificationsaga);
}

export default forgotPasswordSaga;
