import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Modal,
  Tab,
  Tabs,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import {
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeTxt,
  BsFiletypeXlsx,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { LiaUploadSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";
import {
  createNewPolicy,
  deletePolicyAction,
  getAllPolicy,
  getAllPolicyUsers,
  getAllStripeAccounts,
  getUserApprovalRequests,
  setCreatePolicyModal,
  setDeleteModal,
  setPolicyDocument,
  setSubmitButtonLoader,
} from "./Redux/action";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CreatePolicies() {
  let hostname = window.location.hostname;
  let host = window.location.origin;

  //*********************** USE-SELECTORS***********************  */

  const messages = useSelector((state) => state.userData.message);
  const activeItem = useSelector((state) => state.headerData.openItem)



  const allPolicies = useSelector(
    (state) => state.CreatePolicesReducer.policies,
  );
  const userSectionData = useSelector(
    (state) => state.CreatePolicesReducer.userRequestedAssignedData,
  );
  const allPolicyUsers = useSelector(
    (state) => state.CreatePolicesReducer.policyUsers,
  );

  const allStripeAccounts = useSelector(
    (state) => state.CreatePolicesReducer.stripeAccounts,
  );

  const userData = useSelector((state) => state.userData.userData);
  const verifyRole = useSelector((state) => state.accountData.data.role);
  const isUserPolicy = useSelector(
    (state) => state.CreatePolicesReducer.isUserAssignPolicy,
  );
  const loaderData = useSelector(
    (state) => state.CreatePolicesReducer.createPolicyLoader,
  );
  const deleteModal = useSelector(
    (state) => state.CreatePolicesReducer.deleteModel,
  );
  const createPolicyModal = useSelector(
    (state) => state.CreatePolicesReducer.createPolicyModal,
  );
  const submitButtonLoader = useSelector(
    (state) => state.CreatePolicesReducer.submitButtonLoader,
  );
  const document = useSelector(
    (state) => state.CreatePolicesReducer.policyDocument,
  );
  const walletAddress = useSelector((state) => state.accountData.walletAddress);

  const validateWalletUsersInfo = useSelector(
    (state) => state.accountData.validateWallet,
  );

  const [valueTab, setValueTab] = React.useState(0);
  const [deletePolicyId, setDeletePolicyId] = useState();
  const [listAssignedUsersOpen, setListAssignedUsersOpen] =
    React.useState(false);
  const [AssignDataForMap, setAssignDataForMap] = React.useState({});

  const [value, setValue] = useState({
    policyName: "",
    policyDescription: "",
    assignedUsers: "",
    hostname,
    host,
    accountId: "",
    amount: null,
  });

  const checkValidation = () => {
    if (selectedOption === "stripe") {
      if (
        value.policyName &&
        value.policyDescription &&
        value.assignedUsers &&
        value.accountId &&
        value.amount
      ) {
        return true;
      }
    } else {
      if (
        value.policyName &&
        value.policyDescription &&
        document &&
        value.assignedUsers
      ) {
        return true;
      }
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let myLoc = window.location.search;
    if (myLoc === "?voteuser=true") {
      dispatch(dispatch(getUserApprovalRequests()));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch(getAllPolicy());
      dispatch(getAllPolicyUsers());
      dispatch(getAllStripeAccounts());
      // dispatch(getPolicyAdminList())
    }
  }, [userData]);

  const fileTypeOFDoc = document?.name.split(".");
  const fileTypeForIcons = document
    ? fileTypeOFDoc[fileTypeOFDoc?.length - 1]
    : "";
  const fileName = document?.name;

  const onDrop = React.useCallback((acceptedFiles) => {
    Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    dispatch(setPolicyDocument(acceptedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleChange = (event, newValue) => {
    if (newValue) {
      dispatch(getUserApprovalRequests());
    } else {
      dispatch(getAllPolicy());
    }
    setValueTab(newValue);
  };

  const [selectedOption, setSelectedOption] = useState("normal");

  useEffect(() => {
    if (window.location.pathname === "/createPolicy") {
      setSelectedOption("normal");
    } else if (window.location.pathname === "/securePayment") {
      setSelectedOption("stripe");
    }
  }, [window.location.pathname]);

  // const handleChangeMethod = (event) => {
  //   setSelectedOption(event.target.value);
  //   setValue({
  //     policyName: "",
  //     policyDescription: "",
  //     assignedUsers: "",
  //     hostname,
  //     host,
  //     accountId: "",
  //     amount: null,
  //   });
  // };

  const assignUsersHandler = (data) => {
    const assignedUsersMap = data?.allAssignUserResponse?.map((e, idx) => {
      return {
        ...e,
        id: idx,
      };
    });
    setAssignDataForMap({
      assignedUsersMap,
      data,
    });
  };

  const forApprovals = allPolicies?.filter((e) =>
    e.assignUser.includes(userData.email),
  );

  const useStyles = makeStyles((theme) => ({
    autocomplete: {
      width: "100%",
      // maxHeight: "52px",
      // minHeight: "53px",
      paddingTop: "5px",
      "& .MuiAutocomplete-inputRoot": {
        flexWrap: "nowrap !important",
      },
    },
    // autocompletetext: {
    //   maxHeight: "52px"
    // },
    tabs: {},
  }));

  const classes = useStyles();

  const coloumsForUserSection = [
    {
      field: "user",
      headerName: "User",
      width: 200,
      editable: false,
    },
    {
      field: "policyName",
      headerName: "Policy Name",
      width: 150,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 180,
      editable: false,
    },
    {
      field: "voterStatus",
      headerName: "Vote Status",
      width: 180,
      editable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row?.assignUserResponse?.status == "DENIED" && (
            <span>DENIED</span>
          )}
          {cellValues.row?.assignUserResponse?.status == "APPROVED" && (
            <span>APPROVED</span>
          )}
          {cellValues.row?.assignUserResponse?.status == "PENDING" && (
            <Button
              onClick={() => {
                history.push(
                  `reviewuserrequest/${cellValues.row.policyId}/${cellValues.row.user}`,
                );
              }}
            >
              APPROVE/REJECT
            </Button>
          )}
        </>
      ),
    },
  ];
  const coloumForAssigndUsers = [
    {
      field: "email",
      headerName: "E-Mail",
      width: 280,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
    },
    {
      field: "walletAddress",
      headerName: "Agent ID",
      width: 350,
      editable: false,
    },
  ];
  const columns = [
    {
      field: "policyName",
      headerName: "Policy Name",
      width: 150,
      editable: false,
    },
    {
      field: "Usersmails",
      headerName: "Users",
      width: 160,
      sortable: false,
      hide:
        verifyRole === "admin" ||
          verifyRole === "policyadmin" ||
          verifyRole === "policysuperadmin"
          ? false
          : true,
      renderCell: (value) => (
        <Button
          variant="contained"
          onClick={() => (
            assignUsersHandler(value.row), setListAssignedUsersOpen(true)
          )}
        >
          Assigned users
        </Button>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
    },

    {
      field: "CreatedOn",
      headerName: "Created on",
      width: 160,
      editable: false,
    },

    {
      field: "isActive",
      headerName: "On Blockchain",
      width: 190,
      editable: false,
      hide:
        verifyRole === "admin" ||
          verifyRole === "policyadmin" ||
          verifyRole === "policysuperadmin"
          ? false
          : true,
      renderCell: (cellValues) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {cellValues.row.isActive}
        </div>
      ),
    },

    {
      field: "EditPolicy",
      headerName: "Actions",
      width: 360,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      renderCell: (cellValues) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "40px",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setDeletePolicyId(cellValues.row.id);
              dispatch(setDeleteModal(true));
            }}
            sx={{
              color: "red",
              fontSize: "25px",
              display:
                verifyRole === "policyadmin" ||
                  verifyRole === "admin" ||
                  verifyRole === "policysuperadmin"
                  ? "block"
                  : "none",
            }}
          >
            <AiOutlineDelete />
          </Button>

          {cellValues.row && cellValues.row.documentLink ? (
            <Button
              sx={{
                fontSize: "25px",
                display:
                  cellValues.row && cellValues.row.documentLink
                    ? "block"
                    : "none",
              }}
              onClick={() =>
                history.push(`/viewyourpolicy/${cellValues.row.policyId}`)
              }
            >
              <AiOutlineEye />
            </Button>
          ) : (
            <Button
              sx={{
                fontSize: "25px",
              }}
            ></Button>
          )}

          <div
            style={{
              ...(cellValues.row?.assignUserResponse?.status === "DENIED" ||
                cellValues.row?.assignUserResponse?.status === "APPROVED"
                ? { marginLeft: "9px" }
                : {}),
            }}
          >
            {cellValues.row?.assignUserResponse?.status == "DENIED" && (
              <span>DENIED</span>
            )}
            {cellValues.row?.assignUserResponse?.status == "APPROVED" && (
              <span>APPROVED</span>
            )}
            {cellValues.row?.assignUserResponse?.status == "PENDING" &&
              cellValues.row.checkIsActive && (
                <Button
                  onClick={() => {
                    validateWalletUsersInfo?.status
                      ? history.push(`reviewpolicy/${cellValues.row.policyId}`)
                      : dispatch(
                        messageDataAction({
                          message: validateWalletUsersInfo?.MessageStatement,
                          status: "error",
                        }),
                      );
                  }}
                >
                  APPROVE/REJECT
                </Button>
              )}
          </div>
        </div>
      ),
    },
    // {
    //   field: "transactionId",
    //   headerName: "Transaction Id",
    //   width: 560,
    //   editable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   disableColumnFilter: true,
    //   sortable: false,
    // },
  ];

  const rowsForUSerSection = userSectionData?.map((e) => {
    return {
      id: e._id,
      user: e.user,
      policyName: e.policyName,
      policyId: e.policyId,
      totalRemainVote: e.totalRemainVote,
      createdAt: moment(e.createdAt).format("MM-DD-YYYY HH:mm:ss"),
      Status: e.Status,
      assignUserResponse: e.assignVoterReview?.find(
        (e) => e.email == userData?.email,
      ),
    };
  });

  const rowsForApprovedPolicies = forApprovals?.map((e) => {
    return {
      id: e._id,
      policyId: e.policyId,
      policyName: e.policyName,
      CreatedOn: moment(e.createdAt).format("MM-DD-YYYY HH:mm:ss"),
      Lastedited: moment(e.lastModifiedDate).format("MM-DD-YYYY HH:mm:ss"),
      Status: e.Status === "LIVE-POLICY" ? "LIVE POLICY" : e.Status,
      documentLink: e.policyDoc,
      isBtnAvailable: e.assignUser,
      policyDescription: e.policyDescription,
      assignUserResponse: e.assignUserResponse?.find(
        (e) => e.email == userData?.email,
      ),
      allAssignUserResponse: e.assignUserResponse,
      isActive: e.isActive ? "YES" : "NO",
      checkIsActive: e.isActive,
    };
  });

  const rows = allPolicies
    ?.filter((e) =>
      window.location.pathname === "/createPolicy"
        ? e.flowType == "NORMAL"
        : e.flowType == "STRIPE",
    )
    ?.map((e) => {
      return {
        id: e._id,
        policyId: e.policyId,
        policyName: e.policyName,
        CreatedOn: moment(e.createdAt).format("MM-DD-YYYY HH:mm:ss"),
        Lastedited: moment(e.lastModifiedDate).format("MM-DD-YYYY HH:mm:ss"),
        Status: e.Status === "LIVE-POLICY" ? "LIVE POLICY" : e.Status,
        documentLink: e.policyDoc,
        isBtnAvailable: e.assignUser,
        policyDescription: e.policyDescription,
        assignUserResponse: e.assignUserResponse?.find(
          (e) => e.email == userData?.email,
        ),
        allAssignUserResponse: e.assignUserResponse,
        isActive: e.isActive ? "YES" : "NO",
        checkIsActive: e.isActive,
        transactionId: e.transactionId,
      };
    });

  if (!loaderData) {
    return (
      <Box sx={{ height: 500, width: "100%" }}>
        {verifyRole === "admin" ||
          verifyRole === "policyadmin" ||
          verifyRole === "policysuperadmin" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {window.location.search === "?voteuser=true" ? (
              <></>
            ) : (
              <Button
                sx={{
                  height: "38px",
                  width: "160px",
                  marginBottom: "20px",
                  fontSize: "15px",
                }}
                onClick={() => {
                  // validateWalletUsersInfo?.status ===  ?
                  dispatch(setCreatePolicyModal(true));
                  // : dispatch(
                  //     messageDataAction({
                  //       message:
                  //         validateWalletUsersInfo?.MessageStatement ||
                  //         "Please install metamask",
                  //       status: "error",
                  //     })
                  //   );
                }}
                variant="contained"
              >
                {(window.location.pathname === "/createPolicy" && activeItem[0] === "#createPolicy")
                  ? "Create Policy" : activeItem[0] === "#secureDocument" ? "Secure Document" : 'Secure Payment'
                }
              </Button>
            )}
          </Box>
        ) : (
          <></>
        )}
        {verifyRole === "admin" ||
          verifyRole === "policyadmin" ||
          verifyRole === "policysuperadmin" ? (
          <>
            {window.location.search === "?voteuser=true" ? (
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={valueTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                      className={classes.tabs}
                      label="Policy"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                      className={classes.tabs}
                      label="User"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueTab} index={0}>
                  <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
                    <DataGrid
                      unstable_cellSelection
                      disableColumnMenu
                      rows={rowsForApprovedPolicies}
                      columns={columns}
                      loading={loaderData}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                      localeText={{
                        noRowsLabel:
                          "Currently there is no policy approval requests to show",
                      }}
                    />
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={valueTab} index={1}>
                  <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
                    <DataGrid
                      unstable_cellSelection
                      disableColumnMenu
                      rows={rowsForUSerSection}
                      columns={coloumsForUserSection}
                      loading={loaderData}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                      localeText={{
                        noRowsLabel:
                          "Currently there is no user requests to show",
                      }}
                      sx={{
                        "& MuiDataGrid-window::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    />
                  </Box>
                </CustomTabPanel>
              </Box>
            ) : (
              <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
                <DataGrid
                  unstable_cellSelection
                  disableColumnMenu
                  rows={rows}
                  columns={columns}
                  loading={loaderData}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableSelectionOnClick
                  getRowHeight={() => "auto"}
                  localeText={{
                    noRowsLabel:
                      "Currently there is no policy approval requests to show",
                  }}
                />
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ width: "100%" }}>
            {(verifyRole === "user" && !isUserPolicy) ||
              (verifyRole === "profileuser" && !isUserPolicy) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "25px",
                }}
              >
                Request admin for access
              </div>
            ) : (
              <>
                <Box>
                  <Tabs
                    value={valueTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                      className={classes.tabs}
                      label="Policy"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                      className={classes.tabs}
                      label="User"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueTab} index={0}>
                  <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
                    <DataGrid
                      unstable_cellSelection
                      disableColumnMenu
                      rows={rowsForApprovedPolicies}
                      columns={columns}
                      loading={loaderData}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                      localeText={{
                        noRowsLabel:
                          "Currently there is no policy approval requests to show",
                      }}
                    />
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={valueTab} index={1}>
                  <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
                    <DataGrid
                      unstable_cellSelection
                      disableColumnMenu
                      rows={rowsForUSerSection}
                      columns={coloumsForUserSection}
                      loading={loaderData}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      disableSelectionOnClick
                      getRowHeight={() => "auto"}
                      localeText={{
                        noRowsLabel:
                          "Currently there is no user requests to show",
                      }}
                    />
                  </Box>
                </CustomTabPanel>
              </>
            )}
          </Box>
        )}

        {/* create policy model */}
        <Dialog
          fullWidth
          PaperProps={{
            sx: {
              padding: "30px",
              maxHeight: "auto",
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          onClose={() => dispatch(setCreatePolicyModal(false))}
          open={createPolicyModal}
        >
          <div
            style={{
              width: "90%",
              height: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {/*here is a tab for stripe or normal  */}

            {/* <Select value={selectedOption} onChange={handleChangeMethod}>
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="stripe">Stripe</MenuItem>
            </Select> */}
            {selectedOption === "stripe" && (
              <>
                <TextField
                  onChange={(e) => {
                    setValue({ ...value, policyName: e.target.value });
                  }}
                  value={value.policyName}
                  id="standard-basic"
                  label="Policy Name"
                  variant="outlined"
                />
                <TextField
                  value={value.policyDescription}
                  onChange={(e) => {
                    setValue({ ...value, policyDescription: e.target.value });
                  }}
                  id="standard-basic"
                  label="Policy Description"
                  variant="outlined"
                />
                <Autocomplete
                  sx={{ overflow: "hidden" }}
                  className={classes.autocomplete}
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={[...allPolicyUsers]}
                  getOptionDisabled={(option) => !option.privateKey}
                  onChange={(e, data) =>
                    setValue({ ...value, assignedUsers: data })
                  }
                  getOptionLabel={(option) => option.email}
                  disableClearable
                  ChipProps={{ size: "small" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.autocompletetext}
                      label="Select Users"
                      placeholder="Users"
                    />
                  )}
                />

                <Autocomplete
                  sx={{ overflow: "hidden" }}
                  className={classes.autocomplete}
                  multiple={false}
                  id="single-select"
                  options={[...allStripeAccounts]}
                  onChange={(e, data) => {
                    if (data) {
                      setValue({ ...value, accountId: data.id });
                    } else {
                      setValue({ ...value, accountId: null });
                    }
                  }}
                  getOptionLabel={(option) => option.id}
                  disableClearable
                  ChipProps={{ size: "small" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.autocompletetext}
                      label="Select Account"
                      placeholder="Account"
                      InputProps={{ ...params.InputProps, readOnly: true }}
                    />
                  )}
                />

                {/* <TextField
                  onChange={(e) => {
                    setValue({ ...value, accountId: e.target.value });
                  }}
                  value={value.accountId}
                  id="standard-basic"
                  label="AccountID"
                  variant="outlined"
                /> */}
                <TextField
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      setValue({ ...value, amount: e.target.value });
                    } else {
                      setValue({ ...value, amount: "" });
                    }
                  }}
                  value={value.amount}
                  id="standard-basic"
                  label="Amount In USD"
                  variant="outlined"
                />
              </>
            )}
            {selectedOption === "normal" && (
              <>
                <TextField
                  onChange={(e) => {
                    setValue({ ...value, policyName: e.target.value });
                  }}
                  value={value.policyName}
                  id="standard-basic"
                  label="Policy Name"
                  variant="outlined"
                />

                <TextField
                  value={value.policyDescription}
                  onChange={(e) => {
                    setValue({ ...value, policyDescription: e.target.value });
                  }}
                  id="standard-basic"
                  label="Policy Description"
                  variant="outlined"
                />
                <Autocomplete
                  sx={{ overflow: "hidden" }}
                  className={classes.autocomplete}
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={[...allPolicyUsers]}
                  getOptionDisabled={(option) => !option.privateKey}
                  onChange={(e, data) =>
                    setValue({ ...value, assignedUsers: data })
                  }
                  getOptionLabel={(option) => option.email}
                  disableClearable
                  ChipProps={{ size: "small" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.autocompletetext}
                      label="Select Users"
                      placeholder="Users"
                    />
                  )}
                />

                <div
                  {...getRootProps()}
                  style={{
                    border: "1px solid white",
                    padding: "3px",
                    width: "",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {document ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "50px",
                          }}
                        >
                          {(fileTypeForIcons === "pdf" && (
                            <BsFillFileEarmarkPdfFill />
                          )) ||
                            (fileTypeForIcons === "csv" && <BsFiletypeCsv />) ||
                            (fileTypeForIcons === "xlsx" && (
                              <BsFiletypeXlsx />
                            )) ||
                            (fileTypeForIcons === "txt" && <BsFiletypeTxt />) ||
                            (fileTypeForIcons === "docx" && <BsFiletypeDoc />)}
                        </div>
                        <p
                          style={{
                            fontSize: "30px",
                            textAlign: "center",
                            lineHeight: "30px",
                          }}
                        >
                          {fileName}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {isDragActive ? (
                        <p
                          style={{
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                        >
                          Drop the files here...
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                        >
                          Drag & Drop Files Here
                        </p>
                      )}
                      <div
                        style={{
                          fontSize: "50px",
                        }}
                      >
                        <LiaUploadSolid />
                      </div>
                    </>
                  )}
                  <input {...getInputProps()} />
                </div>
              </>
            )}

            <LoadingButton
              disabled={checkValidation() ? false : true}
              loading={submitButtonLoader}
              fullWidth
              variant="contained"
              onClick={() => {
                dispatch(setSubmitButtonLoader(true));
                dispatch(
                  createNewPolicy({
                    ...value,
                    setValue,
                    document,
                    walletAddress,
                    creator: userData.email,
                    selectedOption,
                  }),
                );
              }}
            >
              SUBMIT
            </LoadingButton>
          </div>
        </Dialog>

        {/* List Users modal*/}
        <Dialog
          fullWidth
          PaperProps={{
            sx: {
              padding: "30px",
              maxHeight: "500px",
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            },
          }}
          open={listAssignedUsersOpen}
          onClose={() => setListAssignedUsersOpen(false)}
        >
          <h2
            style={{
              marginBottom: "10px",
            }}
          >{`Selected users for ${AssignDataForMap?.data?.policyName}`}</h2>

          <Box sx={{ height: 500, width: "100%", padding: "0px" }}>
            <DataGrid
              unstable_cellSelection
              disableColumnMenu
              rows={AssignDataForMap?.assignedUsersMap}
              columns={coloumForAssigndUsers}
              loading={loaderData}
              pageSizeOptions={[5, 10, 25]}
              disableSelectionOnClick
              getRowHeight={() => "auto"}
              localeText={{
                noRowsLabel: "Currently no assigned users for this policy",
              }}
            />
          </Box>
        </Dialog>

        {/*delete confirmation dialog */}
        <Modal
          open={deleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Are you sure?
            </Typography>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                onClick={() => {
                  dispatch(deletePolicyAction({ deletePolicyId }));
                }}
              >
                Yes
              </Button>

              <Button onClick={() => dispatch(setDeleteModal(false))}>
                No
              </Button>
            </div>
          </Box>
        </Modal>

        <ToastMsg
          toastMessage={messages}
          open={true}
          close={() => dispatch(messageDataAction({}))}
        />
      </Box>
    );
  } else {
    return (
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderData}
        >
          <CircularProgress />
        </Backdrop>
      </div>
    );
  }
}
export default CreatePolicies;
