import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import AnimateButton from "../../HeaderLayout/ui-component/extended/AnimateButton";
import { useDispatch, useSelector } from "react-redux";
import { messageDataAction } from "../../Login/actions";
import toastMsg from "../../SnackBar";

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AuthVerify = props => {

  const message = useSelector(state => state.userData.message);

  const theme = useTheme();

  const dispatch = useDispatch()

  const { signupProp, ...others } = props;

  const history = useHistory();
  let referral_id;

  if (window.location.search) {
    let query = queryString.parse(window.location.search);
    referral_id = query.referral_id;
  }

  const handleSubmit = values => {
    let res = props.verificationsignup({
      username: props.email,
      code: values.verificationCode,
      history: history
    });
  };

  const closeSnackBar = () => {
    dispatch(messageDataAction({}));
  };

  return (
    <>
      <toastMsg toastMessage={message} open={true} close={closeSnackBar} />
      {/* {showVerification ? } */}

      <Formik
        initialValues={{
          verificationCode: ""
        }}
        onSubmit={async (values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 500);
          await handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(
                touched.verificationCode && errors.verificationCode
              )}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Verification Code
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                value={values.verificationCode}
                name="verificationCode"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.verificationCode && errors.verificationCode && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.verificationCode}
                </FormHelperText>
              )}
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              </AnimateButton>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                cursor: "pointer"
              }}
              onClick={async () => {
                await Auth.resendSignUp(props.email)
                  .then(() => {
                    props.messageDataAction({
                      message: "code resent successfully",
                      status: "success"
                    });
                  })
                  .catch(() => {
                    props.messageDataAction({
                      message: "error in resending code",
                      status: "error"
                    });
                  });
              }}
            >
              Resend OTP?
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthVerify;
