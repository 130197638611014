import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import React from "react";
import { SidebarNav } from "./components";
const Sidebar = props => {
  const { open, variant, onClose, ...rest } = props;

  return (
    // <Drawer
    //     sx={{
    //       width: drawerWidth,
    //       flexShrink: 0,
    //       '& .MuiDrawer-paper': {
    //         width: drawerWidth,
    //         boxSizing: 'border-box',
    //       },
    //     }}
    //     variant="persistent"
    //     anchor="left"
    //     open={open}
    //   >
    //     <List>
    //       {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
    //         <ListItem key={text} disablePadding>
    //           <ListItemButton>
    //             <ListItemIcon>
    //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItemButton>
    //         </ListItem>
    //       ))}
    //     </List>
    //   </Drawer>
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: { xs: "100%", sm: 250 }
        }
      }}
    >
      <Box
        {...rest}
        sx={{
          height: "100%",
          padding: 1
        }}
      >
        <SidebarNav
          onClose={onClose}
          data={props.data}
          themeMode={props.themeMode}
          messageAction={props.messageAction}
          headerAccountAction={props.headerAccountAction}
          headerNetworkIdAction={props.headerNetworkIdAction}
          headerSetBalance={props.headerSetBalance}
          showAccountModal={props.showAccountModal}
          accountModal={props.accountModal}
          headerAccount={props.headerAccount}
          headerNeworkId={props.headerNeworkId}
          // homeData={props.homeData}
          balance={props.balance}
        />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired
};

export default Sidebar;
