import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getApprovedPolicies } from "./redux/action";

function MyApprovedPolicies() {
  const approvedPolicies = useSelector(
    state => state.MyApprovedPoliciesReducer.approvePolicies
  );
  const messages = useSelector(state => state.userData.message);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getApprovedPolicies());
  }, []);

  const columns = [
    { field: "id", headerName: "PolicyId", width: 320 },
    {
      field: "policyName",
      headerName: "Policy Name",
      width: 200,
      editable: false
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false
    },

    {
      field: "CreatedOn",
      headerName: "Created on",
      width: 200,
      editable: false
    },
    {
      field: "Lastedited",
      headerName: "Last edited",
      width: 200,
      editable: false
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: params => {
        return (
          <Button
            sx={{
              fontSize: "25px"
            }}
            onClick={() =>
              history.push(`/viewyourpolicy/${params.row.policyId}`)
            }
          >
            <AiOutlineEye />
          </Button>
        );
      }
    }
  ];

  const rows = approvedPolicies?.map(e => {
    return {
      id: e._id,
      policyId: e.policyId,
      policyName: e.policyName,
      CreatedOn: moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      Lastedited: moment(e.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss"),
      Status: e.Status === "LIVE-POLICY" ? "LIVE POLICY" : e.Status,
      documentLink: e.policyDoc,
      isBtnAvailable: e.assignUser,
      policyDescription: e.policyDescription
    };
  });
  <toastMsg />;
  return (
    <>
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          unstable_cellSelection
          disableColumnMenu
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 25]}
          disableSelectionOnClick
          getRowHeight={() => "auto"}
          localeText={{
            noRowsLabel: "There is no approved policies right now to show"
          }}
        />
      </Box>
      <toastMsg />
    </>
  );
}

export default MyApprovedPolicies;
