import {
  EDIT_CHAT_NAME,
  IS_LOADING_CHAT,
  SELECTED_CHAT_ID,
  SELECTED_CHAT_MESSAGES_ID,
  SET_ALL_CHAT_LIST
} from "./constants";
const defaultState = {
  AllChatList: "",
  EditchatId: "",
  editchatname: "",
  selectedMessageId: "",
  waitingChat: false
};

const chatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_CHAT_LIST:
      return { ...state, AllChatList: action.payload };
    case SELECTED_CHAT_ID:
      return { ...state, EditchatId: action.payload };
    case EDIT_CHAT_NAME:
      return { ...state, editchatname: action.payload };
    case SELECTED_CHAT_MESSAGES_ID:
      return { ...state, selectedMessageId: action.payload };
    case IS_LOADING_CHAT:
      return { ...state, waitingChat: action.payload };

    default:
      return state;
  }
};

export default chatReducer;
