import {
  IS_USER_ASSIGN_POLICY,
  SET_ALL_POLICY,
  SET_ALL_POLICY_USERS,
  SET_ALL_STRIPE_ACCOUNTS,
  SET_ASSIGN_USER_MODAL,
  SET_CREATE_POLICY_LOADER,
  SET_CREATE_POLICY_MODAL,
  SET_DELETE_MODAL_LOADER,
  SET_POLICY_DOCUMENT,
  SET_SEND_TOKEN_MODAL,
  SET_SUBMIT_BUTTON_LOADER,
  SET_USER_APPROVAL_RESPONSE_DATA,
  TRANSFER_TOKEN_BUTTON_LOADER,
} from "./constants";

const defaultState = {
  policies: [],
  policyUsers: [],
  stripeAccounts: [],
  // policyAdmins: [],
  assignUsers: {},
  isUserAssignPolicy: null,
  userRequestedAssignedData: [],
  createPolicyLoader: true,
  deleteModel: false,
  sendTokenModal: false,
  createPolicyModal: false,
  submitButtonLoader: false,
  tokenTransferButton: false,
  policyDocument: null,
  assignUserModal: false,
  tstMssg: true,
};

const CreatePolicesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_POLICY_USERS:
      return { ...state, policyUsers: action.payload };

    case SET_ALL_POLICY:
      return { ...state, policies: action.payload };

    case IS_USER_ASSIGN_POLICY:
      return { ...state, isUserAssignPolicy: action.payload.data };

    case SET_USER_APPROVAL_RESPONSE_DATA:
      return { ...state, userRequestedAssignedData: action.payload };

    case SET_CREATE_POLICY_LOADER:
      return { ...state, createPolicyLoader: action.payload };

    case SET_DELETE_MODAL_LOADER:
      return { ...state, deleteModel: action.payload };

    case SET_SEND_TOKEN_MODAL:
      return { ...state, sendTokenModal: action.payload };

    case SET_CREATE_POLICY_MODAL:
      return { ...state, createPolicyModal: action.payload };

    case SET_SUBMIT_BUTTON_LOADER:
      return { ...state, submitButtonLoader: action.payload };

    case TRANSFER_TOKEN_BUTTON_LOADER:
      return { ...state, tokenTransferButton: action.payload };

    case SET_POLICY_DOCUMENT:
      return { ...state, policyDocument: action.payload };

    case SET_ASSIGN_USER_MODAL:
      return { ...state, assignUserModal: action.payload };

    case SET_ALL_STRIPE_ACCOUNTS:
      return { ...state, stripeAccounts: action.payload };

    default:
      return state;
  }
};

export default CreatePolicesReducer;
