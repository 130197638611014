import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useHistory } from "react-router-dom";

function Header(props) {
  const history = useHistory();

  return (
    <Toolbar sx={{ background: "#191919" }}>
      <Button
        onClick={() => history.push("/createPolicy")}
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 4 }}
      >
        <ArrowBackIcon />
      </Button>
      <Typography
        variant="h5"
        noWrap
        component="div"
        sx={{ flexGrow: 1, ml: -3 }}
      >
        BACK TO POLYADMIN
      </Typography>
    </Toolbar>
  );
}

export default Header;
