import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { connect } from "react-redux";
import logo from "../../../images/rocket-joypixels.gif";
import { messageDataAction } from "../../Login/actions";
import AuthCardWrapper from "../../Login/CardWrapper/AuthCardWrapper";
import AuthWrapper1 from "../../Login/CardWrapper/AuthWrapper1";
import ToastMsg from "../../SnackBar";
import { forgetverification, forgetverifyEmail } from "../action";
import AuthForgetVerify from "./VerificationForm";

const VerificationForgotpassword = props => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const closeSnackBar = () => {
    props.messageDataAction({});
  };
  return (
    <AuthWrapper1>
      {props.waitingLogin && (
        <Backdrop
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={props.waitingLogin}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <ToastMsg
        toastMessage={props.message}
        open={true}
        close={closeSnackBar}
      />

      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <img src={logo} width="50px" height={"50px"} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Verification Page
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthForgetVerify
                      forgetverification={props.forgetverification}
                      email={props.email}
                      forgetverifyEmail={props.forgetverifyEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    forgetverification: values => {
      dispatch(forgetverification(values));
    },
    messageDataAction: data => {
      dispatch(messageDataAction(data));
    },
    forgetverifyEmail: data => {
      dispatch(forgetverifyEmail(data));
    }
  };
};

const mapStateToProps = state => {
  return {
    email: state.frogetverify.email,
    message: state.userData.message,
    waitingLogin: state.userData.waitingLogin
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationForgotpassword);
