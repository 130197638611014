import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const addPolicieEmailsApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(`${API}addpolicyuser`, data);

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getPolicieEmailsApi = async () => {
  try {
    const result = await getAxiosInstance().get(`${API}getpolicyuser`);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const deletepolicyuser = async (id) => {
  try {
    const result = await getAxiosInstance().post(`${API}deletepolicyuser`, id);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const changePolicyRoleApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(
      `${API}changepolicy_role`,
      data,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const sendToken = async (data) => {
  try {
    const result = await getAxiosInstance().post(`${API}send_tokens`, data);
    return result;
  } catch (e) {
    console.log(e);
    return e.response

  }
};
