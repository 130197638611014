import { TextField } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import copy from "copy-to-clipboard";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeleteModal,
  setSendTokenMoal,
} from "../CreatePolicies/Redux/action";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";
import {
  addUserEmailsActionPolicie,
  changePolicyRoleAction,
  deleteEmailActionPolicie,
  getAddedEmailsActionPolicie,
  sendTokenAction,
  setInviteBtnLoader,
  setModalStatus,
} from "./redux/action";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
};

function Policy() {
  const host = window.location.origin;

  const [emails, setEmails] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [deletePolicyId, setDeletePolicyId] = useState();
  const [userDetail, setUserDetail] = useState();

  //*********************USE-SELECTORS********************** */

  const datas = useSelector((state) => state.PolicieEmailReducer.emails);
  const messages = useSelector((state) => state.userData.message);
  const verifyData = useSelector((state) => state.userData.userData);
  const verifyRole = useSelector((state) => state.accountData.data.role);
  const tableLoader = useSelector((state) => state.PolicieEmailReducer.loader);
  const inviteBtnLoader = useSelector(
    (state) => state.PolicieEmailReducer.inviteBtnLoader,
  );
  const modalStatus = useSelector(
    (state) => state.PolicieEmailReducer.modalStatus,
  );
  const deleteModal = useSelector(
    (state) => state.CreatePolicesReducer.deleteModel,
  );

  const sendTokenModal = useSelector(
    (state) => state.CreatePolicesReducer.sendTokenModal,
  );

  const transferButton = useSelector(
    (state) => state.CreatePolicesReducer.tokenTransferButton,
  );

  const [roleDetail, setRoleData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    let roleData = {};
    datas?.forEach((element) => {
      roleData = { ...roleData, [element._id]: element.role };
    });
    setRoleData(roleData);
  }, [datas]);

  const handleChange = async (event, index) => {
    setRoleData({ ...roleDetail, [index]: event.target.value });
  };

  const submitFunc = async (_id, role, email) => {
    dispatch(changePolicyRoleAction({ _id, role, email }));
  };

  let checkValidation = () => {
    if (userDetail?.email && userDetail?.agentId && userDetail?.ethers) {
      return true;
    }
  };

  useEffect(() => {
    if (verifyData) {
      dispatch(getAddedEmailsActionPolicie());
    }
  }, [verifyData]);

  const useStyles = makeStyles(() => ({
    "& .MuiDataGrid-root": {
      color: "white",
    },
  }));
  const classes = useStyles();

  function validateEmail(emails) {
    var atPos = emails.indexOf("@");
    var dotPos = emails.lastIndexOf(".");

    return atPos > 0 && dotPos > atPos + 1 && dotPos < emails.length - 1;
  }

  const getValue = (e) => {
    e.preventDefault();
    dispatch(setInviteBtnLoader(true));

    if (!validateEmail(emails)) {
      dispatch(setInviteBtnLoader(false));
      return;
    }

    dispatch(
      addUserEmailsActionPolicie({
        email: emails,
        hostname: host,
      }),
    );
  };

  const columns = [
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
      editable: false,
    },
    {
      field: "walletAddress",
      headerName: "Agent ID",
      width: 170,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              copy(`${cellValues.row.walletAddress}`);
              dispatch(
                messageDataAction({
                  message: "Agent-ID copied",
                  status: "success",
                }),
              );
            }}
          >{`${cellValues.row.walletAddress.slice(0, -28)}...`}</div>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 120,
      editable: false,
      renderCell: (value) => {
        return (
          <Tooltip
            sx={{ ml: 3, cursor: "pointer" }}
            placement="right-start"
            title={
              <React.Fragment>
                <Typography color="black">
                  {`${value.row.balance} Wei `}
                </Typography>
              </React.Fragment>
            }
          >
            {value.row.ETH ? value.row.ETH : 0}
          </Tooltip>
        );
      },
    },
    // {
    //   field: "eth",
    //   headerName: "ETH",
    //   width: 200,
    //   editable: false,
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <TextField
    //           id="outlined-basic"
    //           label="Ethers"
    //           variant="outlined"
    //           InputProps={{
    //             style: { color: "white" }, // This changes the input text color
    //           }}
    //         />
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "SendToken",
    //   headerName: "SendToken",
    //   width: 200,
    //   editable: false,
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <Button
    //           sx={{ ml: 2 }}
    //           variant="contained"
    //           onClick={() => sendToken(cellValues.row.walletAddress)}
    //         >
    //           Transfer
    //         </Button>
    //       </>
    //     );
    //   },
    // },
    {
      field: "date",
      headerName: "Date",
      width: 160,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 140,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,

      hide:
        verifyRole === "admin" || verifyRole === "policysuperadmin"
          ? false
          : true,
      renderCell: (value) => {
        if (
          value.row.role.role === "admin" ||
          value.row.role.role === "policysuperadmin"
        ) {
          return (
            <div style={{ width: "100%", textAlign: "center" }}>
              Super Admin
            </div>
          );
        }

        return (
          <Box sx={{ minWidth: 130 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{`${value.row.role.role}`}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  roleDetail[value.row.role._id]
                    ? roleDetail[value.row.role._id]
                    : ""
                }
                label="Role"
                onChange={(e) => {
                  handleChange(e, value.row.role._id);
                }}
              >
                <MenuItem value={"policyadmin"}>Policy Admin</MenuItem>
                <MenuItem value={"policyuser"}>Policy User</MenuItem>
              </Select>
            </FormControl>
          </Box>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 310,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      hide:
        verifyRole === "admin" || verifyRole === "policysuperadmin"
          ? false
          : true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              // background: "black",
              display: "flex",
              justifyContent: "space-around",
              width: "98%",
            }}
          >
            {cellValues.row.role.role === "admin" ||
              cellValues.row.role.role === "policysuperadmin" ? (
              <></>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setDeletePolicyId(cellValues.row._id);
                    dispatch(setDeleteModal(true));
                  }}
                  variant="contained"
                  sx={{
                    color: "red",
                    fontSize: "25px",
                  }}
                >
                  <AiOutlineDelete />
                </Button>

                <Button
                  // sx={{ ml: 2 }}

                  variant="contained"
                  onClick={() =>
                    submitFunc(
                      cellValues.row._id,
                      roleDetail[cellValues.row._id],
                      cellValues.row.email,
                    )
                  }
                >
                  SUBMIT
                </Button>
              </>
            )}

            <Button
              sx={{
                ...(cellValues.row.role.role === "policysuperadmin" && {
                  mr: -21.5,
                }),
              }}
              variant="contained"
              onClick={() => {
                setUserDetail({
                  email: cellValues.row.email,
                  agentId: cellValues.row.walletAddress,
                });
                dispatch(setSendTokenMoal(true));
              }}
            >
              Send Token
            </Button>
          </Box>
        );
      },
    },
  ];

  const rows = datas?.map((e) => {
    return {
      _id: e._id,
      id: e.id,
      email: e.email,
      date: moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      role: e,
      walletAddress: e.walletAddress,
      ETH: `${e.ETH}  ETH`,
      balance: e.balance,
    };
  });

  return (
    <Box sx={{ height: 470, width: "100%" }}>
      {tableLoader && ( // Display loader when tableLoader is true
        <div>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      )}

      {verifyRole === "admin" || verifyRole === "policysuperadmin" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              height: "38px",
              width: "130px",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
            onClick={() => {
              dispatch(setModalStatus(true));
            }}
            variant="contained"
          >
            + INVITE
          </Button>
        </Box>
      ) : (
        <></>
      )}

      {!tableLoader && (
        <>
          <DataGrid
            unstable_cellSelection
            disableColumnMenu
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 25]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableSelectionOnClick
            disableRowSelectionOnClick
            localeText={{
              noRowsLabel:
                "There is currently no users. Invite Users to watch list of invited users",
            }}
            autoPageSize={false}
          />

          <Modal
            open={modalStatus}
            onClose={() => dispatch(setModalStatus(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                sx={{
                  height: "160px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h3"
                  component="h1"
                >
                  Invite to policy user
                </Typography>
                <TextField
                  sx={{ textField: { color: "white" } }}
                  InputLabelProps={{
                    sx: {
                      color: "white",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "white",
                      "&:hover fieldset": {
                        border: "1px solid white!important",
                        borderRadius: 0,
                      },
                      "&:focus-within fieldset, &:focus-visible fieldset": {
                        border: "1px solid white!important",
                        color: "white",
                      },
                    },
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Enter your email"
                  variant="outlined"
                  onChange={(e) => setEmails(e.target.value)}
                />
                <LoadingButton
                  loading={inviteBtnLoader}
                  fullWidth
                  variant="contained"
                  onClick={(e) => getValue(e)}
                >
                  INVITE
                </LoadingButton>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={deleteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Are you sure?
              </Typography>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Button
                  onClick={() => {
                    dispatch(deleteEmailActionPolicie({ id: deletePolicyId }));
                  }}
                >
                  Yes
                </Button>

                <Button onClick={() => dispatch(setDeleteModal(false))}>
                  No
                </Button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={sendTokenModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Transfer Token
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h1"
                sx={{ textAlign: "center" }}
              >
                {userDetail?.email}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h1"
                sx={{ textAlign: "center" }}
              >
                {userDetail?.agentId}
              </Typography>

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Ethers"
                  variant="outlined"
                  value={userDetail?.ethers}
                  InputLabelProps={{
                    style: { color: "white" }, // This changes the label color
                  }}
                  InputProps={{
                    style: { color: "white" }, // This changes the input text color
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value) && parseFloat(value) <= 10) {
                      setUserDetail({
                        ...userDetail,
                        ethers: value,
                      });
                    } else {
                      setUserDetail({ ...userDetail, ethers: "" });
                    }
                  }}
                />

                <LoadingButton
                  loading={transferButton}
                  fullWidth
                  variant="contained"
                  sx={{ mt: "5px" }}
                  onClick={() => dispatch(sendTokenAction(userDetail))}
                  disabled={checkValidation() ? false : true}
                >
                  Transfer
                </LoadingButton>

                <Button
                  fullWidth
                  sx={{ mt: "5px" }}
                  onClick={() => dispatch(setSendTokenMoal(false))}
                >
                  Cancle
                </Button>
              </Box>
            </Box>
          </Modal>

          <ToastMsg
            toastMessage={messages}
            open={true}
            close={() => dispatch(messageDataAction({}))}
          />
        </>
      )}
    </Box>
  );
}
export default Policy;
