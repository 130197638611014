import {
  ABOUT_US_DATA,
  ABOUT_US_MODAL_SHOW,
  CLAIM_NFT_MODAL,
  CUSTOM_LANDING_PAGE_LOADER,
  DELETE_LANDING_PAGE_SUCCESS,
  FETCH_LANDING_PAGE_SUCCESS,
  HEADER_ACCOUNT,
  HEADER_AIRDROP_DATA,
  HEADER_COLOR,
  HEADER_NETWORKID,
  HEADER_SET_BALANCE,
  HEADER_SHOW_SIDEBAR,
  HEADER_WALLET_TYPE,
  LANDINGPAGE_DATA_SUCCESS,
  LANDING_HOME_PAGE_DATA,
  LOADING_DATA,
  MESSAGE_DATA,
  NFT_FACTORY_CONTRACT_MINT,
  SET_CUSTOM_IMAGE_LIST,
  SET_EMAIL_ID,
  SET_MATRIX_TABLE_DATA,
  SHOW_ACCOUNT_MODAL,
  THEME_BACKGROUND
} from "./constants";

const defaultState = {
  data: [],
  images: [],
  isMessage: [],
  isLoading: false,
  modalShow: false,
  aboutusData: null,
  claimNftModal: false,
  accountModal: false,
  airDropData: null,
  balance: "0 ETH",
  headerAccount: [],
  headerNeworkId: null,
  headerWalletType: null,
  pageData: null,
  landingsuccessData: null,
  nftfactoryContract: null,
  headerSidebar: false,
  colorgroup: "palettes[2]",
  themecolor: "#ffffff",
  emailIdData: [],
  customImageList: "",
  customLandingPageLoader: true,
  sectionName: "",
  matrixList: ""
};

const landingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_LANDING_PAGE_SUCCESS:
      return {
        ...state,
        data: action.payload.landingPages,
        images: action.payload.imagesArr
      };
    case SET_MATRIX_TABLE_DATA:
      return { ...state, matrixList: action.payload };
    case THEME_BACKGROUND:
      return { ...state, themecolor: action.payload };
    case HEADER_SHOW_SIDEBAR:
      return { ...state, headerSidebar: action.payload };
    case HEADER_COLOR:
      return { ...state, colorgroup: action.payload };
    case LANDINGPAGE_DATA_SUCCESS:
      return { ...state, landingsuccessData: action.payload };
    case NFT_FACTORY_CONTRACT_MINT:
      return { ...state, nftfactoryContract: action.payload };
    case LANDING_HOME_PAGE_DATA:
      return { ...state, pageData: action.payload };
    case HEADER_ACCOUNT:
      return { ...state, headerAccount: action.payload };
    case HEADER_WALLET_TYPE:
      return { ...state, headerWalletType: action.payload };
    case HEADER_NETWORKID:
      return { ...state, headerNeworkId: action.payload };
    case HEADER_SET_BALANCE:
      return { ...state, balance: action.payload };
    case CLAIM_NFT_MODAL:
      return { ...state, claimNftModal: action.payload };
    case HEADER_AIRDROP_DATA:
      return { ...state, airDropData: action.payload };
    case SHOW_ACCOUNT_MODAL:
      return { ...state, accountModal: action.payload };
    case ABOUT_US_DATA:
      return { ...state, aboutusData: action.payload };
    case LOADING_DATA:
      return { ...state, isLoading: action.payload };
    case ABOUT_US_MODAL_SHOW:
      return { ...state, modalShow: action.payload };
    case MESSAGE_DATA:
      return { ...state, isMessage: action.payload };
    case SET_EMAIL_ID:
      return { ...state, emailIdData: action.payload };
    case SET_CUSTOM_IMAGE_LIST:
      return { ...state, customImageList: action.payload };
    case CUSTOM_LANDING_PAGE_LOADER:
      return { ...state, customLandingPageLoader: action.payload };

    case DELETE_LANDING_PAGE_SUCCESS:
      let index;
      state.data.map((elem, i) => {
        if (elem._id === action.payload.pageData._id) {
          index = i;
        }
      });
      const filteredData = state.data.filter(
        page => page._id !== action.payload.pageData._id
      );
      let filteredImages = state.images.filter((image, i) => i !== index);
      return { ...state, data: filteredData, images: filteredImages };
    default:
      return state;
  }
};

export default landingReducer;
