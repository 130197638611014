import { Box } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { API } from "../../../utils/config";
// import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  headerAccountAction,
  storeMatrixDataAction
} from "../../LandingPage/actions";
import { messageDataAction } from "../../Login/actions";

import axios from "axios";
import "./index.css";

function Matrix(props) {
  // paypal...................................................|

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture"
  };

  async function createOrder(e) {
    try {
      const response = await axios.post(`${API}my-server/create-paypal-order`, {
        amount: 25
      });
      return response.data.id;
    } catch (e) {
      console.log(e);
    }
  }

  async function onApprove(data) {
    try {
      const response = await axios.post(`${API}createPayment`, {
        orderID: data.orderID
      });

      if (response.status == 200) {
        props.storeMatrixDataAction({
          userData: { ...userData, plan: plan },
          landingPageId: props.data._id
        });

        setNameInputError(false);
        setEmailInputError(false);
        setAllInput(false);

        setUserData({
          name: "",
          email: "",
          socials: ""
        });
        SetOpenSquare(false);
      } else {
        props.messageDataAction({
          message: "Authentication Failed",
          status: "error"
        });
        SetOpenSquare(false);
      }
    } catch (e) {
      props.messageDataAction({
        message: "Authentication Failed",
        status: "error"
      });
      SetOpenSquare(false);
      console.error(e.message);
    }
  }

  //////////////////////////////////////////////////////////////
  const [openSquare, SetOpenSquare] = useState(false);
  const [card, setCard] = useState();
  const [payment, setPayment] = useState();
  const appId = process.env.REACT_APP_SQUARE_APP_ID;
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;
  const themeType = useTheme();

  const useStyles = makeStyles(() => ({
    square: {
      width: "35%",
      marginTop: "10%",
      marginLeft: "30%",
      backgroundColor: "black",
      "@media screen and (max-width: 576px)": {
        width: "100%",
        marginLeft: "0px"
      }
    }
  }));
  const classes = useStyles();

  const [plan, setPlan] = useState("");

  const [nameInput, setNameInputError] = useState(false);
  const [emailInput, setEmailInputError] = useState(false);
  const [allInput, setAllInput] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    socials: ""
  });

  const history = useHistory();

  const createProfileWithSerious = async props => {

    if (!userData.name && !userData.email) {
      props.messageDataAction({
        message: "Please enter required field",
        status: "error"
      });
      setAllInput(true);
    } else if (!userData.name) {
      props.messageDataAction({
        message: "Please enter twitter handle",
        status: "error"
      });

      setNameInputError(true);
    } else if (!userData.email) {
      props.messageDataAction({
        message: "Please enter email",
        status: "error"
      });
      setEmailInputError(true);
    } else {
      if (plan === "SERIOUS") {
        // const stripeLink = "https://buy.stripe.com/6oE16u6sA0UWfII4gi";
        // window.open(stripeLink, "_blank");
        SetOpenSquare(true);
      } else {
        props.storeMatrixDataAction({
          userData: { ...userData, plan: plan },
          landingPageId: props.data._id
        });

        setNameInputError(false);
        setEmailInputError(false);
        setAllInput(false);

        setUserData({
          name: "",
          email: "",
          socials: ""
        });
      }
    }
  };

  useEffect(() => {
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    var lines = [];
    var width = window.innerWidth;
    var height = window.innerHeight;
    var verticalText = true; /* Updated */

    canvas.width = width;
    canvas.height = height;

    var userForm = document.getElementById("user-form");
    var formLabel = document.getElementById("form-label");

    // Add event listeners for the buttons
    document.getElementById("blue-pill").addEventListener("click", function () {
      updateRainColor("blue"); /* Updated */
      userForm.dataset.color = "blue";
      userForm.style.display = "block";
      formLabel.innerText = "Exclusive Invitation: Start for Free.";
    });

    document
      .getElementById("orange-pill")
      .addEventListener("click", function () {
        updateRainColor("orange"); /* Updated */
        userForm.dataset.color = "orange";
        userForm.style.display = "block";
        formLabel.innerText = "Exclusive Offer: Only $25!";
      });

    // Function to change color of the rain
    function updateRainColor(color) {
      ctx.fillStyle = color;
    }

    // Start the rain with green color
    updateRainColor("lime");

    // Add 100 lines initially
    for (var i = 0; i < 100; i++) {
      lines.push(new Line(Math.random() * width, Math.random() * height));
    }

    function Line(x, y) {
      this.x = x;
      this.y = y;
      this.text = "()_+ABCGI-@#$!@())(*&^%";
      this.speed = Math.random() * 2 + 1;
    }

    Line.prototype.draw = function () {
      if (verticalText) {
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(this.text, 0, 0);
        ctx.restore();
      } else {
        ctx.fillText(this.text, this.x, this.y);
      }
      this.y += this.speed;
      if (this.y > canvas.height) {
        this.y = 0;
      }
    };

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      lines.forEach(function (line) {
        line.draw();
      });
      requestAnimationFrame(animate);
    }

    ctx.font = "20px 'Share Tech Mono'";
    animate();
  }, []);
  return (
    <div id="matrixCOntainer">
      {/*paypal dialog box  */}
      <Dialog
        fullWidth
        PaperProps={{
          sx: {
            padding: "30px",
            maxHeight: "auto",
            minHeight: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        }}
        open={openSquare}
        onClose={() => {
          SetOpenSquare(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Box sx={{ marginTop: "2rem" }}>
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                fundingSource="card"
                createOrder={createOrder}
                onApprove={onApprove}
              />
            </PayPalScriptProvider>
          </Box>
        </div>
      </Dialog>
      {/* ............... */}
      <div
        id="title"
        style={{
          color:
            props.displayType || themeType.palette.mode === "dark"
              ? "#fff"
              : "#000"
        }}
      >
        Choose Your Path
      </div>
      <canvas id="canvas"></canvas>
      <div id="button-container">
        <button
          class="btnpill"
          id="blue-pill"
          style={{
            color:
              props.displayType || themeType.palette.mode === "dark"
                ? "#fff"
                : "#000"
          }}
          onClick={() => setPlan("CURIOUS")}
        >
          Curious
        </button>

        <button
          class="btnpill"
          id="orange-pill"
          style={{
            color:
              props.displayType || themeType.palette.mode === "dark"
                ? "#fff"
                : "#000"
          }}
          onClick={() => setPlan("SERIOUS")}
        >
          Serious
        </button>
      </div>

      <div id="user-form">
        <label id="form-label"></label>

        <label for="name">Twitter Handle:</label>

        <input
          className={
            nameInput === true || allInput === true ? "errorClass" : ""
          }
          value={userData.name}
          type="text"
          id="name"
          name="name"
          onChange={e => {
            setUserData(prevState => ({
              ...prevState,
              ["name"]: e.target.value
            }));
          }}
          required
        ></input>

        <label for="email">Email:</label>

        <input
          className={
            emailInput === true || allInput === true ? "errorClass" : ""
          }
          value={userData.email}
          type="email"
          id="email"
          name="email"
          onChange={e => {
            setUserData(prevState => ({
              ...prevState,
              ["email"]: e.target.value
            }));
          }}
          required
        ></input>

        <label for="socials">Social Links:</label>

        <textarea
          value={userData.socials}
          id="socials"
          name="socials"
          onChange={e => {
            setUserData(prevState => ({
              ...prevState,
              ["socials"]: e.target.value
            }));
          }}
          rows={4}
          style={{ resize: "none" }}
        ></textarea>

        <input
          id="submit"
          type="submit"
          value="Create Your Profile"
          onClick={() => createProfileWithSerious(props)}
        ></input>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    storeMatrixDataAction: values => {
      dispatch(storeMatrixDataAction(values));
    },
    messageDataAction: values => {
      dispatch(messageDataAction(values));
    },
    headerAccountAction: data => {
      dispatch(headerAccountAction(data));
    }
  };
};

const mapStateToProps = state => {
  return {
    message: state.userData.message,

    mintDataForPrice:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0],
    singleMinterData:
      state?.landingPageData?.landingsuccessData?.properties?.marketPlace,
    mintDataForComingSoonText:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0]?.mint
        ?.minterId,
    headerAccount: state.landingPageData.headerAccount,

    comingSoonText:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0]
        ?.mintNft?.comingSoon,
    customImage:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.customImageUrl,
    displayType:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.displayType
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
