import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import draftToHtml from "draftjs-to-html";
import React from "react";
import {
  AiFillLinkedin,
  AiFillMediumSquare,
  AiFillTwitterCircle,
  AiFillYoutube
} from "react-icons/ai";
import {
  FaDiscord,
  FaFacebook,
  FaTelegramPlane,
  FaUserCircle
} from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const Team = props => {
  const useStyles = makeStyles(() => ({
    image: {
      width: "170px",
      height: "170px",
      borderRadius: "50%",
      marginBottom: "20px"
    },
    title: {
      fontSize: "32px",
      fontWeight: "700",
      textTransform: "uppercase",
      marginBottom: "20px",
      textOpacity: "1",
      color: "rgb(251,215,37)",
      fontFamily: "Nunito,sans-serif",
      lineHeight: "1.2",
      marginTop: "0",
      textAlign: "center",
      paddingBottom: "30px"
    },
    modal: {
      margin: "30px auto",
      width: "400px",
      backgroundColor:
        props.data && props.data.properties.aboutUs ? "black" : "pink",
      borderRadius: "10px",
      padding: "10px"
    },
    modalImageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "10px"
    },
    aboutMember: {
      display: "flex",
      justifyContent: "center"
    },
    socialLinks: {
      display: "flex",
      justifyContent: "center"
    },
    icon: {
      margin: "10px",
      fontSize: "30px",
      cursor: "pointer"
    }
  }));
  const classes = useStyles();

  let checkAddress = window.location.href;
  let check = checkAddress.includes("customLandingPage");
  return (
    <>
      {props.data ? (
        <Box paddingLeft={4}>
          <Box marginBottom={4}>
            {/* {localStorage.getItem("component" === "Aboutus") ? ( */}
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: "medium"
              }}
              gutterBottom
              color={"secondary"}
            >
              {props.data && props.data.properties.aboutUs
                ? props.data.properties.aboutUs.title.value
                : "About Us"}
            </Typography>
            {/* )
            //  : null */}

            {/* {localStorage.getItem("component" === "Aboutus") ? ( */}
            <Typography
              fontWeight={400}
              variant={"h4"}
              dangerouslySetInnerHTML={{
                __html:
                  props.data && props.data.properties.aboutUs
                    ? draftToHtml(
                        JSON.parse(props.data.properties.aboutUs.description)
                      )
                    : "About Us Description"
              }}
            ></Typography>
            {/* ) : null} */}

            {/* <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: "medium"
              }}
              gutterBottom
              color={"secondary"}
            >
              {props.data && props.data.properties.aboutUs
                ? props.data.properties.aboutUs.title.value
                : "About Us"}
            </Typography>
            
            <Typography
              fontWeight={400}
              variant={"h4"}
              dangerouslySetInnerHTML={{
                __html:
                  props.data && props.data.properties.aboutUs
                    ? draftToHtml(
                        JSON.parse(props.data.properties.aboutUs.description)
                      )
                    : "About Us Description"
              }}
            ></Typography> */}
          </Box>

          <Grid container spacing={2}>
            {props.data &&
              props.data.properties.aboutUs &&
              props.data.properties.aboutUs.team.map((item, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={i}
                  onClick={() => {
                    props.AboutUsDataAction(item);
                    props.aboutUpModalShow(true);
                  }}
                >
                  <ListItem
                    component="div"
                    disableGutters
                    data-aos={check ? null : "fade-up"}
                  >
                    <ListItemAvatar>
                      <Box
                        component={Avatar}
                        width={120}
                        height={120}
                        src={
                          item.imageUrl
                            ? item.imageUrl
                            : "https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652__340.png"
                        }
                        marginRight={2}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name ? item.name : "XYZ"}
                      secondary={item.position ? item.position : "XYZ"}
                    />
                  </ListItem>
                </Grid>
              ))}
          </Grid>

          {/* <Grid container spacing={2}>
            {props.data &&
              props.data.properties.aboutUs &&
              props.data.properties.aboutUs.team.map((item, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={i}
                  onClick={() => {
                    props.AboutUsDataAction(item);
                    props.aboutUpModalShow(true);
                  }}
                >
                  <ListItem
                    component="div"
                    disableGutters
                    data-aos={check ? null : "fade-up"}
                  >
                    <ListItemAvatar>
                      <Box
                        component={Avatar}
                        width={120}
                        height={120}
                        src={
                          item.imageUrl
                            ? item.imageUrl
                            : "https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652__340.png"
                        }
                        marginRight={2}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name ? item.name : "XYZ"}
                      secondary={item.position ? item.position : "XYZ"}
                    />
                  </ListItem>
                </Grid>
              ))}
          </Grid> */}

          {props.modalShow && (
            <Modal
              open={props.modalShow}
              onClose={() => props.aboutUpModalShow(false)}
            >
              <div className={classes.modal}>
                <div className={classes.modalImageContainer}>
                  {props.aboutusData && props.aboutusData.imageUrl ? (
                    <img
                      src={props.aboutusData.imageUrl}
                      className={classes.image}
                    />
                  ) : (
                    <FaUserCircle className={classes.image} />
                  )}
                  <div>{props.aboutusData && props.aboutusData.name}</div>
                  <div>
                    {(props.aboutusData && props.aboutusData.position) || "CEO"}
                  </div>
                </div>
                <div className={classes.aboutMember}>
                  <p
                    className={classes.description3}
                    dangerouslySetInnerHTML={{
                      __html:
                        props.aboutusData && props.aboutusData.about
                          ? draftToHtml(
                              JSON.parse(
                                props.aboutusData && props.aboutusData.about
                              )
                            )
                          : "Team Member"
                    }}
                  />
                </div>
                <div className={classes.socialLinks}>
                  {props.aboutusData && props.aboutusData.facebookUrl && (
                    <FaFacebook
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.facebookUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.twitterUrl && (
                    <AiFillTwitterCircle
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.twitterUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.youtubeUrl && (
                    <AiFillYoutube
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.youtubeUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.discordUrl && (
                    <FaDiscord
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.discordUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.linkedinUrl && (
                    <AiFillLinkedin
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.linkedinUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.instagramUrl && (
                    <GrInstagram
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.instagramUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.mediumUrl && (
                    <AiFillMediumSquare
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.mediumUrl, "_blank")
                      }
                    />
                  )}
                  {props.aboutusData && props.aboutusData.telegramUrl && (
                    <FaTelegramPlane
                      className={classes.icon}
                      onClick={() =>
                        window.open(props.aboutusData.telegramUrl, "_blank")
                      }
                    />
                  )}
                </div>
              </div>
            </Modal>
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default Team;
