import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  setDeleteModal,
  setSendTokenMoal,
  transferTokenButtonLoader,
} from "../../CreatePolicies/Redux/action";
import { messageDataAction } from "../../Login/actions";
import {
  setAddedEmailActionPolicie,
  setInviteBtnLoader,
  setLoaderForUserTable,
  setModalStatus,
} from "./action";
import {
  addPolicieEmailsApi,
  changePolicyRoleApi,
  deletepolicyuser,
  getPolicieEmailsApi,
  sendToken,
} from "./api";
import {
  ADD_USER_EMAILS_POLICIE,
  CHANGE_POLICY_ROLE,
  DELETE_EMAIL_ACTION_POLICIE,
  GET_USER_EMAILS_POLICIE,
  SEND_TOKEN,
} from "./constant";

function* addPolicieEmailsFunction(action) {
  try {
    let state = yield select();
    const response = yield call(addPolicieEmailsApi, action.payload);
    if (response.status === 200) {
      let AddData = [...state.PolicieEmailReducer.emails, response.data.data];
      yield put(setAddedEmailActionPolicie(AddData));
      yield put(
        messageDataAction({
          message: "Emails added successfully",
          status: "success",
        }),
      );
      yield put(setModalStatus(false));
      yield put(setInviteBtnLoader(true));
    }

    if (response.status === 201) {
      yield put(
        messageDataAction({
          message: response.data.message,
          status: "error",
        }),
      );
      yield put(setModalStatus(false));
      yield put(setInviteBtnLoader(false));
    }
    yield put(setInviteBtnLoader(false));
    yield put(setModalStatus(false));
  } catch (e) {
    yield put(setInviteBtnLoader(false));
    yield put(setModalStatus(false));
    console.log(e);
  }
}

function* getPolicieEmailsFunction(action) {
  try {
    const response = yield call(getPolicieEmailsApi);
    if (response.status === 200) {
      yield put(setAddedEmailActionPolicie(response.data.data));
      yield put(setLoaderForUserTable(false));
    }
  } catch (e) {
    yield put(setLoaderForUserTable(false));
    console.log(e);
  }
}
function* deletePolicieEmailFunction(action) {
  try {
    let state = yield select();

    const response = yield call(deletepolicyuser, action.payload);
    if (response.status === 200) {
      let data = state.PolicieEmailReducer.emails.filter(
        (e) => e._id !== action.payload.id,
      );

      yield put(setAddedEmailActionPolicie(data));
      yield put(setDeleteModal(false));
      yield put(
        messageDataAction({
          message: "User deleted successfully",
          status: "success",
        }),
      );
    }
    // if (response.status === 400) {
    //   yield put(setDeleteEmailActionPolicie(action.payload));
    //   yield put(setDeleteModal(false));
    //   yield put(
    //     messageDataAction({
    //       message: "User deleted successfully",
    //       status: "success"
    //     })
    //   );
    // }
  } catch (e) {
    console.log(e);
    yield put(setDeleteModal(false));
  }
}

function* changePolicyRoleSaga(action) {
  try {
    const response = yield call(changePolicyRoleApi, action.payload);
    if (response && response.status === 200) {
      yield put(
        messageDataAction({
          message: "Role Updated Successfully ",
          status: "success",
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* sendTokenFunction(action) {
  try {
    yield put(transferTokenButtonLoader(true));
    const data = action.payload;
    debugger;

    const response = yield call(sendToken, data);
    if (response && response.status === 200) {
      yield put(transferTokenButtonLoader(false));
      yield put(
        messageDataAction({
          message: "The token transfer was Successful.",
          status: "success",
        }),
      );
      yield put(setSendTokenMoal(false));
      return;
    }
    if (response && response.status === 400) {
      yield put(transferTokenButtonLoader(false));
      yield put(
        messageDataAction({
          message: "User have already enough Tokens",
          status: "error",
        }),
      );
      yield put(setSendTokenMoal(false));
      return;
    } else {
      yield put(
        messageDataAction({
          message: "The token transfer was Failed.",
          status: "error",
        }),
      );
    }
    yield put(transferTokenButtonLoader(false));
  } catch (e) {
    yield put(transferTokenButtonLoader(false));
    yield put(
      messageDataAction({
        message: "The token transfer was Failed.",
        status: "error",
      }),
    );
    console.log(e);
  }
}

export default function* PolicieEmailsSaga() {
  yield takeEvery(ADD_USER_EMAILS_POLICIE, addPolicieEmailsFunction);
  yield takeEvery(GET_USER_EMAILS_POLICIE, getPolicieEmailsFunction);
  yield takeEvery(DELETE_EMAIL_ACTION_POLICIE, deletePolicieEmailFunction);
  yield takeEvery(CHANGE_POLICY_ROLE, changePolicyRoleSaga);
  yield takeEvery(SEND_TOKEN, sendTokenFunction);
}
