import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const requestUsersApi = async data => {

  try {
    const result = await getAxiosInstance().get(
      `${API}reviewuserrequest/${data.id}/${data.email}`
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const userResponseApi = async data => {
  try {
    const result = await getAxiosInstance().post(`${API}voteractionforuser_request`, data);
    return result
  } catch (error) {
    console.log(error)
  }
}
