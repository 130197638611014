import { Auth } from "aws-amplify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { signInUserApi } from "../../api";
import { setToken } from "../../utils/axios";
import {
  getprofiledata,
  setUserWalletAddress,
  validateUsersWallet,
} from "../Account/actions";
// import { messageAction } from "../CustomLandingPage/actions";
import { messageDataAction, postLoginSuccess, waitingAction } from "./actions";
import { loginUserEntry, postLoginApi } from "./api";
import { LOGIN_ACCOUNT } from "./constants";

function* loginAccountSaga(action) {
  try {
    const state = yield select();
    const sendToPaymentLink = null;
    yield put(waitingAction(true));
    const response = yield call(signInUserApi, action.payload);

    console.log(response, "::aws res");

    if (response && response.status === 200) {
      const data = {
        userId: response.data.attributes.sub,
        email: response.data.attributes.email,
        given_name: response.data.attributes.given_name || "",
        family_name: response.data.attributes.family_name || "",
        hostname: window.location.hostname,
      };
      const res = yield call(postLoginApi, data);

      if (res && res.status === 200) {
        if (!res.data.data) {
          Auth.signOut();
          yield put(
            messageDataAction({
              message: res.data.message,
              status: data.status === 200 ? "error" : "success",
            }),
          );
          yield put(waitingAction(false));
          return;
        }
        if (
          res.data.data.role === "user" ||
          res.data.data.role === "profileuser"
        ) {
          Auth.signOut();
          yield put(
            messageDataAction({
              message: "Unauthorized access",
              status: "error",
            }),
          );
          yield put(waitingAction(false));
          return;
        }

        if (res.data.data.role === "admin") {
          if (res.data.walletAddress) {
            yield put(setUserWalletAddress(res.data.walletAddress));
            yield put(
              validateUsersWallet({
                status: true,
                MessageStatement: "successfully connected...",
              }),
            );
          } else {
            yield put(
              validateUsersWallet({
                status: false,
                MessageStatement:
                  "You have not Agent ID Please connect to metamask",
              }),
            );
          }

          yield put(
            postLoginSuccess({ userId: response.data.username, isAdmin: true }),
          );
        } else {
          if (res.data.walletAddress) {
            yield put(setUserWalletAddress(res.data.walletAddress));
            yield put(
              validateUsersWallet({
                status: true,
                MessageStatement: "successfully connected...",
              }),
            );
          } else {
            yield put(
              validateUsersWallet({
                status: false,
                MessageStatement:
                  "You have not Agent ID Please connect to metamask",
              }),
            );
          }
          yield put(
            postLoginSuccess({
              userId: response.data.username,
              isAdmin: false,
            }),
          );
        }
        yield put(getprofiledata(res.data.data));
        setToken(response.data.signInUserSession.idToken.jwtToken);
        yield put(
          messageDataAction({
            message: `Successfully Logged In`,
            status: "success",
          }),
        );
        yield put(waitingAction(false));

        if (res.data.data.role === "policyuser") {
          action.payload.history.push("/votecenter");
        } else {
          action.payload.history.push("/createPolicy");
        }

        const loginData = {
          email: res.data.data.email,
          role: res.data.data.role,
          name: `${res.data.data.family_name} ${res.data.data.given_name} `,
        };
        const loginEntry = yield call(loginUserEntry, loginData);
      } else {
        Auth.signOut();
        yield put(
          messageDataAction({
            message: res.data.message,
            status: data.status === 200 ? "error" : "success",
          }),
        );
        yield put(waitingAction(false));
      }
    } else {
      yield put(waitingAction(false));
      yield put(
        messageDataAction({
          message: `please enter valid credential`,
          status: "error",
        }),
      );
    }

    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));
    console.log(err);
  }
}

function* loginSaga() {
  yield takeLatest(LOGIN_ACCOUNT, loginAccountSaga);
}

export default loginSaga;
