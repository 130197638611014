// material-ui
import { Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../../../../images/rocket-joypixels.gif";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const theme = useTheme();
  const verifyRole = useSelector((state) => state.accountData.data.role);

  return (
    <Link
      component={RouterLink}
      to={"/createPolicy"}
      style={{ textDecoration: "none" }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img src={logo} alt={logo} width={"50px"} height={"50px"} />
        <div
          style={{
            fontSize: "18px",
            paddingLeft: "20px",
            paddingTop: "10px",
            color:
              theme.palette.mode === "dark"
                ? "#ffffff"
                : theme.palette.dark.main,
            // color: theme.palette.text.primary
          }}
        >
          Poly Admin
        </div>
      </div>
    </Link>
  );
};

export default LogoSection;
