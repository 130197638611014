import { SET_MY_APPROVED_POLICIES } from "./constant";

const defaultState = {
  approvePolicies: []
};

export const MyApprovedPoliciesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MY_APPROVED_POLICIES:
      return { ...state, approvePolicies: action.payload };
    default:
      return state;
  }
};
