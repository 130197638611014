// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
// project imports
import useConfig from "../../../../../hooks/useConfig";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = (props) => {
  const seasonsLists =
    props.seasonsList &&
    props.seasonsList.find((list) => list.status == "PENDING")?._id;

  const { item, level } = props;
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const { borderRadius } = useConfig();
  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: props.openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: props.openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        ref={ref}
        {...props}
        to={
          item.id == "Resume Games" && seasonsLists
            ? `${item.url2}?id=${seasonsLists}`
            : item.url
              ? item.url
              : document.location.pathname
        }
        target={itemTarget}
      />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    if (id == "#Social Tracker") {
      window.open("https://twitter.com/EdginTimothy");
    }
    props.activeItem([id]);
    if (matchesSM) {
      props.drawerOpenAction(false);
    }
  };

  useEffect(() => {
    // this is for sideNav Active tabs and if some how here you want to or cause of some one else you need to add more tab then you have to manually add link and the id like key value pair in "idFromUrl" object where urls endpoint is your key and value is your id which is you have added.
    let currenPage = window.location.href.split("/").slice(-1);

    const idFromUrl = {
      createPolicy: "#createPolicy",

      securePayment: "#securePayment",
      myapproval: "#myapprovals",
      "createpolicies?voteuser=true": "#votecenter",
      inviteusers: "#policies",
      ChatGroup: "#chatAi",
      metrics: "#metrics",
    };
    Object.keys(idFromUrl).forEach((id) => {
      if (id == currenPage) {
        console.log(id);
        props.activeItem([idFromUrl[currenPage]]);
      }
    });
  }, []);
  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={props.openItem?.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              props.openItem?.findIndex((id) => id === item.id) > -1
                ? "h5"
                : "body1"
            }
            color="inherit"
          >
            {/* {(item.id=="Resume Games" && seasonsLists) ? item.title2 : item.title} */}
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
