import { getAxiosInstance } from "../../utils/axios";
import { API } from "../../utils/config";

export const postLoginApi = async (data) => {
  try {
    let result = await getAxiosInstance().post(`${API}verifyUser`, data);
    return result;
  } catch (err) {
    return { err };
  }
};

export const loginUserEntry = async (data) => {
  try {
    let result = await getAxiosInstance().post(`${API}login_user_entry`, data);
    return result;
  } catch (e) {
    console.log(e);
  }
};
