import { call, put, takeEvery } from "redux-saga/effects";
import { setApprovedPolicies } from "./action";
import { getMyApprovedPoliciesapi } from "./api";
import { GET_MY_APPROVED_POLICIES } from "./constant";

function* getMyApprovedPolicies(action) {
  try {
    const response = yield call(getMyApprovedPoliciesapi);
    if (response.status === 200) {
      yield put(setApprovedPolicies(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* myApprovedPoliciesSaga() {
  yield takeEvery(GET_MY_APPROVED_POLICIES, getMyApprovedPolicies);
}

export default myApprovedPoliciesSaga;
