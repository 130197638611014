import { GET_USER_POLICY_RESPONSE, MESSAGE_DATA, SEND_USER_APPROVAL, SET_REVIEW_LOADER, SET_USER_POLICY_RESPONSE } from "./constant";

export const getUserPolicyResponseData = value => {

    return {
        type: GET_USER_POLICY_RESPONSE,
        payload: value
    }
};
export const setUserPolicyResponseData = value => {
    return {
        type: SET_USER_POLICY_RESPONSE,
        payload: value
    }
};
export const setOnClickUserApproval = value => {
    return {
        type: SEND_USER_APPROVAL,
        payload: value
    }
};
export const setUserApproval = value => {
    return {
        type: SEND_USER_APPROVAL,
        payload: value
    }
};
export const setReviewLoader = value => {
    return {
        type: SET_REVIEW_LOADER,
        payload: value
    }
};
export const messageDataAction = value => {
    return {
        type: MESSAGE_DATA,
        payload: value
    };
};