import {
  IconBasket,
  IconCalendar,
  IconLayoutKanban,
  IconMail,
  IconMessages,
  IconNfc,
  IconUserCheck,
} from "@tabler/icons";

import { FormattedMessage } from "react-intl";

//constant
const icons = {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
};

// ========================================= || Policy Admin || =================================== //

const policyAdmin = {
  id: "#Policy",
  title: <FormattedMessage id="Policy Admin" />,
  type: "group",
  children: [
    {
      id: "#createPolicy",
      title: <FormattedMessage id="Create Policy" />,
      type: "item",
      url: "/createPolicy",
      breadcrumbs: false,
    },
    {
      id: "#secureDocument",
      title: <FormattedMessage id="Secure Documents" />,
      type: "item",
      url: "/createPolicy",
      breadcrumbs: false
    },
    {
      id: "#securePayment",
      title: <FormattedMessage id="Secure Payments" />,
      type: "item",
      url: "/securePayment",
      breadcrumbs: false,
    },
    {
      id: "#myapprovals",
      title: <FormattedMessage id="My Approvals" />,
      type: "item",
      url: "/myapproval",
      breadcrumbs: false,
    },
    {
      id: "#votecenter",
      title: <FormattedMessage id="Vote Center" />,
      type: "item",
      url: "/createPolicy?voteuser=true",
      breadcrumbs: false,
    },
    {
      id: "#requestedpolicy",
      title: <FormattedMessage id="Request Access" />,
      type: "item",
      url: "/pendingpolicies",
      breadcrumbs: false,
    },
    {
      id: "#policies",
      title: <FormattedMessage id="Users" />,
      type: "item",
      url: "/inviteusers",
      breadcrumbs: false,
    },
    {
      id: "#chatAi",
      title: <FormattedMessage id="Chat-AI" />,
      type: "item",
      url: "/ChatGroup",
      breadcrumbs: false,
    },
    {
      id: "#metrics",
      title: <FormattedMessage id="Analytics" />,
      type: "item",
      url: "/metrics",
      breadcrumbs: false,
    },
  ],
};

export default policyAdmin;
