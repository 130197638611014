import {
  LOGIN_ACCOUNT,
  MESSAGE_DATA,
  POST_LOGIN_SUCCESS,
  POST_LOGOUT_SUCCESS,
  REFFEREAL_CODE,
  USER_DATA,
  WAITING_LOGIN
} from "./constants";

export const userDataAction = value => {
  return {
    type: USER_DATA,
    payload: value
  };
};
export const loginAccount = value => {

  return {
    type: LOGIN_ACCOUNT,
    payload: value
  };
};
export const waitingAction = value => {
  return {
    type: WAITING_LOGIN,
    payload: value
  };
};
export const messageDataAction = value => {
  return {
    type: MESSAGE_DATA,
    payload: value
  };
};

export const postLoginSuccess = value => {
  return {
    type: POST_LOGIN_SUCCESS,
    payload: value
  };
};

export const postLogoutSuccess = value => {
  return {
    type: POST_LOGOUT_SUCCESS,
    payload: value
  };
};
export const setRefferalCode = value => {
  return {
    type: REFFEREAL_CODE,
    payload: value
  };
};