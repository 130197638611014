import { Auth } from "aws-amplify";
import { getAxiosInstance } from "../../utils/axios";
import { API } from "../../utils/config";

export const postDataApi = async () => {
  try {
    let isAdmin = false;
    let getCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    let data = await getAxiosInstance().post(`${API}verifyUser`, {
      userId: getCurrentAuthenticatedUser.username,
      email: getCurrentAuthenticatedUser.attributes.email,
      given_name: getCurrentAuthenticatedUser.attributes.given_name || "",
      family_name: getCurrentAuthenticatedUser.attributes.family_name || ""
    });
    console.log(data);
    if (data.status === 200 && data.data.message === "OK") {
      if (data.data.data.role === "admin") {
        isAdmin = true;
      }
    }
    return { userId: getCurrentAuthenticatedUser.username, isAdmin };
  } catch (err) {
    console.log(err);
  }
};
