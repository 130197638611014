import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const SliderImg = props => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      props.imageListForGalleryView.length >= 2
        ? 2
        : props.imageListForGalleryView.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1,
    speed: 6000
  };

  return (
    <div className="sliderImgMain">
      <Slider {...settings}>
        {
          props.imageListForGalleryView.map(item => {
            return <img alt="" src={item.link} />;
          })}
      </Slider>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    imageListForGalleryView:
      state.landingPageData.landingsuccessData.properties.header
        ?.galleryViewImageUrl
  };
};

export default connect(mapStateToProps)(SliderImg);
