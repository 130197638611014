import "aos/dist/aos.css";
import Amplify from "aws-amplify";
import React, { useEffect } from "react";
import "react-image-lightbox/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PolicyAdminRoute from "./PolicyAdmin";
import awsconfig from "./aws/aws-exports";
import {
  setUserWalletAddress,
  setWalletRelatedInfo,
  validateUsersWallet,
} from "./components/Account/actions.js";
import ChatGroup from "./components/ChatRoom/index.js";
import CreatePolicies from "./components/CreatePolicies";
import ForgotPassword from "./components/ForgotPassword";
import VerificationForgotpassword from "./components/ForgotPassword/Verification";
import { postAction } from "./components/Header/actions";
import { messageAction } from "./components/LandingPage/actions.js";
import { Main as MainLayout } from "./components/LandingPageTheme/layouts";
import { Home as HomeView } from "./components/LandingPageTheme/views/landingPages";
import Login from "./components/Login";
import MyApprovedPolicies from "./components/MyApprovals";
import Policy from "./components/PolicyUsers";
import RequestedPendingPolicies from "./components/RequestedPendingPolicies";
import { ReviewApproveOrDenied } from "./components/Review/index.js";
import ReviewUsers from "./components/ReviewUsers";
import Verificationpage from "./components/SignUp/Verification/index";
import SignUp from "./components/SignUp/index";
import Metrics from "./components/metrics/index.js";
import ViewPolicy from "./components/viewPolicy/index.js";
import WithLayout from "./containers/WithLayout";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

Amplify.configure(awsconfig);
const App = (props) => {
  useEffect(() => {
    orientation();
  }, []);

  const orientation = async () => {
    try {
      await window.screen.orientation.lock("landscape");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {/* <WalletValidation /> */}
      <Router>
        <Switch>
          <PrivateRoute
            path="/reviewpolicy"
            component={ReviewApproveOrDenied}
          ></PrivateRoute>
          <PrivateRoute
            path="/reviewuserrequest"
            component={ReviewUsers}
          ></PrivateRoute>
          <PublicRoute
            path="/signup"
            component={() => <SignUp />}
          ></PublicRoute>
          <PublicRoute path="/login" component={() => <Login />}></PublicRoute>
          <PublicRoute
            path="/verificationpage"
            component={() => <Verificationpage />}
          ></PublicRoute>
          <PublicRoute
            path="/forgotPassword"
            component={() => <ForgotPassword />}
          ></PublicRoute>
          <PublicRoute
            path="/forgotPasswordVerify"
            component={() => <VerificationForgotpassword />}
          ></PublicRoute>
          <PolicyAdminRoute
            path="/inviteusers"
            component={Policy}
          ></PolicyAdminRoute>

          <PolicyAdminRoute
            path="/myapproval"
            component={MyApprovedPolicies}
          ></PolicyAdminRoute>

          <PolicyAdminRoute
            path="/createPolicy"
            component={CreatePolicies}
          ></PolicyAdminRoute>



          <PolicyAdminRoute
            path="/securePayment"
            component={CreatePolicies}
          ></PolicyAdminRoute>

          <PolicyAdminRoute
            path="/metrics"
            component={Metrics}
          ></PolicyAdminRoute>

          <PolicyAdminRoute
            path="/votecenter"
            component={CreatePolicies}
          ></PolicyAdminRoute>
          {/* 
          <PolicyAdminRoute
            path="/viewyourpolicy"
            component={ViewPolicy}
          ></PolicyAdminRoute> */}

          <PrivateRoute
            path="/viewyourpolicy"
            component={ViewPolicy}
          ></PrivateRoute>

          <PrivateRoute path="/ChatGroup" component={ChatGroup}></PrivateRoute>

          <PolicyAdminRoute
            path="/pendingpolicies"
            component={RequestedPendingPolicies}
          ></PolicyAdminRoute>
          <Route
            exact
            path="/*"
            render={(matchProps) => (
              <WithLayout
                {...matchProps}
                component={HomeView}
                layout={MainLayout}
              />
            )}
          />
        </Switch>
      </Router>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAction: (values) => {
      dispatch(postAction(values));
    },
    setWalletRelatedInfo: (values) => {
      dispatch(setWalletRelatedInfo(values));
    },
    validateUsersWallet: (value) => {
      dispatch(validateUsersWallet(value));
    },
    messageAction: (value) => {
      dispatch(messageAction(value));
    },
    setUserWalletAddress: (value) => {
      dispatch(setUserWalletAddress(value));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userData.user || { userId: "", isAdmin: false },
    userData: state.userData.userData,
    walletAddress: state.accountData.walletAddress,
    validateWallet: state.accountData.validateWallet,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
