import { all } from "redux-saga/effects";
import accountSaga from "../components/Account/saga";
import chatSaga from "../components/ChatRoom/Redux/saga";
import createPoliciesSaga from "../components/CreatePolicies/Redux/saga";
import forgotPasswordSaga from "../components/ForgotPassword/saga";
import headerSaga from "../components/Header/saga";
import landingSaga from "../components/LandingPage/saga";
import loginSaga from "../components/Login/saga";
import myApprovedPoliciesSaga from "../components/MyApprovals/redux/saga";
import PolicieEmailsSaga from "../components/PolicyUsers/redux/saga";
import { RequestedPendingPoliciesSaga } from "../components/RequestedPendingPolicies/Redux/saga";
import ReviewPolicySaga from "../components/Review/redux/saga";
import { ReviewUserSaga } from "../components/ReviewUsers/redux/saga";
import signUpSaga from "../components/SignUp/saga";
import { metricsDataSaga } from "../components/metrics/redux/saga";
import viewPolicysaga from "../components/viewPolicy/redux/saga";
export default function* rootSaga() {
  yield all([
    landingSaga(),
    headerSaga(),
    signUpSaga(),
    loginSaga(),
    accountSaga(),
    forgotPasswordSaga(),
    PolicieEmailsSaga(),
    createPoliciesSaga(),
    ReviewPolicySaga(),
    ReviewUserSaga(),
    RequestedPendingPoliciesSaga(),
    myApprovedPoliciesSaga(),
    viewPolicysaga(),
    chatSaga(),
    metricsDataSaga(),
  ]);
}
