import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";

// material-ui
import { useTheme } from "@mui/material/styles";
// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import { Auth } from "aws-amplify";
import { forwardRef, useEffect, useRef, useState } from "react";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import useConfig from "../../../../hooks/useConfig";
// project imports
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";

// ==============================|| PROFILE MENU ||============================== //

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileSection = props => {
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.accountData.data.role);
  const profileObj = useSelector(state => state.profileData);

  const userWalletAddress = useSelector(
    state => state.accountData.walletAddress
  );

  const theme = useTheme();
  const history = useHistory();
  const { navType, onChangeMenuType, borderRadius } = useConfig();

  const [value, setValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [messageForModal, setMessageForMOdal] = useState("");

  const anchorRef = useRef(null);

  const addNewWalletAddress = async () => {
    try {
      const requestedAccount = await window.ethereum?.request({
        method: "eth_requestAccounts"
      });
      if (requestedAccount[0] !== userWalletAddress) {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = async () => {
    try {
      Auth.signOut().then(() => {
        props.postLogoutSuccess({
          userId: "",
          isAdmin: false
        });
        props.setUserWalletAddress("");
        props.setWalletRelatedInfo({});
        history.push("/");
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleCloseMessage = () => {
    setOpenModal(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {/* <Stack spacing={2} sx={{ width: "100%" }}> */}
      <Snackbar
        open={openModal}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => handleCloseMessage()}
      >
        <Alert
          onClose={() => handleCloseMessage()}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageForModal}
        </Alert>
      </Snackbar>
      {/* </Stack > */}
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light
            }
          },
          "& .MuiChip-label": {
            lineHeight: 0
          }
        }}
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Good Morning,</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {props.userData && props.userData.given_name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider />
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: "100%",
                        maxHeight: "calc(100vh - 250px)",
                        overflowX: "hidden"
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        {/* <Card
                          sx={{
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? theme.palette.dark[800]
                                : theme.palette.primary.light,
                            my: 2
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={3} direction="column">
                              <Grid item>
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="subtitle1">
                                      DARK
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Switch
                                      color="primary"
                                      checked={navType === "dark"}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          onChangeMenuType("dark");
                                        } else {
                                          onChangeMenuType("light");
                                        }
                                      }}
                                      name="sdm"
                                      size="small"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card> */}
                        {/* <Divider /> */}
                        <List
                          component="nav"
                          sx={{
                            marginTop: "10px",
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%"
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5
                            }
                          }}
                        >
                          {/* <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            onClick={() => {
                              addNewWalletAddress()
                            }}
                          >

                            <ListItemIcon>
                              <IconWallet stroke={1.5} size="1.3rem" />
                            </ListItemIcon>

                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Add New Wallet Address
                                </Typography>
                              }
                            />

                          </ListItemButton> */}

                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
