import { call, put, takeEvery } from "redux-saga/effects";
import { setRequestUserData, setReviewUserLoader } from "./action";
import { requestUsersApi, userResponseApi } from "./api";
import { GET_REQUESTED_DATA, USER_RESPONSE } from "./constant";

function* RequestedUserData(action) {
  try {
    const response = yield call(requestUsersApi, action.payload);

    if (response && response.status === 200) {
      yield put(setRequestUserData(response.data.data));
      yield put(setReviewUserLoader(false));
    } else {
      action.payload.history.push("/createPolicy");
    }
  } catch (error) {
    yield put(setReviewUserLoader(false));
    action.payload.history.push("/createPolicy");
    console.log(error);
  }
}
function* UserResponse(action) {
  try {
    const response = yield call(userResponseApi, action.payload);
    console.log(response);
    if (response.status === 200) {
      yield put(setReviewUserLoader(false));
      action.payload.history.push("/createPolicy");
    }
    yield put(setReviewUserLoader(false));
  } catch (error) {
    yield put(setReviewUserLoader(false));
    console.log(error);
  }
}

export function* ReviewUserSaga() {
  yield takeEvery(GET_REQUESTED_DATA, RequestedUserData);
  yield takeEvery(USER_RESPONSE, UserResponse);
}
