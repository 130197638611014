import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import Container from "../../../../containers/common/Container";

import {
  claimNftModal,
  headerAccountAction,
  headerAirDropData,
  headerNetworkId,
  headerSetBalance,
  messageAction,
  nftFactoryContractMint,
  showAccountModal
} from "../../../LandingPage/actions";

import { Sidebar, Topbar } from "./components";

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired
};

const Main = props => {
  const {
    children,
    themeToggler,
    themeMode,
    setThemePalette,
    paletteType
  } = props;
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <div>
      <HideOnScroll>
        <AppBar
          position={"fixed"}
          sx={{
            display:
              props.themeType === "SquareSupply"
                ? "none"
                : props.themeType == process.env.THEME_TYPE
                  ? "flex"
                  : props.themeType === "brentrice" &&
                    props.header &&
                    props.header.topBar
                    ? "flex"
                    : "none",
            backgroundColor:
              props.displayType == "matrix"
                ? "black"
                : theme.palette.background.paper
          }}
          elevation={1}
        >
          {window.location.pathname !== "/profile" && (
            <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
              {props.header && props.header.topBar ? (
                <Topbar
                  onSidebarOpen={handleSidebarOpen}
                  themeMode={themeMode}
                  themeToggler={themeToggler}
                  setThemePalette={setThemePalette}
                  nftFactoryContractMint={props.nftFactoryContractMint}
                  paletteType={paletteType}
                  claimNftModal={props.claimNftModal}
                  data={props.landingsuccessData}
                  allAirdropData={
                    props.landingsuccessData &&
                    props.landingsuccessData.airdropData
                  }
                  user={props.user}
                  messageAction={props.messageAction}
                  setClaimNftModal={props.setClaimNftModal}
                  accountModal={props.accountModal}
                  showAccountModal={props.showAccountModal}
                  airDropData={props.airDropData}
                  headerAirDropData={props.headerAirDropData}
                  balance={props.balance}
                  headerSetBalance={props.headerSetBalance}
                  headerAccount={props.headerAccount}
                  headerAccountAction={props.headerAccountAction}
                  headerNeworkId={props.headerNeworkId}
                  headerNetworkIdAction={props.headerNetworkIdAction}
                  headerSidebar={props.headerSidebar}
                  headerSideBarShow={props.headerSideBarShow}
                  colorgroup={props.colorgroup}
                  headerColorAction={props.headerColorAction}
                  themecolor={props.themecolor}
                  themeBackgroundAction={props.themeBackgroundAction}
                // updatenftblockchain={props.updatenftblockchain}
                />
              ) : (!props.themeType ||
                (props.themeType && props.themeType == process.env.THEME_TYPE)) ? (
                <Topbar
                  onSidebarOpen={handleSidebarOpen}
                  themeMode={themeMode}
                  themeToggler={themeToggler}
                  setThemePalette={setThemePalette}
                  nftFactoryContractMint={props.nftFactoryContractMint}
                  paletteType={paletteType}
                  claimNftModal={props.claimNftModal}
                  data={props.landingsuccessData}
                  allAirdropData={
                    props.landingsuccessData &&
                    props.landingsuccessData.airdropData
                  }
                  user={props.user}
                  messageAction={props.messageAction}
                  setClaimNftModal={props.setClaimNftModal}
                  accountModal={props.accountModal}
                  showAccountModal={props.showAccountModal}
                  airDropData={props.airDropData}
                  headerAirDropData={props.headerAirDropData}
                  balance={props.balance}
                  headerSetBalance={props.headerSetBalance}
                  headerAccount={props.headerAccount}
                  headerAccountAction={props.headerAccountAction}
                  headerNeworkId={props.headerNeworkId}
                  headerNetworkIdAction={props.headerNetworkIdAction}
                  headerSidebar={props.headerSidebar}
                  headerSideBarShow={props.headerSideBarShow}
                  colorgroup={props.colorgroup}
                  headerColorAction={props.headerColorAction}
                  themecolor={props.themecolor}
                  themeBackgroundAction={props.themeBackgroundAction}
                // updatenftblockchain={props.updatenftblockchain}
                />
              ) : (
                ""
              )}
            </Container>)
          }
        </AppBar>
      </HideOnScroll>
      <Sidebar
        onClose={handleSidebarClose}
        data={props.landingsuccessData}
        open={openSidebar}
        themeMode={themeMode}
        variant="temporary"
        messageAction={props.messageAction}
        headerAccountAction={props.headerAccountAction}
        headerNetworkIdAction={props.headerNetworkIdAction}
        headerSetBalance={props.headerSetBalance}
        showAccountModal={props.showAccountModal}
        accountModal={props.accountModal}
        headerAccount={props.headerAccount}
        headerNeworkId={props.headerNeworkId}
        // homeData={props.homeData}
        balance={props.balance}
      />
      <main>
        <Box
          height={{
            xs:
              props.themeType === "SquareSupply"
                ? 0
                : props.themeType == process.env.THEME_TYPE
                  ? 56
                  : props.themeType === "brentrice" &&
                    props.header &&
                    props.header.topBar
                    ? 110
                    : 50,
            sm:
              props.themeType === "SquareSupply"
                ? 0
                : props.themeType == process.env.THEME_TYPE
                  ? 64
                  : props.themeType === "brentrice" &&
                    props.header &&
                    props.header.topBar
                    ? 110
                    : 50
          }}
        />
        {children}
        <Divider />
      </main>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setClaimNftModal: data => {
      dispatch(claimNftModal(data));
    },
    nftFactoryContractMint: data => {
      dispatch(nftFactoryContractMint(data));
    },
    showAccountModal: data => {
      dispatch(showAccountModal(data));
    },
    headerAccountAction: data => {
      dispatch(headerAccountAction(data));
    },
    messageAction: data => {
      dispatch(messageAction(data));
    },
    headerNetworkIdAction: data => {
      dispatch(headerNetworkId(data));
    },
    headerSetBalance: data => {
      dispatch(headerSetBalance(data));
    },
    headerAirDropData: data => {
      dispatch(headerAirDropData(data));
    }
    // updatenftblockchain: data => {
    //   dispatch(updatenftblockchain(data));
    // },
  };
};

const mapStateToProps = state => {
  return {
    user: state.userData.user || { userId: "", isAdmin: false },
    modalShow: state.landingPageData.modalShow,
    aboutusData: state.landingPageData.aboutusData,
    aboutusIndex: state.landingPageData.aboutusIndex,
    isMessage: state.landingPageData.isMessage,
    claimNftModal: state.landingPageData.claimNftModal,
    accountModal: state.landingPageData.accountModal,
    airDropData: state.landingPageData.airDropData,
    balance: state.landingPageData.balance,
    headerAccount: state.landingPageData.headerAccount,
    headerNeworkId: state.landingPageData.headerNeworkId,
    landingsuccessData: state.landingPageData.landingsuccessData,
    stateLoading: state.landingPageData.isLoading,
    nftfactoryContract: state.landingPageData.nftfactoryContract,
    headerSidebar: state.landingPageData.headerSidebar,
    colorgroup: state.landingPageData.colorgroup,
    themecolor: state.landingPageData.themecolor,
    balance: state.landingPageData.balance,
    header: state?.landingPageData?.landingsuccessData?.properties?.header,
    themeType:
      state?.landingPageData?.landingsuccessData?.properties?.header?.themeType,
    displayType:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.displayType
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
