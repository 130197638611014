import { call, put, takeEvery } from "redux-saga/effects";
import {
  setLoaderMetrics,
  setMetricsData,
  setTotalActivityCount,
  setTotalRegisterUser,
} from "./action";
import { getMetricsDataApi } from "./api";
import { GET_METRICS_DATA } from "./constant";

function* getMetricsDataSaga(action) {
  try {

    const response = yield call(getMetricsDataApi);
    if (response && response.status === 200) {
      yield put(setLoaderMetrics(false));
      yield put(setMetricsData(response.data.data));
      yield put(setTotalActivityCount(response.data.totalActivity));
      yield put(setTotalRegisterUser(response.data.totalRegister));
    }
    yield put(setLoaderMetrics(false));
  } catch (e) {
    yield put(setLoaderMetrics(false));
    console.log(e);
  }
}

export function* metricsDataSaga() {
  yield takeEvery(GET_METRICS_DATA, getMetricsDataSaga);
}
