import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import draftToHtml from "draftjs-to-html";
import React from "react";

const Main = props => {
  const theme = useTheme();
  let checkAddress = window.location.href;
  let check = checkAddress.includes("customLandingPage");

  return (
    <>
      <Box>
        {props.data ? (
          <Box marginBottom={4}>
            <Box
              component={Typography}
              fontWeight={700}
              variant={"h3"}
              gutterBottom
              align={"center"}
              data-aos={check ? null : "fade-up"}
            >
              {props.data
                ? props.data.properties.stats.title.value
                : "Stats Title"}
            </Box>
            <Typography
              variant={"h6"}
              component={"p"}
              color={"textSecondary"}
              align={"center"}
              data-aos={check ? null : "fade-up"}
              dangerouslySetInnerHTML={{
                __html: props.data
                  ? draftToHtml(
                      JSON.parse(props.data.properties.stats.description)
                    )
                  : "Stats Description"
              }}
            />
          </Box>
        ) : (
          ""
        )}

        <Grid container spacing={4} display="flex" justifyContent={"center"}>
          {props.data &&
            props.data.properties.stats.file.map((item, i) => (
              <>
                {item.filedata && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={i}
                      data-aos={check ? null : "fade-up"}
                    >
                      <Box
                        component={"a"}
                        href={""}
                        display={"block"}
                        width={"100%"}
                        height={"100%"}
                        sx={{
                          textDecoration: "none",
                          transition: "all .2s ease-in-out",
                          "&:hover": {
                            transform: `translateY(-${theme.spacing(1 / 2)})`
                          }
                        }}
                      >
                        <Box
                          component={Card}
                          width={"100%"}
                          height={"100%"}
                          borderRadius={3}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <CardMedia
                            image={
                              item.filedata && item.filedata.fileUrl
                                ? item.filedata.fileUrl
                                : item.filedata
                            }
                            title={item.title}
                            sx={{
                              height: 240
                            }}
                          />
                          <Box component={CardContent}>
                            <Box
                              component={Typography}
                              variant={"h6"}
                              gutterBottom
                              fontWeight={500}
                              align={"left"}
                            >
                              {item.title}
                            </Box>
                            <Typography
                              align={"left"}
                              variant={"body2"}
                              color="textSecondary"
                            >
                              {item.description}
                            </Typography>
                          </Box>
                          <Box flexGrow={1} />
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default Main;
