// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#e3e8e8",
	"primary200": "#8b9fa1",
	"primaryMain": "#173e43",
	"primaryDark": "#14383d",
	"primary800": "#0d282c",
	"secondaryLight": "#e8f6f5",
	"secondary200": "#9fd8d6",
	"secondaryMain": "#3fb0ac",
	"secondaryDark": "#39a9a5",
	"secondary800": "#299792",
	"successLight": "#b9f6ca",
	"success200": "#69f0ae",
	"successMain": "#00e676",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#c62828",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fff8e1",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#051114",
	"darkBackground": "#030708",
	"darkLevel1": "#02131d",
	"darkLevel2": "#051114",
	"darkTextTitle": "#ffffff",
	"darkTextPrimary": "#ffffff",
	"darkTextSecondary": "#ccd2eb",
	"darkPrimaryLight": "#e3e8e8",
	"darkPrimaryMain": "#3a5b5f",
	"darkPrimaryDark": "#14383d",
	"darkPrimary200": "#8b9fa1",
	"darkPrimary800": "#0d282c",
	"darkSecondaryLight": "#e8f6f5",
	"darkSecondaryMain": "#3fb0ac",
	"darkSecondaryDark": "#39a9a5",
	"darkSecondary200": "#9fd8d6",
	"darkSecondary800": "#299792"
};
export default ___CSS_LOADER_EXPORT___;
