import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  messageDataAction,
  setReviewLoader,
  setUserPolicyResponseData,
} from "./action";
import { getReviewPolicyapi, sendReviewPolicyResponseapi } from "./api";
import { GET_USER_POLICY_RESPONSE, SEND_USER_APPROVAL } from "./constant";

function* getUserPolicyResponseData(action) {
  let response;
  try {
    const res = yield call(getReviewPolicyapi, action.payload.id);
    if (res.data) {
      response = res.data;
      yield put(setUserPolicyResponseData(response.data.data));
      yield put(setReviewLoader(false));
    }
    if (res.error) {
      yield put(setReviewLoader(false));
      action.payload.history.push("/createPolicy");
    }
  } catch (e) {
    yield put(
      messageDataAction({
        message: "Policy Rejected",
        status: "error",
      }),
    );
    console.log(e);
  }
}
function* sendUserResponseToApi(action) {
  try {
    let data = action.payload;

    const response = yield call(sendReviewPolicyResponseapi, data);

    if (response.status === 200) {
      yield put(setReviewLoader(false));
      yield put(
        messageDataAction({
          message: "Thanks For Response. Please wait, redirecting...",
          status: "success",
        }),
      );
      yield delay(5000);
      action.payload.history.push("/createPolicy");
    }
    yield put(setReviewLoader(false));
  } catch (e) {
    yield put(setReviewLoader(false));
    console.log(e);
  }
}

function* ReviewPolicySaga() {
  yield takeEvery(GET_USER_POLICY_RESPONSE, getUserPolicyResponseData);
  yield takeEvery(SEND_USER_APPROVAL, sendUserResponseToApi);
}

export default ReviewPolicySaga;
