import {
  MESSAGE_DATA,
  POST_LOGIN_SUCCESS,
  POST_LOGOUT_SUCCESS,
  REFFEREAL_CODE,
  USER_DATA,
  WAITING_LOGIN
} from "./constants";

const defaultState = {
  user: { userId: "", isAdmin: false },
  message: [],
  waitingLogin: false,
  userData: null,
  refferal_code: "",
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case POST_LOGIN_SUCCESS:

      return { ...state, user: action.payload };
    case USER_DATA:
      return { ...state, userData: action.payload };
    case POST_LOGOUT_SUCCESS:
      return { ...state, user: action.payload };
    case WAITING_LOGIN:
      return { ...state, waitingLogin: action.payload };
    case MESSAGE_DATA:
      return { ...state, message: action.payload };
    case REFFEREAL_CODE:
      return { ...state, refferal_code: action.payload };
    default:
      return state;
  }
};

export default authReducer;
