import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../index.css";

const ChatGroupList = props => {
  const history = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const { AllChatList, EditchatId } = props;
  const id = window.location.search.split("?id=")[1];

  useEffect(() => {
    if (id) {
      props.selectedChatMessageIdAction(id);
    }
  }, [id]);
  return (
    <div>
      {AllChatList &&
        AllChatList.map(e => (
          <div
            key={e._id}
            class="groupItem"
            onClick={() => {
              if (!EditchatId && !isDelete) {
                props.selectedChatMessageIdAction(e._id);
                history.push(`/ChatGroup?id=${e._id}`);
              }
            }}
            style={{
              background:
                e._id == EditchatId || e._id == props.selectedMessageId
                  ? "rgb(52 53 65)"
                  : "transparent"
            }}
          >
            <input
              value={e._id == EditchatId ? props.editchatname : e.chatName}
              disabled={!EditchatId || isDelete}
              className="InputChatName"
              style={{
                background:
                  e._id == EditchatId ? "rgb(52 53 65)" : "transparent"
              }}
              onChange={e => {
                props.editChatNameAction(e.target.value);
              }}
            />

            {e._id == EditchatId ? (
              <div className="GroupIcon">
                <DoneIcon
                  fontSize="14px"
                  className="EditIcon"
                  onClick={() => {
                    if (isDelete) {
                      props.deleteChatFromListAction({
                        id: EditchatId,
                        history: history
                      });
                      setIsDelete(false);
                    } else {
                      props.editChatNameInListAction({
                        id: EditchatId,
                        chatName: props.editchatname
                      });
                    }
                  }}
                />
                <CloseIcon
                  fontSize="14px"
                  className="DeleteIcon"
                  onClick={() => {
                    if (isDelete) {
                      setIsDelete(false);
                    } else {
                      props.editChatNameAction("");
                    }
                    props.selectedEditChatIdAction("");
                  }}
                />
              </div>
            ) : (
              <div className="GroupIcon">
                <EditIcon
                  fontSize="14px"
                  className="EditIcon"
                  onClick={() => {
                    setIsDelete(false);
                    props.editChatNameAction(e.chatName);
                    props.selectedEditChatIdAction(e._id);
                  }}
                />
                <DeleteIcon
                  fontSize="14px"
                  className="DeleteIcon"
                  onClick={() => {
                    setIsDelete(true);
                    props.editChatNameAction(e.chatName);
                    props.selectedEditChatIdAction(e._id);
                  }}
                />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ChatGroupList;
