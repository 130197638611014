import { makeStyles } from "@material-ui/styles";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import FileViewer from "react-file-viewer";
import { IoArrowBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getViewPolicyData, setViewPolicyData } from "./redux/action";

const ViewPolicy = () => {
  const history = useHistory();
  const id = window.location.pathname.split("/")[2];

  const data = useSelector((state) => state.viewPolicyReducer.viewPolicyData);
  const loaderData = useSelector(
    (state) => state.CreatePolicesReducer.createPolicyLoader
  );

  const dispatch = useDispatch();

  const goBackFunc = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/myapproval");
    }
  };

  useEffect(() => {
    dispatch(getViewPolicyData({ id, history }));
    return () => dispatch(setViewPolicyData());
  }, []);

  const useStyles = makeStyles(() => ({
    mainContainer: {
      width: "100%",
      height: "83vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      gap: "10px",
      marginLeft: "10px",
      margin: "auto",
    },
    dwnldDocument: {
      display: "flex",
      height: "250px",
      alignItems: "center",
      flexDirection: "column",
      "&  a": {
        fontSize: "210px",
        margin: "10px",
      },
      "& h2": {
        margin: "10px",
      },
    },
    btns: {
      display: "flex",
      flexDirection: "column",
    },
    approveAndDenyBtn: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "48%",
      cursor: "pointer",
      alignItems: "center",
      gap: "20px",
    },
    nameAndDescription: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "15px",
      height: "15vh",
      marginTop: "20px",
    },
    backArrow: {
      position: "relative",
      top: "50px",
      left: "10px",
      fontSize: "20px",
      cursor: "pointer",
      height: "20px",
      width: "20px",
    },
  }));

  const classes = useStyles();

  return data?.policyName ? (
    <div className={classes.mainContainer}>
      <span
        className={classes.backArrow}
        onClick={() => {
          goBackFunc();
        }}
      >
        <IoArrowBackSharp />
      </span>

      <div className={classes.nameAndDescription}>
        <p
          style={{
            fontSize: "20px",
          }}
        >
          {data?.policyName}
        </p>
        <p
          style={{
            width: "50%",
            overflowY: "hidden",
            fontSize: "15px",
            height: "6vh",
            textAlign: "center",
            lineHeight: "3vh",
          }}
        >
          {data?.policyDescription}
        </p>
      </div>

      {data?.userStripeAccountId ? (
        <>
          <div
            style={{
              width: "100%",
              maxWidth: "611px",
              height: "40%",
              marginLeft: "auto",
              marginRight: "auto",

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>AccountID :{data?.userStripeAccountId}</h2>
            <h2>Amount:{data?.holdAmount}</h2>
          </div>
        </>
      ) : (
        <>
          {data?.policyDoc ? (
            <>
              <div
                style={{
                  width: "100%",
                  maxWidth: "611px",
                  height: "58vh",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <FileViewer
                  fileType={data?.policyDoc
                    ?.trim()
                    .split("/")
                    .pop()
                    .trim()
                    .split(".")
                    .pop()
                    .trim()}
                  filePath={data?.policyDoc}
                />
              </div>
              <div className={classes.btns}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    href={`${data?.policyDoc}`}
                    style={{
                      padding: "10px",
                      width: "48%",
                      color: "white",
                      fontSize: "19px",
                    }}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>
                To view the related document please request to admin to give you
                a permission
              </span>
              <Button>Request</Button>
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderData}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default ViewPolicy;
