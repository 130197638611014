export default async (
  wallet,
  headerNetworkIdAction,
  setWalletType,
  messageAction
) => {
  try {
    if (window.ethereum) {
      try {
        const networkId = await window.ethereum.request({
          method: "net_version"
        });
        headerNetworkIdAction(networkId);
        setWalletType(wallet);
        const chain = networkId === "1" || networkId === "4" ? "ETH" : "MATIC";
      } catch (error) {
        let msg =
          error?.message == "Please install wallet"
            ? error?.message
            : "please enable metamask";
        messageAction({
          message: msg,
          status: "error"
        });
        console.log(error.message);
      }
    } else {
      alert("You have to install MetaMask !");
    }
  } catch (error) {
    if (error.code == 4001) {
      messageAction({
        message: `You have rejected transaction.`,
        status: "error"
      });
    } else {
      messageAction({
        message: `something went wrong please try refreshing page.`,
        status: "error"
      });
    }
    console.log(error);
  }
};
