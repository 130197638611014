import { combineReducers } from "redux";
import accountReducer from "../components/Account/reducer";
import chatReducer from "../components/ChatRoom/Redux/reducer";
import CreatePolicesReducer from "../components/CreatePolicies/Redux/reducer";
import verifyReducer from "../components/ForgotPassword/reducer";
import headerReducer from "../components/Header/reducer";
import landingReducer from "../components/LandingPage/reducers";
import authReducer from "../components/Login/reducer";
import { MyApprovedPoliciesReducer } from "../components/MyApprovals/redux/reducer";
import PolicieEmailReducer from "../components/PolicyUsers/redux/reducer";
import ReuestedPendingPoliciesReducer from "../components/RequestedPendingPolicies/Redux/reducer";
import ReviewUserPolicyReducer from "../components/Review/redux/reducer";
import ReviewUserReducer from "../components/ReviewUsers/redux/reducer";
import signupReducer from "../components/SignUp/reducer";
import { metricsDataReducer } from "../components/metrics/redux/reducer";
import { viewPolicyReducer } from "../components/viewPolicy/redux/reducer";
export default combineReducers({
  landingPageData: landingReducer,
  userData: authReducer,
  headerData: headerReducer,
  accountData: accountReducer,
  signup: signupReducer,
  frogetverify: verifyReducer,
  chatReducer: chatReducer,
  PolicieEmailReducer: PolicieEmailReducer,
  CreatePolicesReducer: CreatePolicesReducer,
  ReviewUserPolicyReducer: ReviewUserPolicyReducer,
  ReviewUserReducer: ReviewUserReducer,
  ReuestedPendingPoliciesReducer: ReuestedPendingPoliciesReducer,
  MyApprovedPoliciesReducer: MyApprovedPoliciesReducer,
  viewPolicyReducer: viewPolicyReducer,
  metricsDataReducer: metricsDataReducer,
});
