import { GET_REQUESTED_DATA, SET_REQUESTED_DATA, SET_REVIEW_USER_LOADER, USER_RESPONSE } from "./constant";

export const getRequestUserData = value => {

  return {
    type: GET_REQUESTED_DATA,
    payload: value
  };
};

export const setRequestUserData = value => {
  return {
    type: SET_REQUESTED_DATA,
    payload: value
  }
}

export const userResponsAction = value => {
  return {
    type: USER_RESPONSE,
    payload: value
  }
}

export const setReviewUserLoader = value => {
  return {
    type: SET_REVIEW_USER_LOADER,
    payload: value
  }
}
