import {
  FormControl,
  Hidden,
  MenuItem,
  Modal,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import MUITextField from "@mui/material/TextField";
import { IoMdArrowDropupCircle } from "react-icons/io";
import { MdArrowDropDownCircle } from "react-icons/md";

import TextField from "@material-ui/core/TextField";
import ShareIcon from "@mui/icons-material/Share";
import { Button, Modal as MUIModal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import copy from "copy-to-clipboard";
import draftToHtml from "draftjs-to-html";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import metamask from "../../../../../../images/MetaMask_Fox.svg.png";
import coinbase from "../../../../../../images/coinbase.png";
import logo from "../../../../../../images/rocket-joypixels.gif";
import { API } from "../../../../../../utils/config";
import handleConnect from "../../../../../../utils/connectWalletFunctions";
import useConfig from "../../../../../HeaderLayout/hooks/useConfig";
import { headerWalletType } from "../../../../../LandingPage/actions";

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" }
];

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

const steps = [
  "Twitter Handle",
  "Startup Details",
  "Additional Help",
  "Preview",
  "Finishing Step"
];

const Topbar = props => {
  const {
    themeMode,
    themeToggler,
    setThemePalette,
    onSidebarOpen,
    paletteType,
    allAirdropData
  } = props;

  const theme = useTheme();
  const { walletType, onChangeWalletType } = useConfig();
  const [showWalletType, setShowWalletType] = useState(false);

  const location = useLocation();

  const history = useHistory();

  const [pageCreationError, setPageCreationError] = useState(false);
  const [showBtn, setshowBtn] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleReset();
  };

  const isStepOptional = step => {
    return step === 2;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = formik => {
    let newSkipped = skipped;
    if (
      activeStep === 0 &&
      (formik.errors["twitter"] || formik.values["twitter"] === "")
    ) {
      props.messageAction({
        message: `entered input is not valid!`,
        status: "error"
      });
      return;
    }
    if (
      activeStep === 1 &&
      (formik.errors["idea"] ||
        formik.errors["description"] ||
        formik.values["idea"] === "" ||
        formik.values["description"] === "")
    ) {
      props.messageAction({
        message: `entered input is not valid!`,
        status: "error"
      });
      return;
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 2) {
      formik.submitForm();
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = formik => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    formik.setFieldValue("additionalHelp", "");
    handleNext(formik);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const useStyles = makeStyles(() => ({
    modal: {
      backgroundColor: "#10100d",
      width: "940px",
      height: "550px",
      marginLeft: "180px",
      marginTop: "50px",
      overflow: "auto"
    },
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "800px",
      "@media (max-width: 900px)": {
        width: "90%"
      }
    },
    stepper: {
      "@media (max-width: 600px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        gridGap: "10px"
      }
    },
    field: {
      width: "350px",
      marginTop: "20px !important",
      marginRight: "20px !important",
      color: "white"
    },
    btnGroup: {
      // "@media (max-width: 600px)": {
      //   display: "flex",
      //   flexDirection: "column",
      //   gridGap: "5px"
      // },
      "@media (max-width: 768px)": {
        display: "none"
      }
    },
    mobBtn: {
      display: "none",
      "@media(max-width:768px)": {
        display: "block"
      }
    },
    formControl: {
      width: "300px",
      margin: "30px",
      color: "white !important",
      fontColor: "white !important",
      "& svg": { color: "rgb(232 232 232 / 54%) !important" }
    },
    select: {
      background: "white"
    },
    submitBtn: {
      fontSize:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.fontSize
          : "12px",
      color:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.fontColor
          : "#ffffff",
      backgroundColor:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.btnColor
          : "#1976d2",
      "&:hover": {
        backgroundColor:
          props.airDropData && props.airDropData.submitButton
            ? props.airDropData.submitButton.btnColor
            : "#1976d2"
      }
    },
    modal2: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "320px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px"
    },
    modal3: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "180px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px"
    },
    drawerLogo: {
      textAlign: "center",
      color: props.colorgroup
    },
    modelheading: {
      // backgroundColor: "red",
      textAlign: "center",
      fontSize: "5rem",
      fontWeight: "bold"
    }
  }));

  const classes = useStyles();

  const StepsJSX = [
    formik => (
      <Fragment>
        <Typography
          sx={{
            mb: 2
          }}
          color={themeMode === "light" ? "black" : "white"}
        >
          What's your Twitter handle? (We'll send you a tweet with your site
          details.)
        </Typography>
        <MUITextField
          sx={{
            mb: 2
          }}
          fullWidth
          color="secondary"
          id="twitter"
          name="twitter"
          label="handle"
          onBlur={formik.handleBlur}
          value={formik.values.twitter}
          onChange={formik.handleChange}
          error={formik.touched.twitter && Boolean(formik.errors.twitter)}
          helperText={formik.touched.twitter && formik.errors.twitter}
        />
      </Fragment>
    ),
    formik => (
      <Fragment>
        <Typography
          sx={{
            mb: 2
          }}
          color={themeMode === "light" ? "black" : "white"}
        >
          What's your startup or project name? (short input).
        </Typography>
        <MUITextField
          fullWidth
          sx={{
            mb: 2
          }}
          id="idea"
          name="idea"
          label="Startup Idea"
          color="secondary"
          value={formik.values.idea}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.idea && Boolean(formik.errors.idea)}
          helperText={formik.touched.idea && formik.errors.idea}
        />
        <Typography
          sx={{
            mb: 2
          }}
          color={themeMode === "light" ? "black" : "white"}
        >
          Could you please briefly tell us what your project or startup is
          about?
        </Typography>
        <MUITextField
          fullWidth
          sx={{
            mb: 2
          }}
          id="description"
          name="description"
          color="secondary"
          label="Startup Description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          multiline
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
      </Fragment>
    ),
    formik => (
      <Fragment>
        <Typography
          sx={{
            mb: 2
          }}
          color={themeMode === "light" ? "black" : "white"}
        >
          Do you need any additional help from an artist, Discord, smart
          contracts, merch, or others? If so, please let us know how we can
          assist you. (short description)
        </Typography>
        <MUITextField
          fullWidth
          sx={{
            mb: 2
          }}
          id="additionalHelp"
          name="additionalHelp"
          color="secondary"
          label="Additional Help"
          value={formik.values.additionalHelp}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched.additionalHelp &&
            Boolean(formik.errors.additionalHelp)
          }
          helperText={
            formik.touched.additionalHelp && formik.errors.additionalHelp
          }
        />
      </Fragment>
    ),
    formik => (
      <Fragment>
        <Typography
          sx={{
            mb: 2
          }}
          color={themeMode === "light" ? "black" : "white"}
        >
          Here is Preview of Information you entered. Verfiy it and make changes
          if required.
        </Typography>
        <hr
          style={{
            marginBottom: "1rem"
          }}
        />
        {Object.keys(formik.values).map(key => {
          if (formik.values[key] === "") {
            return;
          }
          return (
            <Typography
              key={key}
              color={themeMode === "light" ? "black" : "white"}
              sx={{
                mb: 2
              }}
            >
              {`${key} : ${formik.values[key]}`}
            </Typography>
          );
        })}
      </Fragment>
    ),
    formik => (
      <Typography
        sx={{
          mb: 2
        }}
        color={themeMode === "light" ? "black" : "white"}
      >
        Creating and Submitting Page... It will take Few moments....Please
        Wait...
      </Typography>
    )
  ];

  const validationSchemaCreatePage = yup.object({
    idea: yup.string("Enter your idea").required("Idea is required"),
    description: yup
      .string("Enter idea description")
      .required("description is required"),
    twitter: yup
      .string("Enter your twitter handle")
      .required("twitter handle is required!")
  });

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", accounts => {
      props.headerAccountAction(accounts);
    });
  }

  const networkMap = {
    1: "https://etherscan.io/",
    4: "https://rinkeby.etherscan.io/",
    137: "http://polygonscan.com/",
    80001: "https://mumbai.polygonscan.com/"
  };

  const validationSchema = yup.object().shape({
    questions: yup.array().of(
      yup.object().shape({
        answer: yup
          .string()
          .test(
            yup.string(),
            "Required!",
            (value, context) => !context.parent.required || value
          )
      })
    )
  });

  const validate = value => {
    let error;
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (value && !value.match(phoneno)) {
      error = "Please Enter a Valid Phone Number!";
    }
    return error;
  };

  const data = props.data && props.data.properties.headerTabBar.headerIcon;
  const data2 = props.data && props.data.properties.headerTabBar;

  // responsive menu

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          {(props.data &&
            (data.facebookUrl ||
              data.discordUrl ||
              data.instagramUrl ||
              data.linkedinUrl ||
              data.mediumUrl ||
              data.otherUrl ||
              data.telegramUrl ||
              data.twitchUrl ||
              data.twitterUrl ||
              data.websiteUrl ||
              data.youtubeUrl) &&
            location.pathname !== "/withdraw" && (
              <IconButton onClick={onSidebarOpen} aria-label="Menu">
                <MenuIcon />
              </IconButton>

              // <IconButton
              //   color="inherit"
              //   aria-label="open drawer"
              //   onClick={onSidebarOpen}
              //   edge="start"
              //   sx={{ mr: 2, ...(open && { display: "none" }) }}
              // >
              //   <MenuIcon />
              // </IconButton>
            )) || (
              <Hidden oxnly={["md", "lg", "xl"]}>
                {location.pathname !== "/withdraw" ||
                  (location.pathname !== "products" && (
                    <IconButton onClick={onSidebarOpen} aria-label="Menu">
                      <MenuIcon
                        sx={{
                          color: props.displayType === "matrix" ? "#fff" : ""
                        }}
                      />
                    </IconButton>
                  ))}
                {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onSidebarOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton> */}
              </Hidden>
            )}
        </Box>
        <Box
          display={"flex"}
          alignItems="baseline"
          component="a"
          underline="none"
          href="/"
          // title="webbee"
          height={{ xs: 50, md: 50 }}
          width={45}
        >
          <img src={logo} alt={logo} width={"50px"} height={"50px"} />
        </Box>
      </Box>
      <Box display="flex" alignItems={"center"}>
        {props.displayType !== "matrix" && (
          <Box>
            <IconButton
              onClick={() => themeToggler()}
              aria-label="Dark mode toggler"
              color={themeMode === "light" ? "primary" : "secondary"}
            >
              {themeMode === "light" ? (
                <svg
                  width={24}
                  height={24}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
                </svg>
              ) : (
                <svg
                  width={24}
                  height={24}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              )}
            </IconButton>
          </Box>
        )}

        <Box sx={{ display: { md: "flex" } }} alignItems={"center"}>
          {props?.data?.properties?.headerTabBar?.documentationLink && (
            <Box marginX={2}>
              <Link
                sx={{ color: props.displayType === "matrix" ? "#fff" : "" }}
                underline="none"
                component="a"
                color="textPrimary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    props.data &&
                    props.data.properties.headerTabBar.documentationLink
                  );
                }}
              >
                {props.data
                  ? props.data.properties.headerTabBar.documentation
                  : "Documentation"}
              </Link>
            </Box>
          )}
          {showWalletType && (
            <Modal
              open={showWalletType}
              onClose={() => {
                setShowWalletType(false);
              }}
            >
              <div className={classes.modal3}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    flexDirection: "column"
                  }}
                >
                  Select wallet you want to connect
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={async () => {
                    onChangeWalletType("Metamask");
                    setShowWalletType(false);
                    await handleConnect(
                      "Metamask",
                      props.headerAccountAction,
                      props.headerNetworkIdAction,
                      props.setWalletType,
                      props.headerSetBalance,
                      props.messageAction
                    );
                  }}
                >
                  <img
                    src={metamask}
                    width="30px"
                    height="30px"
                    style={{ marginRight: "10px" }}
                  />
                  Metamask
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={async () => {
                    onChangeWalletType("Coinbase");
                    setShowWalletType(false);
                    await handleConnect(
                      "Coinbase",
                      props.headerAccountAction,
                      props.headerNetworkIdAction,
                      props.setWalletType,
                      props.headerSetBalance,
                      props.messageAction
                    );
                  }}
                >
                  <img
                    src={coinbase}
                    width="30px"
                    height="30px"
                    style={{ marginRight: "10px" }}
                  />
                  Coinbase
                </div>
              </div>
            </Modal>
          )}
          {props.accountModal && (
            <Modal
              open={props.accountModal}
              onClose={() => {
                props.showAccountModal(false);
              }}
            >
              <div className={classes.modal2}>
                <div style={{ height: "50px", paddingTop: "10px" }}>
                  Account
                </div>
                <div
                  style={{
                    height: "30px",
                    color: "#868686",
                    marginBottom: "10px"
                  }}
                >
                  Connected with {walletType}
                </div>
                <div>
                  {`${props.headerAccount[0].substring(
                    0,
                    6
                  )}...${props.headerAccount[0].substring(
                    props.headerAccount[0].length - 4,
                    props.headerAccount[0].length
                  )}`}
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "#868686",
                    marginTop: "10px",
                    marginBottom: "10px",
                    cursor: "pointer"
                  }}
                >
                  <div
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      copy(props.headerAccount[0]);
                      props.messageAction({
                        message: "Successfully copied to clipboard",
                        status: "success"
                      });
                    }}
                  >
                    <FiCopy style={{ marginRight: "10px" }} />
                    Copy Address
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        `${networkMap[props.headerNeworkId]}address/${props.headerAccount[0]
                        }`
                      )
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    View on Explorer
                  </div>
                </div>
                <div>Bridges</div>
                <div
                  style={{
                    display: "flex",
                    color: "#868686",
                    marginTop: "10px",
                    cursor: "pointer"
                  }}
                >
                  <div
                    style={{ marginRight: "20px", marginBottom: "20px" }}
                    onClick={() =>
                      window.open("https://wallet.polygon.technology/bridge")
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    Polygon Bridge
                  </div>
                  <div
                    onClick={() =>
                      window.open("https://bridge.umbria.network/")
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    Umbria Bridge
                  </div>
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.showAccountModal(false);
                    props.headerAccountAction(() => []);
                  }}
                  style={{ float: "right", marginRight: "30px" }}
                >
                  Logout
                </Button>
              </div>
            </Modal>
          )}

          <Box className={classes.mobBtn}>
            {!props.subDomain ||
              (props.subDomain === "metaontheblock" && (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    "& svg": {
                      // fill: "primary"
                    }
                  }}
                  onClick={() => {
                    setshowBtn(!showBtn);
                  }}
                >
                  {!showBtn ? (
                    <MdArrowDropDownCircle variant="contained" size="large" />
                  ) : (
                    <IoMdArrowDropupCircle variant="contained" size="large" />
                  )}
                </Box>

                // <Button
                //   variant="contained"
                //   color="primary"
                //   size="large"
                //   onClick={() => {
                //     setshowBtn(!showBtn);
                //   }}
                // >
                //   Site
                // </Button>
              ))}

            {showBtn ? (
              <Box
                sx={{
                  background: "rgba(0,0,0,0.7)",
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  display: "block",
                  maxWidth: "100%",
                  left: "0",
                  padding: "10px 20px"
                }}
              >
                <Box
                  className="AX"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gridGap: "10px",
                    maxWidth: "200px",
                    width: "100%",
                    marginLeft: "auto"
                  }}
                >

                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Connect Agent
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box marginX={2} className={classes.btnGroup}>
            <MUIModal
              open={isModalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.modalStyle} sx={modalStyle}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Stepper
                      activeStep={activeStep}
                      className={classes.stepper}
                    >
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                          labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                          );
                        }
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {activeStep === steps.length ? (
                      <Fragment>
                        <Typography
                          color={themeMode === "light" ? "black" : "white"}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Thanks for submitting! Our
                          team will review it for offensiveness, and we'll
                          notify you on Twitter within 24-48hrs if you pass. If
                          you fail, we'll DM you with feedback, but it may take
                          longer for us to respond if your site is extra spicy.
                          Please note that if your site is blatantly offensive,
                          we reserve the right to report it to the appropriate
                          authorities.
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button
                            sx={{
                              mr: 1
                            }}
                            color="secondary"
                            onClick={handleReset}
                            variant="contained"
                          >
                            Reset
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleModalClose}
                            color="secondary"
                          >
                            Close
                          </Button>
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Formik
                          initialValues={{
                            twitter: "",
                            idea: "",
                            description: "",
                            additionalHelp: ""
                          }}
                          isInitialValid={false}
                          validationSchema={validationSchemaCreatePage}
                          enableReinitialize
                          onSubmit={async (values, actions) => {
                            try {
                              setActiveStep(4);
                              await axios.post(
                                `${API}createLandingPageData`,
                                values
                              );
                              setActiveStep(5);
                            } catch (err) {
                              setActiveStep(3);
                              props.messageAction({
                                message:
                                  "Something went wrong while creating page! Try again later.",
                                status: "error"
                              });
                              console.log(err);
                            }
                          }}
                        >
                          {formik => (
                            <form>
                              <Box
                                sx={{
                                  mt: 3
                                }}
                              >
                                {0 <= activeStep <= 4 &&
                                  StepsJSX[activeStep](formik)}
                              </Box>
                              {activeStep !== 4 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2
                                  }}
                                >
                                  <Button
                                    disabled={activeStep === 0}
                                    color="secondary"
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    onClick={handleModalClose}
                                    sx={{ mr: 1 }}
                                    color="secondary"
                                  >
                                    Close
                                  </Button>
                                  <Box sx={{ flex: "1 1 auto" }} />
                                  {isStepOptional(activeStep) && (
                                    <Button
                                      color="secondary"
                                      onClick={() => handleSkip(formik)}
                                      sx={{ mr: 1 }}
                                    >
                                      Skip
                                    </Button>
                                  )}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleNext(formik)}
                                  >
                                    {activeStep === steps.length - 2
                                      ? "Let's Go"
                                      : "Next"}
                                  </Button>
                                </Box>
                              )}
                            </form>
                          )}
                        </Formik>
                      </Fragment>
                    )}
                  </Box>
                </div>
              </Box>
            </MUIModal>
            {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Connect Agent
                </Button> */}
          </Box>

          <Box>
            {allAirdropData &&
              allAirdropData.length > 0 &&
              props.headerAccount[0] && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  size="large"
                  onClick={() => {
                    props.setClaimNftModal(true);
                  }}
                >
                  {props.data &&
                    props.data.properties.headerTabBar.claimBtn.text
                    ? props.data.properties.headerTabBar.claimBtn.text
                    : "Claim"}
                </Button>
              )}
          </Box>
        </Box>
      </Box>
      {props.claimNftModal && (
        <Modal
          open={props.claimNftModal}
          onClose={() => {
            props.setClaimNftModal(false);
          }}
        >
          <div className={classes.modal}>
            <div>
              <FormControl
                className={classes.formControl}
                style={{
                  width: "300px",
                  margin: "40px",
                  marginTop: "30px",
                  float: "right",
                  "& svg": { color: "rgb(232 232 232 / 54%) !important" }
                }}
              >
                <h3 style={{ color: "white" }}>
                  {props.data && props.data.properties.headerTabBar.airdropTitle
                    ? props.data.properties.headerTabBar.airdropTitle
                    : "Select Airdrop"}
                </h3>

                <Select
                  value={props.airDropData && props.airDropData._id}
                  onChange={e => {
                    const airDropData = allAirdropData.find(
                      elem => elem._id === e.target.value
                    );
                    props.headerAirDropData(airDropData);
                  }}
                  className={classes.select}
                  sx={{
                    height: "50px",
                    border: "1px solid white",
                    "&:hover": {
                      borderLeft: "1px solid white",
                      borderRight: "1px solid white"
                    }
                  }}
                >
                  {allAirdropData.map((elem, index) => (
                    <MenuItem value={elem._id}>{elem.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Formik
              initialValues={{
                questions:
                  props.airDropData &&
                  props.airDropData.questions.map(question => ({
                    ...question,
                    answer: ""
                  }))
              }}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={async (values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 500);
                let allQuestionAnswered = true;
                values.questions.map(el => {
                  if (!el.answer) {
                    allQuestionAnswered = false;
                  }
                });
                let shouldClaim = true;
                if (!allQuestionAnswered) {
                  shouldClaim = window.confirm(
                    "Are you sure you want cliam without filling all answer!"
                  );
                }
                if (!shouldClaim) {
                  return;
                }
                const data = await axios.post(`${API}addToWhitelist`, {
                  airdropId: props.airDropData._id,
                  walletAddress: props.headerAccount[0],
                  landingPageId: props.data._id,
                  ...values
                });
                if (data.data && data.data.message) {
                  props.messageAction({
                    message: data.data.message,
                    status: "error"
                  });
                }
                props.setClaimNftModal(false);
                props.messageAction({
                  message: "Successfully submitted for whitelist",
                  status: "success"
                });
              }}
            >
              {({ values }) => (
                <div
                  style={{
                    display: "flex",
                    margin: "50px",
                    paddingTop: "50px"
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", width: "800px" }}>
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          width: "100px",
                          height: "100px"
                        }}
                      >
                        <img
                          src={props.airDropData.logoUrl}
                          alt=""
                          width="100px"
                          height="100px"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "30px"
                        }}
                      >
                        <h3 style={{ margin: "0px", padding: "0px" }}>
                          {props.airDropData.title}
                        </h3>
                        <div
                          style={{ width: "300px" }}
                          dangerouslySetInnerHTML={{
                            __html: draftToHtml(
                              JSON.parse(props.airDropData.description)
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <img
                          src={props.airDropData.bannerUrl}
                          alt=""
                          width="400px"
                          height="300px"
                          style={{
                            borderRadius: "10px",
                            marginTop: "30px"
                          }}
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                          marginLeft: "20px",
                          height: "300px",
                          marginTop: "30px",
                          paddingLeft: "30px"
                        }}
                      >
                        <Form>
                          <div
                            style={{
                              marginBottom: "30px",
                              height: "200px",
                              overflow: "auto",
                              width: "400px"
                            }}
                          >
                            {props.airDropData.questions.map(
                              (question, index) => (
                                <Field
                                  component={
                                    question.type == "address"
                                      ? "textarea"
                                      : TextField
                                  }
                                  name={`questions.${index}.answer`}
                                  id={`questions.${index}.answer`}
                                  className={classes.field}
                                  type={question.type}
                                  placeholder={question.title}
                                  variant="standard"
                                  size="small"
                                  validate={question.type == "tel" && validate}
                                />
                              )
                            )}
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submitBtn}
                          >
                            {props.airDropData.submitButton
                              ? props.airDropData.submitButton.text
                              : "Submit"}
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    subDomain: state?.landingPageData?.landingsuccessData?.subDomain,
    displayType:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.displayType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setWalletType: values => {
      dispatch(headerWalletType(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);

// export default Topbar;
