import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  editChatNameAction,
  isLoadingChatAction,
  selectedEditChatIdAction,
  setAllChatListAction
} from "./actions";
import {
  SendUserQuestionApi,
  addChatApi,
  checkAndGetAnswer,
  deleteChatApi,
  editChatApi,
  getAllChatApi
} from "./api";
import {
  ADD_CHAT_IN_LIST,
  DELETE_CHAT_FROM_LIST,
  EDIT_CHAT_NAME_IN_LIST,
  GET_ALL_CHAT_LIST,
  SEND_USER_QUESTION_IN_CHAT
} from "./constants";

function* getAllChatSaga(action) {
  try {
    const result = yield call(getAllChatApi, action.payload);
    if (result.status == 200) {
      yield put(setAllChatListAction(result.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}
function* addChatInListSaga(action) {
  try {
    const state = yield select();

    const result = yield call(addChatApi, action.payload);
    if (result.status == 200) {
      const array = [...state.chatReducer.AllChatList, result.data.data];
      yield put(setAllChatListAction(array));
      yield put(selectedEditChatIdAction(""));

      action.payload.history.push(`/ChatGroup?id=${result.data.data._id}`);
    }
  } catch (err) {
    console.log(err);
  }
}
function* deleteChatFromListSaga(action) {
  try {
    const state = yield select();
    const result = yield call(deleteChatApi, action.payload);
    if (result.status == 200) {
      const array = state.chatReducer.AllChatList.filter(
        e => e._id != action.payload.id
      );
      yield put(setAllChatListAction(array));
      action.payload.history.push("/ChatGroup");
    }
    yield put(selectedEditChatIdAction(""));
  } catch (err) {
    console.log(err);
  }
}
function* editChatFromListSaga(action) {
  try {
    const state = yield select();

    const result = yield call(editChatApi, action.payload);
    if (result.status == 200) {
      const array = state.chatReducer.AllChatList.map(u =>
        u._id !== result.data.data._id ? u : result.data.data
      );

      yield put(setAllChatListAction(array));
    }
    yield put(selectedEditChatIdAction(""));
    yield put(editChatNameAction(""));
  } catch (err) {
    console.log(err);
  }
}
function* sendUserQuestionSaga(action) {
  try {
    const formData = new FormData();
    if (action.payload.que) {
      formData.append("que", action.payload.que);
    }
    if (action.payload.document) {
      formData.append("document", action.payload.document);
    }
    const data = {
      id: action.payload.id,
      formData: formData
    };
    yield put(isLoadingChatAction(true));
    const state = yield select();

    let result = yield call(SendUserQuestionApi, data);

    if (result.status == 200) {
      while (!result.data.completed) {
        result = yield call(checkAndGetAnswer, { id: action.payload.id });
        yield delay(3000);
      }
      const array = state.chatReducer.AllChatList.map(u => {
        if (u._id == action.payload.id) {
          return {
            ...u,
            chatData: [...result.data.data]
          };
        } else {
          return u;
        }
      });

      yield put(setAllChatListAction(array));
    }
    yield put(isLoadingChatAction(false));
  } catch (err) {
    yield put(isLoadingChatAction(false));

    console.log(err);
  }
}

function* chatSaga() {
  yield takeLatest(GET_ALL_CHAT_LIST, getAllChatSaga);
  yield takeLatest(ADD_CHAT_IN_LIST, addChatInListSaga);
  yield takeLatest(DELETE_CHAT_FROM_LIST, deleteChatFromListSaga);
  yield takeLatest(EDIT_CHAT_NAME_IN_LIST, editChatFromListSaga);
  yield takeLatest(SEND_USER_QUESTION_IN_CHAT, sendUserQuestionSaga);
}

export default chatSaga;
