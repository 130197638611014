import {
  SET_LOADER_REQUEST_POLICY,
  SET_REQUESTED_POLICY_DATA
} from "./constant";

const initialState = {
  requestedPolicies: [],
  loader: true
};

const ReuestedPendingPoliciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUESTED_POLICY_DATA:
      return { ...state, requestedPolicies: action.payload };

    case SET_LOADER_REQUEST_POLICY:
      return { ...state, loader: action.payload };

    default:
      return state;
  }
};

export default ReuestedPendingPoliciesReducer;
