import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../../images/rocket-joypixels.gif";
import ToastMsg from "../SnackBar";
import AuthCardWrapper from "./CardWrapper/AuthCardWrapper";
import AuthWrapper1 from "./CardWrapper/AuthWrapper1";
import AuthLogin from "./LoginForm";
import { loginAccount, messageDataAction } from "./actions";

const Login = props => {
  const theme = useTheme();
  const history = useHistory();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const closeSnackBar = () => {
    props.messageDataAction({});
  };
  return (
    <AuthWrapper1>
      {props.waitingLogin && (
        <Backdrop
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={props.waitingLogin}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <ToastMsg
        toastMessage={props.message}
        open={true}
        close={closeSnackBar}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <img src={logo} width="50px" height={"50px"} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Hi, Welcome Back
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                          >
                            {/* Sign in with Google */}
                            {/* Enter your credentials to continue sucees */}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin
                      loginAccount={props.loginAccount}
                      messageDataAction={props.messageDataAction}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      xs={12}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          history.push("/signup");
                        }}
                      >
                        Don&apos;t have an account?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    messageDataAction: values => {
      dispatch(messageDataAction(values));
    },
    loginAccount: values => {
      dispatch(loginAccount(values));
    }
  };
};

const mapStateToProps = state => {
  return {
    message: state.userData.message,
    waitingLogin: state.userData.waitingLogin
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
