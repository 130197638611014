import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AnimateButton from "../HeaderLayout/ui-component/extended/AnimateButton";

const Authforget = props => {
  const theme = useTheme();

  const { signupProp, ...others } = props;

  const history = useHistory();
  let referral_id;

  if (window.location.search) {
    let query = queryString.parse(window.location.search);
    referral_id = query.referral_id;
  }

  const handleSubmit = values => {
    let res = props.forgetverifyEmail({
      username: values.email,
      history: history
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required")
        })}
        onSubmit={async (values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 500);
          await handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Email Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Authforget;
