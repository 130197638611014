import { GET_POLICY_BYID, SET_POLICY_BYID } from "./constant";

export const getViewPolicyData = value => {
  return {
    type: GET_POLICY_BYID,
    payload: value
  };
};

export const setViewPolicyData = value => {
  return {
    type: SET_POLICY_BYID,
    payload: value
  };
};
