import { SET_POLICY_BYID } from "./constant";

const defualtState = {
  viewPolicyData: ""
};

export const viewPolicyReducer = (state = defualtState, action) => {
  switch (action.type) {
    case SET_POLICY_BYID:

      return { ...state, viewPolicyData: action.payload };
    default:
      return state;
  }
};
