import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Auth } from "aws-amplify";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Route, useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
// import { useAsyncEffect } from "use-async-effect";
import {
  getprofiledata,
  setUserWalletAddress,
  validateUsersWallet,
  waitingLoader
} from "./components/Account/actions";
import { isUserAssignPolicy } from "./components/CreatePolicies/Redux/action";
import HeaderLayout from "./components/HeaderLayout/layout/MainLayout/index";
import {
  postLoginSuccess,
  postLogoutSuccess,
  userDataAction
} from "./components/Login/actions";
import { getAxiosInstance, setToken } from "./utils/axios";
import { API } from "./utils/config";

const PolicyAdminRoute = props => {
  const history = useHistory();
  // const isUserAssignPolicy = useSelector((state) => state.CreatePolicesReducer.isUserAssignPolicy)

  // check user is authenticate or not from aws-amplify
  const authenticateUser = async () => {
    try {
      let getCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      return getCurrentAuthenticatedUser;
    } catch (err) {
      return undefined;
    }
  };
  const handleLogout = async () => {
    try {
      Auth.signOut().then(() => {
        props.postLogoutSuccess({
          userId: "",
          isAdmin: false
        });
        history.push("/");
      });
    } catch (err) {
      console.error(err);
    }
  };
  //useAsyncEffect
  useEffect(async () => {
    async function authenticatePolicyUser() {
      props.waitingLoader(true);
      await authenticateUser()
        .then(async getCurrentAuthenticatedUser => {
          if (getCurrentAuthenticatedUser) {
            let isAdmin = false;
            let isUser = false;

            let isUserProfile = false;
            let isInvitedUser = false;
            let isPolicyUser = false;
            setToken(
              getCurrentAuthenticatedUser.signInUserSession.idToken.jwtToken
            );
            if (!props.user.userId) {
              let data = await getAxiosInstance().post(`${API}verifyUser`, {
                userId: getCurrentAuthenticatedUser.username,
                email: getCurrentAuthenticatedUser.attributes.email,
                given_name:
                  getCurrentAuthenticatedUser.attributes.given_name || "",
                family_name:
                  getCurrentAuthenticatedUser.attributes.family_name || "",
                hostname: window.location.hostname
              });

              if (data.status === 200 && data.data.message === "OK") {
                props.waitingLoader(false);

                let res = await getAxiosInstance().get(`${API}is_user_invited`);

                if (res.data) {
                  isInvitedUser = true;
                }

                if (data?.data?.walletAddress) {
                  props.setUserWalletAddress(data.data.walletAddress);
                  props.validateUsersWallet({
                    status: true,
                    MessageStatement: "successfully connected"
                  });
                } else {
                  props.validateUsersWallet({
                    status: false,
                    MessageStatement:
                      "You have not Agent ID Please connect to metamask"
                  });
                }

                let policyassigndata = res.data;
                props.isUserAssignPolicy({ data: policyassigndata });

                if (data.data.data.role === "admin") {
                  isAdmin = true;
                  isUserProfile = false;
                } else if (data.data.data.role === "profileuser") {
                  isUserProfile = true;
                  isUser = true;
                } else if (data.data.data.role === "user") {
                  isUser = true;
                  // setShowContent(false);
                } else if (data.data.data.role === "policyuser") {
                  isPolicyUser = true;
                }
              }

              props.getprofiledata(data.data.data);
              props.postLoginSuccess({
                userId: getCurrentAuthenticatedUser.username,
                isAdmin
              });
            }
            props.waitingLoader(false);
            if (props.user.userId) {
              isAdmin = props.user.isAdmin;
            }
            props.userDataAction(getCurrentAuthenticatedUser.attributes);
            if (isUser) {
              // if (!isInvitedUser) {
              // }
              history.push("/");
            }
            if (isPolicyUser) {
              history.push("/votecenter");
            }
          } else {
            props.waitingLoader(false);
            history.push("/");
          }
        })
        .catch(err => {
          props.waitingLoader(false);
          console.log(err);
          history.push("/");
        });
    }

    authenticatePolicyUser();
  }, []);

  const { ...rest } = props;
  return (
    <>
      {props.loader ? (
        <div>
          {" "}
          <Backdrop
            sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      ) : (
        <div>
          <HeaderLayout
            componentToPassDown={
              <Route
                {...rest}
                render={props => (
                  <>
                    <React.Component {...props} />
                  </>
                )}
              />
            }
          />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    postLoginSuccess: values => {
      dispatch(postLoginSuccess(values));
    },
    userDataAction: values => {
      dispatch(userDataAction(values));
    },
    waitingLoader: values => {
      dispatch(waitingLoader(values));
    },
    getprofiledata: value => {
      dispatch(getprofiledata(value));
    },
    isUserAssignPolicy: value => {
      dispatch(isUserAssignPolicy(value));
    },
    postLogoutSuccess: value => {
      dispatch(postLogoutSuccess(value));
    },
    setUserWalletAddress: value => {
      dispatch(setUserWalletAddress(value));
    },
    validateUsersWallet: value => {
      dispatch(validateUsersWallet(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.userData.user || { userId: "", isAdmin: false },
    loader: state.accountData.loader,
    chainId: state.accountData.walletInfos
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(withRouter(PolicyAdminRoute));
