import { Auth } from "aws-amplify";
import awsmobile from "../../aws/aws-exports";
import { getAxiosInstance } from "../../utils/axios";
import { API } from "../../utils/config";
import emptyS3Directory from "../../utils/utilityFunctions";

export const getLandingPagesByUser = async ({ userId }) => {
  try {
    const res = await getAxiosInstance().get(
      `${API}getLandingPagesByUser/${userId}`
    );

    const imagesArr = [];
    if (res.data.data.length) {
      res.data.data.map(element => {
        if (element.properties.header.file.length > 4) {
          const image = element.properties.header.file.filter(
            (element, index) => index < 4
          );
          imagesArr.push(image);
        } else {
          imagesArr.push(element.properties.header.file);
        }
      });
    }
    return { res, imagesArr };
  } catch (error) {
    console.log(error);
  }
};

export const deleteLandingPagesByUser = async ({ pageData, userId }) => {
  let message;
  try {
    let res = await getAxiosInstance().delete(
      `${API}removeLandingPage/${pageData._id}`
    );
    if (res.status === 202) {
      emptyS3Directory(
        awsmobile.bucket,
        `Landing_Pages/${userId}/${pageData.subDomain}/`
      );
      message = 202;
    }
    return message;
  } catch (error) {
    message = null;
    console.log(error);
  }
};

export const landingPageDataAPi = async ({ subDomain, userSubDomain }) => {
  try {
    let res = await getAxiosInstance().get(
      `${API}getLandingPage?subDomain=${subDomain}&userSubDomain=${userSubDomain}`
    );
    return res;
  } catch (err) {
    return { err };
  }
};

export const registerUserApi = async data => {
  try {
    let res = await getAxiosInstance().post(`${API}registerUser`, data);
    return res;
  } catch (err) {
    return { err };
  }
};

export const getCustomImageListApi = async data => {
  try {
    let getCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();

    let res = await getAxiosInstance().get(
      `${API}listOfCustomImages?userId=${getCurrentAuthenticatedUser.username}`,
      data
    );
    return res;
  } catch (err) {
    return { err };
  }
};

export const uploadCustomImageApi = async data => {
  try {
    let res = await getAxiosInstance().post(
      `${API}uploadCustomImage`,
      data.formData
    );
    return res;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
export const deleteCustomImageApi = async data => {
  try {
    let res = await getAxiosInstance().delete(
      `${API}deleteCustomerImage?_id=${data}`
    );
    return res;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
export const storeMatrixDataApi = async data => {
  try {
    let res = await getAxiosInstance().post(`${API}storeMatrix`, data);
    return res;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
export const getMatrixTableDataApi = async data => {
  try {
    let res = await getAxiosInstance().get(
      `${API}getLandingpageMatrix?landingPageId=${data.id}`
    );
    return res;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
