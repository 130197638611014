import { makeStyles } from "@material-ui/styles";
import React from "react";
import CustomizedInputBase from "./component/ChatInput";
import ChatMessageList from "./component/ChatMessageList";

function RightSideContainer(props) {
  const useStyles = makeStyles(() => ({}));

  const classes = useStyles();
  const id = window.location.search.split("?id=")[1];

  return (
    <div className="ChatRightContainer">
      {id ? (
        <>
          <div className="chatMessageContainer">
            <ChatMessageList
              AllChatList={props.AllChatList}
              selectedMessageId={props.selectedMessageId}
              messageDataAction={props.messageDataAction}
              userData={props.userData}
            />
          </div>
          <div className="chatInputField">
            <CustomizedInputBase
              AllChatList={props.AllChatList}
              sendUserQuestionAction={props.sendUserQuestionAction}
              selectedMessageId={props.selectedMessageId}
              waitingChat={props.waitingChat}
            />
          </div>
        </>
      ) : (
        <div className="WithoutIdContainer">
          <div className="WelcomeMessage">Welcome to Chatbot </div>
          <div className="Welcomedesciption">
            Chatbot is an open source code of OpenAI's ChatGPT.
          </div>
        </div>
      )}
    </div>
  );
}

export default RightSideContainer;
