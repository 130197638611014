import {
  CHANGE_PASSWORD,
  CHECKING_CONNECTION_STATUS,
  EDIT_PROFILE_DATA,
  GET_PROFILE_DATA,
  STORE_WALLET_RELATED_INFOS,
  USER_WALLET_ADDRESS,
  VALIDATE_WALLET_ADDRESS,
  VALIDATION_OF_USERS_WALLET,
  VERIFY_EDIT_DATA,
  WAITING_LOADER
} from "./constants";

export const changePasswordAction = value => {
  return {
    type: CHANGE_PASSWORD,
    payload: value
  };
};
export const editprofile = value => {
  return {
    type: EDIT_PROFILE_DATA,
    payload: value
  };
};
export const getprofiledata = value => {
  return {
    type: GET_PROFILE_DATA,
    payload: value
  };
};
export const verifyeditdata = value => {
  return {
    type: VERIFY_EDIT_DATA,
    payload: value
  };
};

export const waitingLoader = value => {
  return {
    type: WAITING_LOADER,
    payload: value
  };
};

export const setUserWalletAddress = value => {
  return {
    type: USER_WALLET_ADDRESS,
    payload: value
  };
};
export const setWalletRelatedInfo = value => {
  return {
    type: STORE_WALLET_RELATED_INFOS,
    payload: value
  };
};
export const validateUsersWallet = value => {
  return {
    type: VALIDATION_OF_USERS_WALLET,
    payload: value
  };
};

export const setConnectionStatus = value => {
  return {
    type: CHECKING_CONNECTION_STATUS,
    payload: value
  };
};

export const validateWalletInformation = value => {
  return {
    type: VALIDATE_WALLET_ADDRESS,
    payload: value
  }
}