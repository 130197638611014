import {
  ACTIVE_ITEM,
  DRAWER_OPEN,
  POST_DATA_HEADER,
  SET_ACCOUNTS,
  SET_MESSAGE,
  SIDE_BAR_DRAWER
} from "./constants";

export const activeItem = value => {
  return {
    type: ACTIVE_ITEM,
    payload: value
  };
};
export const drawerOpenAction = value => {
  return {
    type: DRAWER_OPEN,
    payload: value
  };
};
export const sideBardDrawer = value => {
  return {
    type: SIDE_BAR_DRAWER,
    payload: value
  };
};
export const postAction = value => {
  return {
    type: POST_DATA_HEADER,
    payload: value
  };
};

export const setMessage = value => {
  return {
    type: SET_MESSAGE,
    payload: value
  };
};

export const setAccounts = value => {
  return {
    type: SET_ACCOUNTS,
    payload: value
  };
};
