export const FETCH_LANDING_PAGE = "FETCH_LANDING_PAGE";
export const FETCH_LANDING_PAGE_SUCCESS = "FETCH_LANDING_PAGE_SUCCESS";
export const FETCH_LANDING_PAGE_BY_PREVIEW = "FETCH_LANDING_PAGE_BY_PREVIEW";
export const DELETE_LANDING_PAGE = "DELETE_LANDING_PAGE";
export const DELETE_LANDING_PAGE_SUCCESS = "DELETE_LANDING_PAGE_SUCCESS";
export const LOADING_DATA = "LOADING_DATA";
export const MESSAGE_DATA = "MESSAGE_DATA";

//--AboutUs--//

export const ABOUT_US_MODAL_SHOW = "ABOUT_US_MODAL_SHOW";
export const ABOUT_US_DATA = "ABOUT_US_DATA";

//--Header--//

export const CLAIM_NFT_MODAL = "CLAIM_NFT_MODAL";
export const SHOW_ACCOUNT_MODAL = "SHOW_ACCOUNT_MODAL";
export const HEADER_AIRDROP_DATA = "HEADER_AIRDROP_DATA";
export const HEADER_SET_BALANCE = "HEADER_SET_BALANCE";
export const HEADER_ACCOUNT = "HEADER_ACCOUNT";
export const HEADER_NETWORKID = "HEADER_NETWORKID";
export const HEADER_WALLET_TYPE = "HEADER_WALLET_TYPE";
export const HEADER_SHOW_SIDEBAR = "HEADER_SHOW_SIDEBAR";
export const HEADER_COLOR = "HEADER_COLOR";
export const THEME_BACKGROUND = "THEME_BACKGROUND";

//--LANDINGPAGE_HOME--//

export const LANDING_HOME_PAGE_DATA = "LANDING_HOME_PAGE_DATA";
export const SET_SUBDOMAIN_FOR_REDIRECT = "SET_SUBDOMAIN_FOR_REDIRECT";

//--index--//

export const LANDINGPAGE_DATA = "LANDINGPAGE_DATA";
export const LANDINGPAGE_DATA_SUCCESS = "LANDINGPAGE_DATA_SUCCESS";

//--mint--//

export const NFT_FACTORY_CONTRACT_MINT = "NFT_FACTORY_CONTRACT_MINT";

//--roadmap--//

export const REGISTER_USER = "REGISTER_USER";

export const SET_EMAIL_ID = "SET_EMAIL_ID";

//--Nftblckchaintype--//

// export const  UPDATE_NFT_BLOCKCHAINTYPE = "UPDATE_NFT_BLOCKCHAINTYPE";

export const GET_CUSTOM_IMAGE_LIST = "GET_CUSTOM_IMAGE_LIST";
export const SET_CUSTOM_IMAGE_LIST = "SET_CUSTOM_IMAGE_LIST";
export const UPLOAD_CUSTOM_IMAGE = "UPLOAD_CUSTOM_IMAGE";
export const DELETE_CUSTOM_IMAGE = "DELETE_CUSTOM_IMAGE";
export const CUSTOM_LANDING_PAGE_LOADER = "CUSTOM_LANDING_PAGE_LOADER";

export const STORE_MATRIX_DATA = "STORE_MATRIX_DATA";

export const GET_MATRIX_TABLE_DATA = "GET_MATRIX_TABLE_DATA";
export const SET_MATRIX_TABLE_DATA = "SET_MATRIX_TABLE_DATA";
