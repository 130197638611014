import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getMyApprovedPoliciesapi = async data => {
  try {
    const result = await getAxiosInstance().get(`${API}approvedAssignedPolicy`);
    return result;
  } catch (e) {
    console.log(e);
  }
};
