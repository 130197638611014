import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getReviewPolicyapi = async data => {
  try {
    let result = await getAxiosInstance().get(`${API}getmailpolicy/${data}`);
    return { data: result };
  } catch (e) {
    return { error: e }
  }
};

export const sendReviewPolicyResponseapi = async data => {
  try {
    const result = await getAxiosInstance().post(`${API}policyuseraction`, data);
    return result;
  } catch (e) {
    console.log(e);
  }
};

