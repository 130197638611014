import {
  SET_LOADER_METRICS,
  SET_METRICS_DATA,
  SET_TOTAL_ACTIVITY_COUNT,
  SET_TOTAL_REGISTER_USER,
} from "./constant";

const defaultState = {
  chartData: [],
  loader: true,
  activityCount: [],
  registerUser: [],
};

export const metricsDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_METRICS_DATA:
      return { ...state, chartData: action.payload };
    case SET_LOADER_METRICS:
      return { ...state, loader: action.payload };
    case SET_TOTAL_ACTIVITY_COUNT:
      return { ...state, activityCount: action.payload };
    case SET_TOTAL_REGISTER_USER:
      return { ...state, registerUser: action.payload };
    default:
      return state;
  }
};
