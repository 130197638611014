import { Fab } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import MessageIcon from "@mui/icons-material/Message";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import copy from "copy-to-clipboard";
import React, { useEffect, useRef, useState } from "react";
import "../../index.css";

export default function ChatMessageList(props) {
  const [chat, setChat] = useState("");
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && chat) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [chat]);
  useEffect(() => {
    if (props.AllChatList && props.selectedMessageId) {
      var ChatArray = props.AllChatList.find(
        e => e._id == props.selectedMessageId
      );
      setChat(ChatArray);
    }
  }, [props.selectedMessageId, props.AllChatList]);

  return (
    <div className="MessageContainer">
      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        className="downBtn"
        onClick={() => {
          if (scrollRef.current && chat) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
          }
        }}
      >
        <KeyboardDoubleArrowDownIcon />
      </Fab>
      {chat &&
        chat.chatData.length > 0 &&
        chat.chatData.map(e => (
          <div
            style={{
              marginLeft: e.role == "user" ? "40%" : "0%",
              marginRight: e.role == "user" ? "0%" : "40%"
            }}
          >
            <ListItem
              className="MessageItem"
              style={{
                backgroundColor: e.role == "user" ? "#fff" : "#2D303A"
              }}
            >
              {e.role == "assistant" && (
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
              )}
              {e.content && e.content.substr(-4) == ".pdf" && (
                <ListItemIcon>
                  <PictureAsPdfIcon style={{ color: "#e03732" }} />
                </ListItemIcon>
              )}
              {e.content && e.content.substr(-4) == ".csv" && (
                <ListItemIcon>
                  <InsertDriveFileIcon style={{ color: "#43aa55" }} />
                </ListItemIcon>
              )}
              {e.content && e.content.substr(-4) == ".txt" && (
                <ListItemIcon>
                  <TextSnippetIcon style={{ color: "#43aa55" }} />
                </ListItemIcon>
              )}
              {typeof e.content == "string" && (
                <ListItemText
                  id="Message"
                  primaryTypographyProps={{
                    style: {
                      color: e.role == "user" && "#000",
                      textAlign: "justify",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      paddingRight: "10px",
                      paddingTop: "4px",
                      lineHeight: "25px"
                    }
                  }}
                  primary={e.content}
                />
              )}
            </ListItem>
            {e.role == "assistant" && (
              <div className="buttonWrapper">
                <Button
                  variant="contained"
                  className="chatBtn"
                  onClick={() => {
                    window.open(
                      `mailto:${props.userData.email}?subject=Poly Admin&body=${e.content}`
                    );
                  }}
                  startIcon={<EmailIcon />}
                >
                  Email
                </Button>
                <Button
                  variant="contained"
                  className="chatBtn"
                  onClick={() => {
                    copy(`${e.content}`);
                    props.messageDataAction({
                      message: "Successfully copied to clipboard",
                      status: "success"
                    });
                  }}
                  startIcon={<ContentCopyIcon />}
                >
                  Copy
                </Button>
              </div>
            )}
          </div>
        ))}

      <li ref={scrollRef} style={{ visibility: "hidden" }} />
    </div>
  );
}
