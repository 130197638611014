import { SET_REQUESTED_DATA, SET_REVIEW_USER_LOADER } from "./constant";

const defaultState = {
  requestUserData: {},
  loaderReviewUser: true
};
const ReviewUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_REQUESTED_DATA:
      return { ...state, requestUserData: action.payload };

    case SET_REVIEW_USER_LOADER:
      return { ...state, loaderReviewUser: action.payload };

    default:
      return state;
  }
};
export default ReviewUserReducer;
