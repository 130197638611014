import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Hidden,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from '@mui/material/Link';
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import {
  AiFillFileZip,
  AiFillLinkedin,
  AiFillMediumSquare,
  AiFillTwitterCircle,
  AiFillWallet
} from "react-icons/ai";
import { BiLinkExternal, BiMoneyWithdraw, } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import {
  FaDiscord,
  FaFacebook,
  FaTelegramPlane,
  FaTwitch
} from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { GrInstagram } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import metamask from "../../../../../../../../images/MetaMask_Fox.svg.png";
import coinbase from "../../../../../../../../images/coinbase.png";
import useConfig from "../../../../../../../HeaderLayout/hooks/useConfig";
const SidebarNav = props => {
  const history = useHistory();
  const { onClose } = props;
  const { onChangeWalletType } = useConfig();
  const [showWalletType, setShowWalletType] = useState(false);
  const networkMap = {
    1: "https://etherscan.io/",
    4: "https://rinkeby.etherscan.io/",
    137: "http://polygonscan.com/",
    80001: "https://mumbai.polygonscan.com/"
  };
  const handleConnect = async wallet => {
    try {
      if (window.ethereum) {
        try {
          const networkId = await window.ethereum.request({
            method: "net_version"
          });

          props.headerNetworkIdAction(networkId);
          const chain =
            networkId === "1" || networkId === "4" ? "ETH" : "MATIC";
        } catch (error) {
          props.messageAction({
            message: `please enable metamask`,
            status: "error"
          });

          console.log(error);
        }
      } else {
        alert("You have to install MetaMask !");
      }
    } catch (error) {
      if (error.code == 4001) {
        props.messageAction({
          message: `You have rejected transaction.`,
          status: "error"
        });
      } else {
        props.messageAction({
          message: `something went wrong please try refreshing page.`,
          status: "error"
        });
      }
      console.log(error);
    }
  };

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", accounts => {
      props.headerAccountAction(accounts);
    });
  }

  const data2 = props.data && props.data.properties.headerTabBar;
  const data = props.data && props.data.properties.headerTabBar.headerIcon;

  const useStyles = makeStyles(() => ({
    modal2: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "320px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px"
    },
    btn: {
      backgroundColor: props.themeMode,
      color: props.themeMode === "light" ? "#222b45" : "#ffffff",
      width: "40%",
      margin: "0px",
      padding: "0px",
      border: "none",
      background: "none",
      textAlign: "left",

      cursor: "pointer",
      fontSize: "16px"
    },
    btn2: {},
    icon: {
      fontSize: "20px",

      color: props.themeMode === "light" ? "#222b45" : "#ffffff"
    },
    drawerWrap: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: "30px"
    },
    modal3: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "180px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px"
    },
    mLeft: {
      marginLeft: "1.7rem"
    },
    modelTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      flexDirection: "column"
    },
    metaButton: {
      marginTop: "20px",
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    coinbaseButton: {
      marginTop: "20px",
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    linkButton: {
      marginTop: "22rem",
      textAlign: "center",
      display: "flex",
      justifyContent: "center"
    },
    MATIC: {
      display: "none",
      "@media screen and (max-width: 600px)": {
        display: "block"
      }
    },



    linkText: {
      color: "white",
      textDecorationColor: "white",
      webkitTextDecorationColor: "white",
    }
  }));

  const classes = useStyles();
  return (
    <Box>
      {showWalletType && (
        <Modal
          open={showWalletType}
          onClose={() => {
            setShowWalletType(false);
          }}
        >
          <div className={classes.modal3}>
            <div className={classes.modelTitle}>
              Select wallet you want to connect
            </div>
            <div
              className={classes.metaButton}
              onClick={async () => {
                onChangeWalletType("Metamask");
                setShowWalletType(false);
                await handleConnect("Metamask");
              }}
            >
              <img
                src={metamask}
                width="30px"
                height="30px"
                style={{ marginRight: "10px" }}
              />
              Metamask
            </div>
            <div
              className={classes.coinbaseButton}
              onClick={async () => {
                onChangeWalletType("Coinbase");
                setShowWalletType(false);
                await handleConnect("Coinbase");
              }}
            >
              <img
                src={coinbase}
                width="30px"
                height="30px"
                style={{ marginRight: "10px" }}
              />
              Coinbase
            </div>
          </div>
        </Modal>
      )}
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        // alignItems={"center"}
        onClick={() => onClose()}
      >
        <IconButton>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box paddingX={2} paddingBottom={2}>
        <Box>
          <div>
            {
              props?.data?.properties?.headerTabBar?.documentation?.documentationLink && (
                <div
                  className={classes.drawerWrap}
                  onClick={() => window.open(data2.documentationLink, "_blank")}
                >
                  <Hidden only="md">
                    <AiFillFileZip className={classes.icon} />
                    <Button className={classes.btn} color="inherit">
                      {props.data.properties.headerTabBar &&
                        props.data.properties.headerTabBar.documentation}
                    </Button>
                  </Hidden>
                </div>
              )}

            {props.data.properties.headerTabBar.wallet &&
              props.data.properties.headerTabBar.wallet.text && (
                <div
                  onClick={async () => {
                    props.headerAccount[0]
                      ? props.showAccountModal(true)
                      : setShowWalletType(true);
                  }}
                >
                  {props.accountModal && (
                    <Modal
                      open={props.accountModal}
                      onClose={() => {
                        props.showAccountModal(false);
                      }}
                    >
                      <div className={classes.modal2}>
                        <div style={{ height: "50px", paddingTop: "10px" }}>
                          Account
                        </div>
                        <div
                          style={{
                            height: "30px",
                            color: "#868686",
                            marginBottom: "10px"
                          }}
                        >
                          Connected with Metamask
                        </div>
                        <div>
                          {`${props.headerAccount[0].substring(
                            0,
                            6
                          )}...${props.headerAccount[0].substring(
                            props.headerAccount[0].length - 4,
                            props.headerAccount[0].length
                          )}`}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            color: "#868686",
                            marginTop: "10px",
                            marginBottom: "10px",
                            cursor: "pointer"
                          }}
                        >
                          <div
                            style={{ marginRight: "20px" }}
                            onClick={() => {
                              copy(props.headerAccount[0]);
                              props.messageAction({
                                message: "Successfully copied to clipboard",
                                status: "success"
                              });
                            }}
                          >
                            <FiCopy style={{ marginRight: "10px" }} />
                            Copy Address
                          </div>
                          <div
                            onClick={() =>
                              window.open(
                                `${networkMap[props.headerNeworkId]}address/${props.headerAccount[0]
                                }`
                              )
                            }
                          >
                            <BiLinkExternal style={{ marginRight: "10px" }} />
                            View on Explorer
                          </div>
                        </div>
                        <div>Bridges</div>
                        <div
                          style={{
                            display: "flex",
                            color: "#868686",
                            marginTop: "10px",
                            cursor: "pointer"
                          }}
                        >
                          <div
                            style={{
                              marginRight: "20px",
                              marginBottom: "20px"
                            }}
                            onClick={() =>
                              window.open(
                                "https://wallet.polygon.technology/bridge"
                              )
                            }
                          >
                            <BiLinkExternal style={{ marginRight: "10px" }} />
                            Polygon Bridge
                          </div>
                          <div
                            onClick={() =>
                              window.open("https://bridge.umbria.network/")
                            }
                          >
                            <BiLinkExternal style={{ marginRight: "10px" }} />
                            Umbria Bridge
                          </div>
                        </div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            props.showAccountModal(false);
                            props.headerAccountAction(() => []);
                          }}
                          style={{ float: "right", marginRight: "30px" }}
                        >
                          Logout
                        </Button>
                      </div>
                    </Modal>
                  )}

                  {/*  Connect Wallet  */}
                  <Hidden only="md">
                    <ListItem key={""} disablePadding>
                      <ListItemButton
                        color="inherit"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gridGap: "30px",
                          height: "50px"
                        }}
                      >
                        <AiFillWallet className={classes.icon} />
                        Connect Agent
                      </ListItemButton>
                    </ListItem>
                  </Hidden>
                </div>
              )}

            {props.data && data.facebookUrl && (
              <ListItem key={"Facebook"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.facebookUrl, "_blank")}
                >
                  <FaFacebook className={classes.icon} />

                  <ListItemText
                    className={classes.mLeft}
                    primary={"Facebook"}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {props.accountModal && (
              <Modal
                open={props.accountModal}
                onClose={() => {
                  props.showAccountModal(false);
                }}
              >
                <div className={classes.modal2}>
                  <div style={{ height: "50px", paddingTop: "10px" }}>
                    Account
                  </div>
                  <div
                    style={{
                      height: "30px",
                      color: "#868686",
                      marginBottom: "10px"
                    }}
                  >
                    Connected with Metamask
                  </div>
                  <div>
                    {`${props.headerAccount[0].substring(
                      0,
                      6
                    )}...${props.headerAccount[0].substring(
                      props.headerAccount[0].length - 4,
                      props.headerAccount[0].length
                    )}`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#868686",
                      marginTop: "10px",
                      marginBottom: "10px",
                      cursor: "pointer"
                    }}
                  >
                    <div
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        copy(props.headerAccount[0]);
                        props.messageAction({
                          message: "Successfully copied to clipboard",
                          status: "success"
                        });
                      }}
                    >
                      <FiCopy style={{ marginRight: "10px" }} />
                      Copy Address
                    </div>
                    <div
                      onClick={() =>
                        window.open(
                          `${networkMap[props.headerNeworkId]}address/${props.headerAccount[0]
                          }`
                        )
                      }
                    >
                      <BiLinkExternal style={{ marginRight: "10px" }} />
                      View on Explorer
                    </div>
                  </div>
                  <div>Bridges</div>
                  <div
                    style={{
                      display: "flex",
                      color: "#868686",
                      marginTop: "10px",
                      cursor: "pointer"
                    }}
                  >
                    <div
                      style={{ marginRight: "20px", marginBottom: "20px" }}
                      onClick={() =>
                        window.open("https://wallet.polygon.technology/bridge")
                      }
                    >
                      <BiLinkExternal style={{ marginRight: "10px" }} />
                      Polygon Bridge
                    </div>
                    <div
                      onClick={() =>
                        window.open("https://bridge.umbria.network/")
                      }
                    >
                      <BiLinkExternal style={{ marginRight: "10px" }} />
                      Umbria Bridge
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      props.showAccountModal(false);
                      props.headerAccountAction(() => []);
                    }}
                    style={{ float: "right", marginRight: "30px" }}
                  >
                    Logout
                  </Button>
                </div>
              </Modal>
            )}

            {props.data && data.twitterUrl && (
              <ListItem key={"Twitter"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.twitterUrl, "_blank")}
                >
                  <AiFillTwitterCircle className={classes.icon} />

                  <ListItemText className={classes.mLeft} primary={"Twitter"} />
                </ListItemButton>
              </ListItem>
            )}
            {props.data && data.discordUrl && (
              <ListItem key={"Discord"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.discordUrl, "_blank")}
                >
                  <FaDiscord className={classes.icon} />

                  <ListItemText className={classes.mLeft} primary={"Discord"} />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem className={classes.MATIC} key={"Withdraw MATIC"} disablePadding>

              <ListItemButton
                onClick={() => history.push("/withdraw")}
              >
                <BiMoneyWithdraw className={classes.icon} />

                <ListItemText className={classes.mLeft} primary={"Withdraw MATIC"} />

              </ListItemButton>

            </ListItem>



            {/* <ListItem key={"claim page"} disablePadding>
              <ListItemButton
                onClick={() => {
                  history.push("/claimPage");
                  onClose();
                }}
              >
                <WebIcon className={classes.icon} />

                <ListItemText
                  className={classes.mLeft}
                  primary={"Claim Page"}
                />
              </ListItemButton>
            </ListItem> */}





            {props.data && data.linkedinUrl && (
              <ListItem key={"LinkedIn"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.linkedinUrl, "_blank")}
                >
                  <AiFillLinkedin className={classes.icon} />

                  <ListItemText
                    className={classes.mLeft}
                    primary={"LinkedIn"}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {props.data && data.instagramUrl && (
              <ListItem key={"Instagram"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.instagramUrl, "_blank")}
                >
                  <GrInstagram className={classes.icon} />

                  <ListItemText
                    className={classes.mLeft}
                    primary={"Instagram"}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {props.data && data.mediumUrl && (
              <ListItem key={"MediumUrl"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.mediumUrl, "_blank")}
                >
                  <AiFillMediumSquare className={classes.icon} />

                  <ListItemText
                    className={classes.mLeft}
                    primary={"MediumUrl"}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {props.data && data.telegramUrl && (
              <ListItem key={"telegramUrl"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.telegramUrl, "_blank")}
                >
                  <FaTelegramPlane className={classes.icon} />

                  <ListItemText
                    className={classes.mLeft}
                    primary={"telegramUrl"}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {props.data && data.twitchUrl && (
              <ListItem key={"Twitch"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.twitchUrl, "_blank")}
                >
                  <FaTwitch className={classes.icon} />

                  <ListItemText className={classes.mLeft} primary={"Twitch"} />
                </ListItemButton>
              </ListItem>
            )}
            {props.data && data.websiteUrl && (
              <ListItem key={"Website"} disablePadding>
                <ListItemButton
                  onClick={() => window.open(data.websiteUrl, "_blank")}
                >
                  <CgWebsite className={classes.icon} />

                  <ListItemText className={classes.mLeft} primary={"Website"} />
                </ListItemButton>
              </ListItem>
            )}



            <ListItem key={"Powered by Poly Admin"} disablePadding className={classes.linkButton} >

              <Link href="https://polyadmin.com/" className={classes.linkText}>
                {/* <WebIcon className={classes.icon} /> */}
                Powered by Polyadmin
                {/* <ListItemText

                  className={classes.linkText}
                  primary={""}
                /> */}
              </Link>


            </ListItem>

          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
