import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import draftToHtml from "draftjs-to-html";
import React from "react";
import "./sol.css";
const Solutions = props => {
  let checkAddress = window.location.href;
  let check = checkAddress.includes("customLandingPage");
  const theme = useTheme();
  return (
    <div>
      {props.data ? (
        <Box marginLeft={2}>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: "medium"
              }}
              gutterBottom
              color={"secondary"}
            >
              {props.data &&
                props.data.properties.solution &&
                props.data.properties.solution.title.value}
            </Typography>
            <Typography
              variant="h3"
              data-aos={check ? null : "fade-up"}
              gutterBottom
              sx={{
                fontWeight: 700
              }}
              dangerouslySetInnerHTML={{
                __html: props.data
                  ? draftToHtml(
                      JSON.parse(
                        props.data.properties.solution.description.value1
                      )
                    )
                  : "Header Discription"
              }}
            />
            <Typography
              variant="h6"
              color={"textSecondary"}
              data-aos={check ? null : "fade-up"}
              dangerouslySetInnerHTML={{
                __html: props.data
                  ? draftToHtml(
                      JSON.parse(
                        props.data.properties.solution.description.value2
                      )
                    )
                  : "Header Discription"
              }}
            />
          </Box>
          <Grid container spacing={4}>
            {props.data &&
              props.data.properties.solution.problem.map((item, i) => (
                <Grid item xs={12} sm={6} md={3} key={i}>
                  <Typography variant={"h6"} gutterBottom>
                    {item.question}
                  </Typography>
                  {item.answer.map((feature, j) => (
                    <Box
                      component={ListItem}
                      disableGutters
                      width={"auto"}
                      padding={0}
                      key={j}
                      data-aos={check ? null : "fade-up"}
                    >
                      <Box
                        component={ListItemAvatar}
                        minWidth={"auto !important"}
                        marginRight={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={theme.palette.secondary.main}
                          width={20}
                          height={20}
                        >
                          <svg
                            width={12}
                            height={12}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Box>
                      </Box>
                      <ListItemText primary={feature.solution} />
                    </Box>
                  ))}
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export default Solutions;
