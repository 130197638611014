import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import logo from "../../images/rocket-joypixels.gif";
import AuthCardWrapper from "../Login/CardWrapper/AuthCardWrapper";
import AuthWrapper1 from "../Login/CardWrapper/AuthWrapper1";
import { forgetverifyEmail } from "./action";
import Authforget from "./ForgetPassForm";

const ForgotPassword = props => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AuthWrapper1>
      {props.waitingLogin && (
        <Backdrop
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={props.waitingLogin}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 1 }}>
                    <img src={logo} width="50px" height={"45px"} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Forgot Password
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                          >
                            Enter your credentials to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Authforget forgetverifyEmail={props.forgetverifyEmail} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    forgetverifyEmail: values => {
      dispatch(forgetverifyEmail(values));
    }
  };
};

const mapStateToProps = state => {
  return {
    message: state.userData.message,
    waitingLogin: state.userData.waitingLogin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
