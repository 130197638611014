import {
  ADD_USER_EMAILS_POLICIE,
  CHANGE_POLICY_ROLE,
  DELETE_EMAIL_ACTION_POLICIE,
  GET_USER_EMAILS_POLICIE,
  SEND_TOKEN,
  SET_ADDED_USER_EMAILS_POLICIE,
  SET_INVITE_BTN_LOADER,
  SET_MODAL_STATUS,
  USER_TABLE_LOADER,
} from "./constant";

export const addUserEmailsActionPolicie = (value) => {
  return {
    type: ADD_USER_EMAILS_POLICIE,
    payload: value,
  };
};

export const getAddedEmailsActionPolicie = (value) => {
  return {
    type: GET_USER_EMAILS_POLICIE,
    payload: value,
  };
};

export const setAddedEmailActionPolicie = (value) => {
  return {
    type: SET_ADDED_USER_EMAILS_POLICIE,
    payload: value,
  };
};

export const deleteEmailActionPolicie = (value) => {
  return {
    type: DELETE_EMAIL_ACTION_POLICIE,
    payload: value,
  };
};

export const setModalStatus = (value) => {
  return {
    type: SET_MODAL_STATUS,
    payload: value,
  };
};
export const setInviteBtnLoader = (value) => {
  return {
    type: SET_INVITE_BTN_LOADER,
    payload: value,
  };
};

export const changePolicyRoleAction = (value) => {
  return {
    type: CHANGE_POLICY_ROLE,
    payload: value,
  };
};

export const sendTokenAction = (value) => {
  return {
    type: SEND_TOKEN,
    payload: value,
  };
};

export const setLoaderForUserTable = (value) => {
  return {
    type: USER_TABLE_LOADER,
    payload: value
  }
}
