import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getallpolicyusersapi = async (data) => {
  try {
    const result = await getAxiosInstance().get(`${API}getallpolicyuser`);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getAllPolicyApi = async (data) => {
  try {
    const result = await getAxiosInstance().get(`${API}myAssignPolicyVote`);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createPolicyApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(`${API}createpolicy`, data);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createPolicyStripeApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(
      `${API}createpolicy_stripe`,
      data
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const deletePolicyApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(`${API}deletepolicy`, data);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const assignUserApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(
      `${API}assignpolicyuser`,
      data
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const requestForPolicyByUserApi = async (data) => {
  try {
    const result = await getAxiosInstance().post(
      `${API}user_requestforpolicy`,
      data
    );
    return { data: result };
  } catch (e) {
    return { error: e };
  }
};

export const getUserApprovalForPolicies = async (data) => {
  try {
    const result = await getAxiosInstance().get(
      `${API}userRequestVoteFor_policy`
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getAllStripeAccountsApi = async (data) => {
  try {
    const result = await getAxiosInstance().get(`${API}get_stripe_accounts`);
    return result;
  } catch (e) {
    console.log(e);
  }
};
