import AWS from "aws-sdk";
import awsmobile from "../aws/aws-exports";

const s3 = new AWS.S3({
  accessKeyId: awsmobile.ACCESS_KEY_ID,
  secretAccessKey: awsmobile.SECRET_ACCESS_KEY,
  region: awsmobile.aws_user_files_s3_bucket_region,
  Bucket: awsmobile.bucket,
});

const emptyS3Directory = async (bucket, dir) => {
  const listParams = {
    Bucket: bucket,
    Prefix: dir,
  };

  const listedObjects = await s3.listObjectsV2(listParams).promise();

  if (listedObjects.Contents.length === 0) return;

  const deleteParams = {
    Bucket: bucket,
    Delete: { Objects: [] },
  };

  listedObjects.Contents.forEach(({ Key }) => {
    deleteParams.Delete.Objects.push({ Key });
  });
  try {
    await s3.deleteObjects(deleteParams).promise();
  } catch (error) {
    console.log(error);
  }

  if (listedObjects.IsTruncated) await emptyS3Directory(bucket, dir);
};

export default emptyS3Directory;
