import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import "./ChartStyles.css";
import { getMetricsData } from "./redux/action";

const data = [
  {
    date: "2024-03-14",
    CreatePolicy: 2,
    UserVote: 3,
    UserRegister: 1,
    UserLogin: 12,
  },
  {
    date: "2024-03-13",
    CreatePolicy: 1,
    UserVote: 1,
    UserRegister: 1,
    UserLogin: 5,
  },
  {
    date: "2024-03-12",
    CreatePolicy: 1,
    UserVote: 1,
    UserRegister: 1,
    UserLogin: 0,
  },
  {
    date: "2024-03-11",
    CreatePolicy: 1,
    UserVote: 2,
    UserRegister: 1,
    UserLogin: 0,
  },
  {
    date: "2024-03-10",
    CreatePolicy: 0,
    UserVote: 0,
    UserRegister: 0,
    UserLogin: 0,
  },
  {
    date: "2024-03-09",
    CreatePolicy: 0,
    UserVote: 0,
    UserRegister: 0,
    UserLogin: 0,
  },
  {
    date: "2024-03-08",
    CreatePolicy: 13,
    UserVote: 14,
    UserRegister: 0,
    UserLogin: 0,
  },
];

export default function Metrics() {
  const chartData = useSelector((state) => state.metricsDataReducer.chartData);
  const userData = useSelector((state) => state.userData.userData);
  const metricsLoader = useSelector((state) => state.metricsDataReducer.loader);
  const totalActivityCount = useSelector(
    (state) => state.metricsDataReducer.activityCount,
  );
  const registerUser = useSelector(
    (state) => state.metricsDataReducer.registerUser,
  );

  console.log(totalActivityCount);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(getMetricsData());
    }
  }, [userData]);

  console.log(chartData);

  return (
    <>
      {metricsLoader ? (
        <div>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={metricsLoader}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {totalActivityCount.map((e) => (
              <Box
                height={120}
                width={200}
                // my={4}
                // display="flex"
                // alignItems="center"
                // gap={4}
                // p={2}
                sx={{ border: "2px solid grey" }}
              >
                <Card variant="solid">
                  <CardContent>
                    <Typography
                      textAlign="center"
                      level="title-lg"
                      fontSize={16}
                      textColor="inherit"
                    >
                      {e.activity}
                    </Typography>
                  </CardContent>
                </Card>
                <CardContent>
                  <Typography textAlign="center" fontSize={16} level="h1">
                    {" "}
                    {e.count}
                  </Typography>
                </CardContent>
              </Box>
            ))}
          </div>
          <LineChart
            width={920}
            height={470}
            data={chartData}
            margin={{
              top: 20,
              right: 10,
              left: 110,
              bottom: 5,
            }}
          >
            <XAxis padding={{ left: 30 }} dataKey="date" />
            <YAxis padding={{ bottom: 30 }} />
            <Tooltip />
            <Legend />
            <Line type="default" dataKey="CreatePolicy" stroke="#8884d8" />
            <Line type="monotone" dataKey="UserRegister" stroke="#82ca9d" />
            <Line
              type="monotone"
              dataKey="TotalTransactions"
              stroke="	#BDB76B"
            />
            
            <Line type="monotone" dataKey="UserLogin" stroke="skyblue" />
          </LineChart>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {registerUser.map((e) => (
              <Box
                height={120}
                width={200}
                // my={4}
                // display="flex"
                // alignItems="center"
                // gap={4}
                // p={2}
                sx={{ border: "2px solid grey" }}
              >
                <Card variant="solid">
                  <CardContent>
                    <Typography
                      textAlign="center"
                      level="title-lg"
                      fontSize={16}
                      textColor="inherit"
                    >
                      {e.role}
                    </Typography>
                  </CardContent>
                </Card>
                <CardContent>
                  <Typography textAlign="center" fontSize={16} level="h1">
                    {" "}
                    {e.count}
                  </Typography>
                </CardContent>
              </Box>
            ))}
          </div>
        </>
      )}
    </>
  );
}
