import {
  GET_METRICS_DATA,
  SET_LOADER_METRICS,
  SET_METRICS_DATA,
  SET_TOTAL_ACTIVITY_COUNT,
  SET_TOTAL_REGISTER_USER,
} from "./constant";

export const getMetricsData = (value) => {

  return {
    type: GET_METRICS_DATA,
    payload: value,
  };
};

export const setMetricsData = (value) => {
  return {
    type: SET_METRICS_DATA,
    payload: value,
  };
};

export const setTotalActivityCount = (value) => {
  return {
    type: SET_TOTAL_ACTIVITY_COUNT,
    payload: value,
  };
};

export const setLoaderMetrics = (value) => {
  return {
    type: SET_LOADER_METRICS,
    payload: value,
  };
};

export const setTotalRegisterUser = (value) => {
  return {
    type: SET_TOTAL_REGISTER_USER,
    payload: value,
  };
};
