import { CHECK_OUT_TO_PAYMENT_PAGE, LOADER_BUTTON_ACTION, PAYMENT_PAGE_REDIRECT, POST_DATA_SIGN_UP, SIGNUP_EMAIL, SIGNUP_REGISTER, VERIFICATION_SIGNUP } from './constant'

export const postAction = (value) => {
    return {
        type: POST_DATA_SIGN_UP,
        payload: value,
    }
}
export const signupRegister = (value) => {

    return {
        type: SIGNUP_REGISTER,
        payload: value,
    }
}
export const signupEmail = (value) => {
    return {
        type: SIGNUP_EMAIL,
        payload: value,
    }
}
export const verificationsignup = (value) => {
    return {
        type: VERIFICATION_SIGNUP,
        payload: value,
    }
}
export const toPaymentCheckOut = (value) => {
    return {
        type: CHECK_OUT_TO_PAYMENT_PAGE,
        payload: value
    }
}

export const toPaymentCheckOutRedirect = (value) => {
    return {
        type: PAYMENT_PAGE_REDIRECT,
        payload: value
    }
}

export const loaderActionButton = (value) => {
    return {
        type: LOADER_BUTTON_ACTION,
        payload: value
    }
}