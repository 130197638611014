// material-ui
import { Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAxiosInstance } from "../../../../../../utils/axios";
import { API } from "../../../../../../utils/config";
import { isUserAssignPolicy } from "../../../../../CreatePolicies/Redux/action";
// import { getallseasonsAction } from "../../../../../Gamification/actions";
import { activeItem, drawerOpenAction } from "../../../../../Header/actions";
import menuItem from "../../../../menu-items";
import policyAdmin from "../../../../menu-items/policyAdmin";
// import  from "../../../../menu-items/";
// import superAdmin from "../../../../menu-items/superAdmin";
import NavGroup from "./NavGroup";

const MenuList = (props) => {
  const [menu, setMenu] = React.useState([]);

  const dispatch = useDispatch();

  const isUserPolicy = useSelector(
    (state) => state.CreatePolicesReducer.isUserAssignPolicy,
  );

  // const [assignPolicy, setAssignPolicy] = React.useState(null)

  useEffect(async () => {
    let res = await getAxiosInstance().get(`${API}is_user_invited`);

    dispatch(isUserAssignPolicy({ data: res.data }));
  }, []);

  useEffect(() => {
    let isRouteAvailable = menuItem.items.find(
      (item) => item.id == "#SuperAdmin",
    );
    if (props.user.isAdmin && !isRouteAvailable) {
      // props.getallseasonsAction();
      let newChildren = policyAdmin.children.filter(
        (e) => e.id !== "#requestedpolicy",
      );
      let newPolicyAdmin = { ...policyAdmin, children: newChildren };
      setMenu(menuItem.items.concat(newPolicyAdmin));
    }
    else if (props.userRole === "policysuperadmin") {

      let newChildren = policyAdmin.children.filter(
        (e) => e.id !== "#requestedpolicy"
      )

      let newPolicyAdmin = { ...policyAdmin, children: newChildren };
      setMenu([newPolicyAdmin]);
    }
    else if (props.userRole === "policyadmin") {

      //#policies
      let newChildren = policyAdmin.children.filter(
        (e) => e.id !== "#requestedpolicy" && e.id !== "#policies"
      );
      let newPolicyAdmin = { ...policyAdmin, children: newChildren };

      setMenu([newPolicyAdmin]);
    } else if (props.userRole === "user") {
      if (isUserPolicy) {
        let changeChildrenArray = policyAdmin.children.filter((e) => {
          if (e.id === "#createPolicy" || e.id === "#securePayment") {
            return {
              ...e,
              title: (e.title = <FormattedMessage id="Vote Center" />),
            };
          } else if (e.id === "#requestedpolicy") {
            return e;
          } else if (e.id === "#myapprovals") {
            return e;
          }
        });

        let updatechangeChildrenArray = {
          ...policyAdmin,
          title: <FormattedMessage id="Policy User" />,
          children: changeChildrenArray,
        };

        setMenu(menuItem.items.concat(updatechangeChildrenArray));
      } else {
        setMenu(menuItem.items.concat());
      }
    } else if (props.userRole === "policyuser") {
      let changeChildrenArray = policyAdmin.children.filter((e) => {
        if (e.id === "#createPolicy") {
          return {
            ...e,
            title: (e.title = <FormattedMessage id="Vote Center" />),
            url: (e.url = "/votecenter"),
          };
        } else if (e.id === "#securePayment") {
          return;
        } else if (e.id === "#requestedpolicy") {
          return e;
        } else if (e.id === "#myapprovals") {
          return e;
        } else if (e.id === "#chatAi") {
          return e;
        }
      });

      setMenu([
        {
          ...policyAdmin,
          title: <FormattedMessage id="Policy User" />,
          children: changeChildrenArray,
        },
      ]);
    } else {
      setMenu(menuItem.items.concat());
    }
  }, [props.user]);

  const navItems = menu.map((item) => {
    if (item.checkedIsAdmin && !props.user.isAdmin) {
      return;
    }
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
            setDrawer={props.setDrawer}
            activeItem={props.activeItem}
            sideBarDrawer={props.sideBarDrawer}
            openItem={props.openItem}
            user={props.user}
            drawerOpenAction={props.drawerOpenAction}
          />

        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeItem: (data) => {
      dispatch(activeItem(data));
    },
    drawerOpenAction: (data) => {
      dispatch(drawerOpenAction(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    sideBarDrawer: state.headerData.sideBarDrawer,
    openItem: state.headerData.openItem,
    user: state.userData.user,
    // seasonsList: state.gamification.seasonsList,
    userRole: state.accountData.data.role,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuList));
