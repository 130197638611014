import { Divider, List, Typography } from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  IconBasket,
  IconCalendar,
  IconLayoutKanban,
  IconMail,
  IconMessages,
  IconNfc,
  IconUserCheck
} from "@tabler/icons";
import PropTypes from "prop-types";
// project imports
import React from "react";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

const icons = {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc
};
const NavGroup = props => {

  const { item } = props;
  const theme = useTheme();
  // const Status= props.seasonsList.map((seasonsList) => seasonsList.status)
  // menu list collapse & items
  const items = item.children?.map(menu => {
    if (menu.isAdmin && !props.user.isAdmin) {
      return;
    }

    switch (menu.type) {
      case "collapse":

        return (
          <NavCollapse
            activeItem={props.activeItem}
            key={menu.id}
            menu={menu}
            level={1}
            drawerOpenAction={props.drawerOpenAction}
            openItem={props.openItem}
            user={props.user}
          // seasonsList={props.seasonsList}
          />
        );
      case "item":
        return (
          <>
            <NavItem
              // seasonsList={props.seasonsList}
              key={menu.id}
              item={menu}
              level={1}
              activeItem={props.activeItem}
              drawerOpenAction={props.drawerOpenAction}
              openItem={props.openItem}
              user={props.user}
            />
          </>
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption }}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
