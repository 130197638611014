import {
  ABOUT_US_DATA,
  ABOUT_US_MODAL_SHOW,
  CLAIM_NFT_MODAL,
  CUSTOM_LANDING_PAGE_LOADER,
  DELETE_CUSTOM_IMAGE,
  DELETE_LANDING_PAGE,
  DELETE_LANDING_PAGE_SUCCESS,
  FETCH_LANDING_PAGE,
  FETCH_LANDING_PAGE_SUCCESS,
  GET_CUSTOM_IMAGE_LIST,
  GET_MATRIX_TABLE_DATA,
  HEADER_ACCOUNT,
  HEADER_AIRDROP_DATA,
  HEADER_COLOR,
  HEADER_NETWORKID,
  HEADER_SET_BALANCE,
  HEADER_SHOW_SIDEBAR,
  HEADER_WALLET_TYPE,
  LANDINGPAGE_DATA,
  LANDINGPAGE_DATA_SUCCESS,
  LANDING_HOME_PAGE_DATA,
  LOADING_DATA,
  MESSAGE_DATA,
  NFT_FACTORY_CONTRACT_MINT,
  REGISTER_USER,
  SET_CUSTOM_IMAGE_LIST,
  SET_EMAIL_ID,
  SET_MATRIX_TABLE_DATA,
  SHOW_ACCOUNT_MODAL,
  STORE_MATRIX_DATA,
  THEME_BACKGROUND,
  UPLOAD_CUSTOM_IMAGE
} from "./constants";

export const setMatrixTableDataAction = value => {
  return {
    type: SET_MATRIX_TABLE_DATA,
    payload: value
  };
};
export const getMatrixTableDataAction = value => {
  return {
    type: GET_MATRIX_TABLE_DATA,
    payload: value
  };
};
export const storeMatrixDataAction = value => {
  return {
    type: STORE_MATRIX_DATA,
    payload: value
  };
};
export const deleteCustomImageAction = value => {
  return {
    type: DELETE_CUSTOM_IMAGE,
    payload: value
  };
};
export const uploadCustomImageAction = value => {
  return {
    type: UPLOAD_CUSTOM_IMAGE,
    payload: value
  };
};

export const setCustomImageListAction = values => {
  return {
    type: SET_CUSTOM_IMAGE_LIST,
    payload: values
  };
};
export const getCustomImageListAction = values => {
  return {
    type: GET_CUSTOM_IMAGE_LIST,
    payload: values
  };
};
export const registerUserAction = values => {
  return {
    type: REGISTER_USER,
    payload: values
  };
};
export const themeBackgroundAction = values => {
  return {
    type: THEME_BACKGROUND,
    payload: values
  };
};
export const headerColorAction = values => {
  return {
    type: HEADER_COLOR,
    payload: values
  };
};
export const headerSideBarShow = values => {
  return {
    type: HEADER_SHOW_SIDEBAR,
    payload: values
  };
};
export const nftFactoryContractMint = values => {
  return {
    type: NFT_FACTORY_CONTRACT_MINT,
    payload: values
  };
};
export const landingPageData = values => {
  return {
    type: LANDINGPAGE_DATA,
    payload: values
  };
};
export const landingPageSuccessData = values => {
  return {
    type: LANDINGPAGE_DATA_SUCCESS,
    payload: values
  };
};
export const homePageDataAction = values => {
  return {
    type: LANDING_HOME_PAGE_DATA,
    payload: values
  };
};
export const headerNetworkId = values => {
  return {
    type: HEADER_NETWORKID,
    payload: values
  };
};

export const headerWalletType = values => {
  return {
    type: HEADER_WALLET_TYPE,
    payload: values
  };
};

export const headerAccountAction = values => {
  return {
    type: HEADER_ACCOUNT,
    payload: values
  };
};
export const headerAirDropData = values => {
  return {
    type: HEADER_AIRDROP_DATA,
    payload: values
  };
};
export const headerSetBalance = values => {
  return {
    type: HEADER_SET_BALANCE,
    payload: values
  };
};
export const showAccountModal = values => {
  return {
    type: SHOW_ACCOUNT_MODAL,
    payload: values
  };
};

export const claimNftModal = values => {
  return {
    type: CLAIM_NFT_MODAL,
    payload: values
  };
};
export const AboutUsData = values => {
  return {
    type: ABOUT_US_DATA,
    payload: values
  };
};

export const fetchData = values => {
  return {
    type: FETCH_LANDING_PAGE,
    payload: values
  };
};
export const aboutUpModalShow = values => {
  return {
    type: ABOUT_US_MODAL_SHOW,
    payload: values
  };
};

export const fetchLandingDataSuccess = data => {
  return {
    type: FETCH_LANDING_PAGE_SUCCESS,
    payload: data
  };
};

export const deleteData = values => {
  return {
    type: DELETE_LANDING_PAGE,
    payload: values
  };
};

export const deleteLandingPageSuccess = data => {
  return {
    type: DELETE_LANDING_PAGE_SUCCESS,
    payload: data
  };
};

export const loadingAction = data => {
  return {
    type: LOADING_DATA,
    payload: data
  };
};

export const messageAction = data => {
  return {
    type: MESSAGE_DATA,
    payload: data
  };
};

export const setEmailIdData = data => {
  return {
    type: SET_EMAIL_ID,
    payload: data
  };
};

export const customLandingPageLoader = data => {
  return {
    type: CUSTOM_LANDING_PAGE_LOADER,
    payload: data
  };
};
