import { GET_MY_APPROVED_POLICIES, SET_MY_APPROVED_POLICIES } from "./constant";

export const getApprovedPolicies = (value) => {
  return {
    type: GET_MY_APPROVED_POLICIES,
    payload: value,
  };
};
export const setApprovedPolicies = (value) => {
  return {
    type: SET_MY_APPROVED_POLICIES,
    payload: value,
  };
};
