import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import validator from "validator";
const Footer = props => {
  const theme = useTheme();
  const [email, setEmail] = React.useState("");

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      if (!email) {
        props.messageAction({
          message: "Must be a valid email address",
          status: "error"
        });
        return;
      } else if (!validator.isEmail(email)) {
        props.messageAction({
          message: "Must be a valid email address",
          status: "error"
        });
        return;
      }
      props.registerUserAction({
        values: email,
        landingPageId: props.data._id,
        setEmail: setEmail
      });
    } catch (err) {
      props.messageAction({
        message: "Must be a valid email address",
        status: "error"
      });
      console.log(err);
    }
  };

  return (
    <Box>
      {props.data && props.data.properties.registerUser ? (
        <>
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{
              color: theme.palette.common.white,
              fontWeight: 900
            }}
          >
            {props.data.properties.registerUser.title.value}
          </Typography>
          <Box component={Card} maxWidth={600} boxShadow={4}>
            <CardContent>
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Box marginBottom={2}></Box>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "stretched", sm: "flex-start" }}
                  >
                    <Box
                      flex={"1 1 auto"}
                      component={TextField}
                      label={`${props.data.properties.registerUser.button.value.substring(
                        0,
                        25
                      )}...`}
                      variant="outlined"
                      onChange={e => {
                        console.log(e);
                        setEmail(e.target.value);
                      }}
                      color="primary"
                      fullWidth
                      height={54}
                    />
                    <Box
                      component={Button}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      height={54}
                      marginTop={{ xs: 2, sm: 0 }}
                      marginLeft={{ sm: 2 }}
                    >
                      {props.data &&
                        props.data.properties.registerUser.button.text
                        ? props.data.properties.registerUser.button.text
                        : "Subscribe"}
                    </Box>
                  </Box>
                </form>
              </Box>
            </CardContent>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default Footer;
