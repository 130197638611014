import { Auth } from "aws-amplify";
import React, { Fragment, memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import { setUserWalletAddress } from "./components/Account/actions";
import HeaderLayout from "./components/HeaderLayout/layout/MainLayout/index";
import { userDataAction } from "./components/Login/actions";
import { getAxiosInstance, setToken } from "./utils/axios";
import { API } from "./utils/config";

// import { verifyeditdata } from "./components/Account/actions";
// import {useHistory} from "react-router-dom"
function PublicRoute(props) {
  // const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const getUser = async () => {
    try {
      let getCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();

      if (getCurrentAuthenticatedUser) {
        props.userDataAction(getCurrentAuthenticatedUser.attributes);
        const data = {
          userId: getCurrentAuthenticatedUser.attributes.sub,
          email: getCurrentAuthenticatedUser.attributes.email,
          given_name: getCurrentAuthenticatedUser.attributes.given_name || "",
          family_name: getCurrentAuthenticatedUser.attributes.family_name || "",
        };

        let authUser = await getAxiosInstance().post(`${API}verifyUser`, data);

        if (authUser.status && authUser.data.message == "OK") {
          props.setUserWalletAddress(authUser.data.walletAddress);
        }

        if (props.path !== "/landingPage*") {
          props.history.push("/");
        }
        setToken(
          getCurrentAuthenticatedUser.signInUserSession.idToken.jwtToken,
        );
        setIsUserAuthenticated(true);
      }
    } catch (error) {
      setLoaded(true);
    }
  };

  useEffect(async () => {
    await getUser();
  }, []);

  const { component: Component, ...rest } = props;

  if (!loaded) return null;
  return (
    <Fragment>
      {isUserAuthenticated ? (
        <HeaderLayout
          componentToPassDown={
            <Route
              {...rest}
              render={(props) => (
                <Fragment>
                  <Component {...props} />
                </Fragment>
              )}
            />
          }
        />
      ) : (
        <Switch>
          <Route exact path={props.path} component={props.component} />
        </Switch>
      )}
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    userDataAction: (values) => {
      dispatch(userDataAction(values));
    },
    setUserWalletAddress: (values) => {
      dispatch(setUserWalletAddress(values));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userData.user || { userId: "", isAdmin: false },
    userData: state.userData.userData,
    // update_data: state.accountData.data
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(withRouter(PublicRoute));
