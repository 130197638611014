import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import NewChat from "./component/AddNewChat/index";
import ChatGroupList from "./component/ChatGroupList/index";

function LeftSideContainer(props) {
  const useStyles = makeStyles(() => ({}));

  const classes = useStyles();

  return (
    <div>
      <NewChat
        AllChatList={props.AllChatList}
        addChatInListAction={props.addChatInListAction}
      />
      <Divider className="GroupDivider" />
      <ChatGroupList
        AllChatList={props.AllChatList}
        EditchatId={props.EditchatId}
        selectedEditChatIdAction={props.selectedEditChatIdAction}
        editchatname={props.editchatname}
        editChatNameAction={props.editChatNameAction}
        deleteChatFromListAction={props.deleteChatFromListAction}
        editChatNameInListAction={props.editChatNameInListAction}
        selectedChatMessageIdAction={props.selectedChatMessageIdAction}
        selectedMessageId={props.selectedMessageId}
      />
    </div>
  );
}

export default LeftSideContainer;
