// assets
import GoogleIcon from "@mui/icons-material/Google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";

// material-ui
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
// third party
import * as Yup from "yup";
// project imports
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getAxiosInstance } from "../../utils/axios";
import { API } from "../../utils/config";
import AnimateButton from "../HeaderLayout/ui-component/extended/AnimateButton";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";

const FirebaseRegister = (props) => {
  const message = useSelector((state) => state.userData.message);
  const [loader, setLoading] = useState(false);
  const closeSnackBar = () => {
    dispatch(messageDataAction({}));
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  // const { signupProp, ...others } = props;

  const history = useHistory();
  const hostname = window.location.hostname;
  let referral_id;

  if (window.location.search) {
    let query = queryString.parse(window.location.search);
    referral_id = query.referral_id;
  }

  const handleSubmit = async (values) => {
    const id = uuidv4();

    const product = {
      product: process.env.REACT_APP_PRODUCT_ID,
    };

    let policyUserEmail = [];

    let policyuserlist = await getAxiosInstance().get(
      `${API}getinviteduseremail`,
    );

    if (policyuserlist && policyuserlist.status == 200) {
      policyUserEmail = policyuserlist.data.data;
    }

    // let userListArr = [];
    // let userList = await getAxiosInstance().get(`${API}getAddedEmails`);
    // if (userList.status == 200) {
    //   userListArr = userList.data.data.emails;
    // }
    // let data = await getAxiosInstance().get(
    //   `${API}products/${product.product}`
    // );

    let res = {
      data: {
        username: values.email.toLowerCase(),
        password: values.password,
        attributes: {
          given_name: values.firstName,
          family_name: values.lastName,
        },
        hostname: hostname,
      },
      email: values.email.toLowerCase(),
      history: history,
      id: id,

      // chargeData: {
      //   amount: data.data.data.offerPrice,
      //   orderId: `${id}`,
      //   paymentType: "Card",
      //   productId: product.product,
      //   productImage: data.data.data.productThumbnailDetails[0].productImages,
      //   productName: data.data.data.productName,
      //   qty: 1,
      //   skuName: data.data.data.productThumbnailDetails[0].SkuName,
      //   signup: data.data.data.signup,
      // },
    };

    // if (
    //   policyUserEmail.includes(values.email.toLowerCase()) ||
    //   hostname === "privatefrontend.polyadmin.com" ||
    //   hostname === "polyadmin-docker-00" ||
    //   hostname === "localhost"
    // ) {
    return props.signupRegister({
      data: res.data,
      history: history,
      hostname,
    });
    // } else {
    //   // !userListArr.includes(res.data.username)
    //   //   ? props.toPaymentCheckOut(res)
    //   //   : props.signupRegister({ data: res.data, history: history });
    // }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              Sign up with Email address
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .max(255)
            .required("Password is required"),
          firstName: Yup.string().max(255).required("FirstName is required"),
          lastName: Yup.string().max(255).required("LastName is required"),
        })}
        onSubmit={async (values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 500);
          await handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(touched.firstName && errors.firstName)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                First Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                value={values.firstName}
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.firstName && errors.firstName && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.firstName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(touched.lastName && errors.lastName)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Last Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                value={values.lastName}
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.lastName && errors.lastName && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.lastName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Email Address / Username
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-password-register">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-register"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                label="Password"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-register"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="subtitle1">
                      Agree with Terms & Conditions.
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Sign up
                </Button>
              </AnimateButton>
            </Box>
            <Box sx={{ mt: 2 }}>
              {/* <AnimateButton > */}

              <LoadingButton
                disabled={loader}
                // loadingIndicator="Loading…"
                onClick={() => {
                  setLoading(true);

                  let uri = window.location.origin + "/createPolicy";

                  window.location.href = `https://metaontheblock164d0383-164d0383-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${uri}/&response_type=token&client_id=5p95qud4aka9bs2msrcljm63e9&scope=aws.cognito.signin.user.admin email openid phone profile`;

                  // setLoading(false)
                  // dispatch(waitingAction(false))
                }}
                // disabled={isSubmitting}
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
                color="secondary"
              >
                <GoogleIcon sx={{ mr: 2 }} />
                Sign up with Google
                {/* Sign in with Google */}
              </LoadingButton>

              {/* </AnimateButton> */}
            </Box>
          </form>
        )}
      </Formik>

      <ToastMsg toastMessage={message} open={true} close={closeSnackBar} />
    </>
  );
};

export default FirebaseRegister;
