import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";
import Header from "./Header";
import LeftSideContainer from "./LeftSideContainer";
import {
  addChatInListAction,
  deleteChatFromListAction,
  editChatNameAction,
  editChatNameInListAction,
  getAllChatListAction,
  selectedChatMessageIdAction,
  selectedEditChatIdAction,
  sendUserQuestionAction
} from "./Redux/actions";
import RightSideContainer from "./RightSideContainer";

function ChatGroup(props) {
  const useStyles = makeStyles(() => ({
    chatContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      overflow: "auto"
      // backgroundColor: "#191919"
    },
    leftContainer: {
      width: "20%",
      display: "inline-block",
      backgroundColor: "#191919",
      height: "100vh"
    },
    rightContainer: {
      width: "80%",
      display: "inline-block",
      backgroundColor: "#292929",
      height: "100vh"
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    props.getAllChatListAction();
  }, []);

  const closeSnackBar = () => {
    props.messageDataAction({});
  };

  return (
    <div>
      <Header />
      <div className={classes.chatContainer}>
        <ToastMsg
          toastMessage={props.message}
          open={true}
          close={closeSnackBar}
        />
        {/* <div
          style={{
            backgroundColor: "#191919",
            position: "absolute",
            top: "0px",
            width: "100%"
          }}
        ></div> */}

        <div className={classes.leftContainer}>
          <LeftSideContainer
            AllChatList={props.AllChatList}
            EditchatId={props.EditchatId}
            selectedEditChatIdAction={props.selectedEditChatIdAction}
            editchatname={props.editchatname}
            editChatNameAction={props.editChatNameAction}
            deleteChatFromListAction={props.deleteChatFromListAction}
            editChatNameInListAction={props.editChatNameInListAction}
            addChatInListAction={props.addChatInListAction}
            selectedChatMessageIdAction={props.selectedChatMessageIdAction}
            selectedMessageId={props.selectedMessageId}
          />
        </div>

        <div className={classes.rightContainer}>
          <RightSideContainer
            selectedMessageId={props.selectedMessageId}
            AllChatList={props.AllChatList}
            messageDataAction={props.messageDataAction}
            sendUserQuestionAction={props.sendUserQuestionAction}
            waitingChat={props.waitingChat}
            userData={props.userData}
          />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getAllChatListAction: values => {
      dispatch(getAllChatListAction(values));
    },
    selectedEditChatIdAction: values => {
      dispatch(selectedEditChatIdAction(values));
    },
    editChatNameAction: values => {
      dispatch(editChatNameAction(values));
    },
    deleteChatFromListAction: values => {
      dispatch(deleteChatFromListAction(values));
    },
    editChatNameInListAction: values => {
      dispatch(editChatNameInListAction(values));
    },
    addChatInListAction: values => {
      dispatch(addChatInListAction(values));
    },
    selectedChatMessageIdAction: values => {
      dispatch(selectedChatMessageIdAction(values));
    },
    messageDataAction: values => {
      dispatch(messageDataAction(values));
    },
    sendUserQuestionAction: values => {
      dispatch(sendUserQuestionAction(values));
    }
  };
};

const mapStateToProps = state => {
  return {
    message: state.userData.message,
    waitingChat: state.chatReducer.waitingChat,
    userData: state.userData.userData,
    AllChatList: state.chatReducer.AllChatList,
    EditchatId: state.chatReducer.EditchatId,
    selectedMessageId: state.chatReducer.selectedMessageId,
    editchatname: state.chatReducer.editchatname
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatGroup);
