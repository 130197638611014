import { Auth } from "aws-amplify";
import { call, put, takeLatest } from "redux-saga/effects";
import { ChangePassApi } from "../../api";
// import { messageAction } from "../CustomLandingPage/actions";
import {
  messageDataAction,
  postLoginSuccess,
  setRefferalCode,
  waitingAction,
} from "../Login/actions";
import { postLoginApi } from "../Login/api";
import { getprofiledata } from "./actions";
import { editprofileApi } from "./api";
import {
  CHANGE_PASSWORD,
  EDIT_PROFILE_DATA,
  VERIFY_EDIT_DATA,
} from "./constants";

function* changePasswordSaga(action) {
  try {
    yield put(waitingAction(true));
    const result = yield call(ChangePassApi, action.payload);
    if (result === "SUCCESS") {
      yield put(waitingAction(false));
      yield put(
        messageDataAction({
          message: `Your password has been changed.`,
          status: "success",
        }),
      );

      action.payload.history.push("/account");
    } else if (result.err) {
      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error",
        }),
      );
      yield put(waitingAction(false));
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));

    console.log(err);
  }
}

function* editprofilesaga(action) {
  try {
    yield put(waitingAction(true));

    let result = yield call(editprofileApi, action.payload);
    if (result.status === 200) {
      yield put(getprofiledata(result.data.data));
      yield put(waitingAction(false));
      action.payload.history.push("/account");
      if (result.data.data) {
        yield put(waitingAction(false));

        yield put(
          messageDataAction({
            message: `Your Profile details have been saved`,
            status: "success",
          }),
        );
      }
    } else if (result.err) {
      yield put(waitingAction(false));

      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error",
        }),
      );
    }
    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));

    console.log(err);
  }
}

function* verfiyeditdatasaga(action) {
  try {
    yield put(waitingAction(true));

    let result = yield call(postLoginApi, action.payload.data);

    yield put(waitingAction(false));

    if (result && result.status == 200) {
      if (!result.data.data) {
        Auth.signOut();
        yield put(
          messageDataAction({
            message: result.data.message,
            status: "error",
          }),
        );
        return;
      }

      yield put(
        setRefferalCode(
          result.data.data.referral_id ? result.data.data.referral_id : "",
        ),
      );

      if (result.data.data.role === "admin") {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: true }),
        );

        action.payload.history.push("/createPolicy");
      } else if (
        result.data.data.role === "profileuser" &&
        window.location.hostname !== "polyadmin.com" &&
        window.location.hostname !== "localhost"
      ) {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: false }),
        );

        action.payload.history.push("/editprofile");
      } else {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: false }),
        );

        action.payload.history.push("/createPolicy");
      }
      yield put(getprofiledata(result.data.data));
    } else if (result && result.status === 201) {
      if (!result.data.data) {
        Auth.signOut();
        yield put(
          messageDataAction({
            message: result.data.message,
            status: "error",
          }),
        );
        return;
      }
      yield put(
        setRefferalCode(
          result.data.data.referral_id ? result.data.data.referral_id : "",
        ),
      );

      if (result.data.data.role === "admin") {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: true }),
        );

        action.payload.history.push("/createPolicy");
      } else if (
        result.data.data.role === "profileuser" &&
        window.location.hostname !== "polyadmin.com" &&
        window.location.hostname !== "localhost"
      ) {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: false }),
        );

        action.payload.history.push("/createPolicy");
      } else {
        yield put(
          postLoginSuccess({ userId: result.data.username, isAdmin: false }),
        );

        action.payload.history.push("/createPolicy");
      }

      yield put(getprofiledata(result.data.data));
    } else if (result && result.status == 400) {
      yield put(
        messageDataAction({
          message: result.data.message,
          status: "error",
        }),
      );
    } else if (result.err) {
      yield put(
        messageDataAction({
          message: result.err.message,
          status: "error",
        }),
      );
    }

    yield put(waitingAction(false));
  } catch (err) {
    yield put(waitingAction(false));

    console.log(err);
  }
}

function* accountSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(EDIT_PROFILE_DATA, editprofilesaga);
  yield takeLatest(VERIFY_EDIT_DATA, verfiyeditdatasaga);
}

export default accountSaga;
