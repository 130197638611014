import { call, put, takeLatest } from "redux-saga/effects";
import { postLoginSuccess } from "../Login/actions";
import { postDataApi } from "./api";
import { POST_DATA_HEADER } from "./constants";

function* postDataSaga(action) {
  try {
    const data = yield call(postDataApi, action.payload);
    console.log(data)
    yield put(postLoginSuccess(data));
  } catch (err) {
    console.log(err);
  }
}

function* headerSaga() {
  yield takeLatest(POST_DATA_HEADER, postDataSaga);
}

export default headerSaga;
