import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery
} from "@mui/material";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setUserWalletAddress, setWalletRelatedInfo } from "../../../Account/actions";
import { activeItem, drawerOpenAction } from "../../../Header/actions";
import {
  claimNftModal,
  headerAccountAction,
  headerAirDropData,
  headerNetworkId,
  headerSetBalance,
  headerWalletType,
  messageAction,
  showAccountModal
} from "../../../LandingPage/actions";
import { postLogoutSuccess } from "../../../Login/actions";
import { drawerWidth } from "../../store/constant";
import Header from "./Header";
import Sidebar from "./Sidebar";
// styles
const Main = styled("main", { shouldForwardProp: prop => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px"
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px"
      }
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px"
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px"
      }
    })
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const HeaderLayout = props => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [path, setPath] = React.useState(props.componentToPassDown.props.path);
  const [openSidebar, setOpenSidebar] = useState(false);
  const {
    children,
    themeToggler,
    themeMode,
    setThemePalette,
    paletteType
  } = props;
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  React.useEffect(() => {
    props.drawerOpenAction(!matchDownMd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: props.drawerOpen
            ? theme.transitions.create("width")
            : "none"
        }}
      >
        <Toolbar>
          <Header
            drawerOpenAction={props.drawerOpenAction}
            drawerOpen={props.drawerOpen}
            postLogoutSuccess={props.postLogoutSuccess}
            path={path}
            userData={props.userData}
            refferal_code={props.refferal_code}
            update_data={props.update_data}
            headerAccountAction={props.headerAccountAction}
            messageAction={props.messageAction}
            headerAccount={props.headerAccount}
            onSidebarOpen={handleSidebarOpen}
            themeMode={themeMode}
            themeToggler={themeToggler}
            setThemePalette={setThemePalette}
            paletteType={paletteType}
            claimNftModal={props.claimNftModal}
            data={props.landingsuccessData}
            allAirdropData={
              props.landingsuccessData && props.landingsuccessData.airdropData
            }
            user={props.user}
            users={props.user}
            accountModal={props.accountModal}
            showAccountModal={props.showAccountModal}
            airDropData={props.airDropData}
            headerAirDropData={props.headerAirDropData}
            balance={props.balance}
            headerSetBalance={props.headerSetBalance}
            headerNeworkId={props.headerNeworkId}
            headerNetworkIdAction={props.headerNetworkIdAction}
            setWalletType={props.setWalletType}
            headerSidebar={props.headerSidebar}
            headerSideBarShow={props.headerSideBarShow}
            colorgroup={props.colorgroup}
            headerColorAction={props.headerColorAction}
            themecolor={props.themecolor}
            themeBackgroundAction={props.themeBackgroundAction}
            setUserWalletAddress={props.setUserWalletAddress}
            setWalletRelatedInfo={props.setWalletRelatedInfo}
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      {path !== "/login" && (
        <Sidebar
          drawerOpenAction={props.drawerOpenAction}
          drawerOpen={props.drawerOpen}
        />
      )}

      {/* main content */}
      <Main
        theme={theme}
        open={props.drawerOpen}
        style={{
          marginLeft:
            props.location && props.location.pathname === "/login" && "20px",

          marginTop: path === "/login" && "0px"
        }}
      >
        <Container maxWidth="lg">{props.componentToPassDown}</Container>
      </Main>
    </Box>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    activeItem: data => {
      dispatch(activeItem(data));
    },
    drawerOpenAction: data => {
      dispatch(drawerOpenAction(data));
    },
    headerAccountAction: data => {
      dispatch(headerAccountAction(data));
    },
    messageAction: data => {
      dispatch(messageAction(data));
    },
    showAccountModal: data => {
      dispatch(showAccountModal(data));
    },
    headerNetworkIdAction: data => {
      dispatch(headerNetworkId(data));
    },
    headerSetBalance: data => {
      dispatch(headerSetBalance(data));
    },
    setClaimNftModal: data => {
      dispatch(claimNftModal(data));
    },
    headerAirDropData: data => {
      dispatch(headerAirDropData(data));
    },
    postLogoutSuccess: data => {
      dispatch(postLogoutSuccess(data));
    },
    setWalletType: data => {
      dispatch(headerWalletType(data));
    },
    setUserWalletAddress: data => {
      dispatch(setUserWalletAddress(data))
    },
    setWalletRelatedInfo: data => {
      dispatch(setWalletRelatedInfo(data))
    },
  };
};

const mapStateToProps = state => {
  return {
    users: state.userData.user || { userId: "", isAdmin: false },
    modalShow: state.landingPageData.modalShow,
    aboutusData: state.landingPageData.aboutusData,
    aboutusIndex: state.landingPageData.aboutusIndex,
    isMessage: state.landingPageData.isMessage,
    claimNftModal: state.landingPageData.claimNftModal,
    accountModal: state.landingPageData.accountModal,
    airDropData: state.landingPageData.airDropData,
    balance: state.landingPageData.balance,
    headerAccount: state.landingPageData.headerAccount,
    headerNeworkId: state.landingPageData.headerNeworkId,
    WalletType: state.landingPageData.headerWalletType,
    landingsuccessData: state.landingPageData.landingsuccessData,
    stateLoading: state.landingPageData.isLoading,
    nftfactoryContract: state.landingPageData.nftfactoryContract,
    headerSidebar: state.landingPageData.headerSidebar,
    colorgroup: state.landingPageData.colorgroup,
    themecolor: state.landingPageData.themecolor,
    drawerOpen: state.headerData.drawerOpen,
    user: state.userData.user,
    userData: state.userData.userData,
    update_data: state.accountData.data,
    // homeData:
    //   state.superAdmin.superAdminData &&
    //   state.superAdmin.superAdminData.length > 0 &&
    //   state.superAdmin.superAdminData.find(e => e.page === "home"),
    refferal_code: state.userData.refferal_code
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
