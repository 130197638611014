import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getRequestedData = () => {
  try {
    const result = getAxiosInstance().get(`${API}getPendingRequest`);
    return result;
  } catch (error) {
    console.log(error);
  }
};
