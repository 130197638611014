import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    background: "#40414f",
    width: "70%",
    marginLeft: "15%"
  },
  input: {
    marginLeft: "5px",
    background: "#40414f",
    color: "#9ca3a6",
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  loader: {
    height: "40px",
    width: "40px"
  },
  divider: {
    height: 28,
    margin: 4
  },
  sendIcon: {
    cursor: "pointer"
  },
  attach: {
    cursor: "pointer"
  },
  chatfile: {
    display: "none"
  }
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const [question, setQuestion] = useState("");

  return (
    <Paper className={classes.root}>
      <IconButton
        color="primary"
        type="file"
        className={classes.attach}
        aria-label="directions"
        onClick={() => {
          document.getElementById("chatfile").click();
        }}
      >
        <AttachFileIcon color={"#2196f3"} />
        <input
          type="file"
          id="chatfile"
          style={{ display: "none" }}
          onChange={e => {
            var file = e.target.files;
            if (
              file &&
              file.length > 0 &&
              (file[0].type === "application/pdf" ||
                file[0].type === "text/csv" || file[0].type === "text/plain")
            ) {
              props.sendUserQuestionAction({
                id: props.selectedMessageId,
                document: file[0]
              });
            }
          }}
        ></input>
      </IconButton>
      <InputBase
        className={classes.input}
        value={question}
        onKeyDown={ev => {
          if (ev.key === "Enter") {
            // Do code here
            props.sendUserQuestionAction({
              id: props.selectedMessageId,
              que: question
            });
            setQuestion("");
            ev.preventDefault();
          }
        }}
        onChange={e => {
          setQuestion(e.target.value);
        }}
        placeholder="Type a message or type '/' to select a prompt... "
      />
      {props.waitingChat && (
        <IconButton
          color="primary"
          className={classes.loader}
          aria-label="directions"
        >
          <CircularProgress color="success" />
        </IconButton>
      )}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={() => {
          props.sendUserQuestionAction({
            id: props.selectedMessageId,
            que: question
          });
          setQuestion("");
        }}
      >
        <SendIcon className="SendIcon" />
      </IconButton>
    </Paper>
  );
}
