/* eslint-disable react/no-unescaped-entities */
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import RocketIllustration from "./Rocket";

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import draftToHtml from "draftjs-to-html";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { API } from "../../../../../../../utils/config";
import "../../../../../../LandingPage/Header.css";

import axios from "axios";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Matrix from "../../../../../Matrix";
import SliderImg from "../SliderImg";

const CardMediaWrapper = styled("div")({
  maxWidth: 720,
  margin: "0 208px",
  position: "relative",
});

const ErrorWrapper = styled("div")({
  maxWidth: 350,
  margin: "0 400px",
  textAlign: "center",
});

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CardMediaBlock = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "3s bounce ease-in-out infinite",
});

const CardMediaBlue = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "15s wings ease-in-out infinite",
});

const CardMediaPurple = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "12s wings ease-in-out infinite",
});

const Hero = (props) => {

  console.log(`${window.location.href}login`, ":::::::::::::::::")

  // const { themeMode } = props
  const theme = useTheme();

  const history = useHistory();
  const path = history.location.pathname;
  const initialValue = {
    twitter: "",
    idea: "",
    description: "",
    email: "",
    // ETHWallet: ""

    // idea: "",
    // twitter: "",
    // ETHWallet: "",
    // description: "",
  };
  const validationSchemaSite = Yup.object().shape({
    idea: Yup.string().required("required"),
    description: Yup.string().required("required"),
    email: Yup.string().required("required"),
  });

  const handleSubmit = async (values, prop) => {
    // dispatch(loginUserDataAction({ values: values, navigate: navigate }));
    prop.resetForm();

    const res = await axios.post(`${API}createLandingPageData`, values);
    if (res.status == 200) {
      props.messageAction({
        message:
          "Thanks for submitting! Our team will review it for offensiveness, and we'll notify you on Twitter within 24-48hrs if you pass. If you fail, we'll DM you with feedback, but it may take longer for us to respond if your site is extra spicy. Please note that if your site is blatantly offensive, we reserve the right to report it to the appropriate authorities.",
        status: "success",
      });
    }
  };

  const boxStyle = {
    // position: 'absolute',
    // top: '24%',
    // left: '80%',
    // position: "relative",
    // transform: 'translate(-50%, -50%)',
    width: "95%",
    height: "95%",
    background: "#F0F0F0",

    // border: "2px solid #000",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly ",
  };

  const useStyles = makeStyles(() => ({
    supplys: {
      height: "58px",
      border: "2px solid  ",
      width: "36%",
      display: "flex",
      color: theme.palette.mode === "light" && "#fff",
      background: "#111936",
      justifyContent: "center",
      marginLeft: "5px",
      marginRight: "5px",
      borderRadius: "10px",
    },
    dateWrapper: {
      paddingLeft: "10px",
      paddingRight: "10px",
      marginTop: "8px",
      display: "flex",
      justifyContent: "space-between",
      // width: "50%"
      width: "100%",
    },
    price: {
      fontSize: "15px",
      display: "flex",
      justifyContent: "center",
      height: "58px",
      width: "36%",
      padding: "5px",
      color: theme.palette.mode === "light" && "#fff",
      border: "2px solid ",
      borderRadius: "10px",
      background: "#111936",
    },
    itemswraper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: "#111936",
      borderRadius: "10px",
    },
    centeritem: {
      display: "flex",
      paddingLeft: "26%",
    },
    collectionWrap: {
      display: "flex",
      justifyContent: "center",
      height: "58px",
      width: "36%",
      border: "2px solid ",
      background: "#111936",
      color: theme.palette.mode === "light" && "#fff",
      borderRadius: "10px",
      textTransform: "capitalize",
    },
    pricewrap: {
      marginLeft: "4%",
      marginTop: "16px",
      display: "flex",
      alignItems: "center",
      // justifyContent: "center"
    },
    MintInput: {
      alignItems: "center",
      display: "flex",
      position: "relative",
    },
    mintBtnWrap: {
      display: "flex",
      fontSize: "20px",
      width: "110px",
      padding: "10px",
      justifyContent: "space-between",
      background: "#111936",
      border: "2px solid ",
      color: theme.palette.mode === "light" && "#fff",
      borderRadius: "30px",
    },
    btnMint: {
      cursor: "pointer",
      display: "inline-block",
      color: theme.palette.mode === "light" && "#fff",
    },
    mintBtn: {
      background: "#111936",
      color: theme.palette.mode === "light" && "#fff",
      fontSize: "16px",
      width: "84px",
      marginLeft: "5%",
      zIndex: 1,
    },
    mintNo: {
      fontSize: "16px",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      width: "25%",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    centerForGallery: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    left: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 500px)": {
        flexDirection: "column",
      },
    },
    right: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 500px)": {
        flexDirection: "column",
      },
    },
    columnCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    rowFlex: {
      display: "flex important",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowFlexImg: {
      width: "100%",
      height: "100%",
      margin: "0px 20px",
    },
    columnCenterWidth: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "60%",
      "@media (max-width: 500px)": {
        width: "80%",
      },
    },
    columnCenterWidthForGalleryView: {
      width: "100%",
    },
    columnCenterRight: {
      marginLeft: "50px",
    },
    columnCenterImg: {
      height: "100%",
      width: "100%",
      marginTop: "58px",
    },
    maxWidthForContainer: {
      maxWidth: "100%",
    },
    startupBox: {
      background:
        "linear-gradient(90deg, rgba(59,205,159,1) 13%, rgba(139,208,175,1) 18%, rgba(234,212,195,1) 28%, rgba(110,154,202,1) 41%, rgba(172,100,183,1) 54%, rgba(208,57,135,1) 66%, rgba(159,122,218,1) 83%, rgba(127,141,246,1) 100%);",
      // background: " rgb(120, 92, 71)",
      // backgroundColor: "blue"
    },
    form: {
      // background: "blue",
      width: "100%",
    },
  }));

  console.log(
    "props.data?.properties.header.displayType",
    props.data?.properties.header.displayType
  );

  let centerDisplay = props.data?.properties.header.displayType == "center";
  let rightDisplay = props.data?.properties.header.displayType == "right";
  let leftDisplay = props.data?.properties.header.displayType == "left";
  let galleryDisplay = props.data?.properties.header.displayType == "gallery";
  let matrixDisplay = props.data?.properties.header.displayType == "matrix";

  let checkAddress = window.location.href;
  let check = checkAddress.includes("customLandingPage");

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const classes = useStyles();
  const [mint, setMint] = React.useState(1);
  const [mintNo, setMintNo] = React.useState(0);

  // TODO: Replace with common getMintedNFT

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <>
      {props.data ? (
        <Grid container>
          <div
            className={
              rightDisplay
                ? classes.left
                : galleryDisplay
                  ? classes.centerForGallery
                  : centerDisplay
                    ? classes.center
                    : leftDisplay
                      ? classes.right
                      : classes.right
            }
          >
            {matrixDisplay ? (
              <Matrix data={props.data} />
            ) : (
              <div
                className={
                  rightDisplay || leftDisplay
                    ? classes.columnCenterWidth
                    : galleryDisplay
                      ? classes.columnCenterWidthForGalleryView
                      : ""
                }
              >
                <div
                  className={
                    centerDisplay || galleryDisplay ? classes.columnCenter : ""
                  }
                >
                  <div className={!centerDisplay ? classes.columnCenter : ""}>
                    <Box
                      data-aos={check ? null : isMd ? "fade-right" : "fade-up"}
                      className={rightDisplay ? classes.columnCenterRight : ""}
                    >
                      <Box marginBottom={2}>
                        <Typography
                          className={
                            centerDisplay || galleryDisplay
                              ? classes.columnCenter
                              : ""
                          }
                          variant="h2"
                          color="textPrimary"
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          {props.data &&
                            props.data.properties.header.title.value}
                          <br />
                          <Typography
                            color={"primary"}
                            component={"span"}
                            variant={"inherit"}
                            className={
                              centerDisplay || galleryDisplay
                                ? classes.columnCenter
                                : ""
                            }
                          >
                            {props.data &&
                              props.data.properties.header.title.value1}
                            <br />
                            <Typography
                              variant="h2"
                              color="textPrimary"
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              {props.data &&
                                props.data.properties.header.title.value2}
                            </Typography>
                          </Typography>
                        </Typography>
                      </Box>

                      {
                        <div
                          className={
                            centerDisplay || galleryDisplay
                              ? classes.columnCenter
                              : ""
                          }
                        >
                          <Box marginBottom={3}>
                            <Typography
                              variant="h6"
                              component="p"
                              color="textSecondary"
                              sx={{ fontWeight: 400 }}
                            >
                              <div
                                className={
                                  centerDisplay || galleryDisplay
                                    ? classes.columnCenter
                                    : ""
                                }
                                dangerouslySetInnerHTML={{
                                  __html: props.data
                                    ? draftToHtml(
                                      JSON.parse(
                                        props.data.properties.header
                                          .description
                                      )
                                    )
                                    : "Header Discription",
                                }}
                              ></div>
                            </Typography>
                          </Box>
                        </div>
                      }
                      <div
                        className={
                          centerDisplay || galleryDisplay
                            ? classes.columnCenter
                            : ""
                        }
                      >
                        <Box
                          display="flex"
                          flexDirection={{
                            xs: "column",
                            sm: "row",
                          }}
                          alignItems={{
                            xs: "stretched",
                            sm: "flex-start",
                          }}
                          style={{ marginTop: "20px" }}
                        >
                          {props.data &&
                            props.data.properties.header.discord &&
                            props.data.properties.header.discord.text && (
                              <Button
                                component={"a"}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth={isMd ? false : true}
                                target={"_blank"}
                                onClick={() => {
                                  window.open(
                                    `${window.location.href}login`
                                  );
                                }}
                              >
                                {props.data &&
                                  props.data.properties.header.discord
                                  ? props.data.properties.header.discord.text
                                  : "Discord"}
                              </Button>
                            )}
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            )}
            <Grid item xs={12} md={6}>
              <div
                className={
                  centerDisplay
                    ? classes.columnCenterImg
                    : leftDisplay
                      ? classes.columnCenterRight
                      : ""
                }
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flex={"row"}
                  height={"80%"}
                  width={"100%"}
                  maxHeight={600}
                  marginTop={"10px"}
                >
                  {!galleryDisplay ? (
                    props.data &&
                      props.data.properties.header.enableCustomImage &&
                      props.data.properties.header.customImageUrl &&
                      !matrixDisplay ? (
                      <>
                        <img
                          style={{
                            marginTop: "45px",
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          src={props.data.properties.header.customImageUrl}
                        />
                      </>
                    ) : (
                      <div>
                        {path === "/" && window.location.host == " " ? (
                          <div
                            className={classes.startupBox}
                            style={{
                              width: "100%",
                              // height: "70vh",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box className={classes.boxStyle} sx={boxStyle}>
                              <div style={{ width: "100%" }}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h4"
                                  component="h1"
                                  sx={{
                                    textAlign: "center",
                                    color: "black",
                                  }}
                                >
                                  What's your startup idea?
                                </Typography>

                                {/* <Box sx={{ width: "100%" }}> */}
                                <Formik
                                  initialValues={initialValue}
                                  validationSchema={validationSchemaSite}
                                  enableReinitialize
                                  onSubmit={handleSubmit}
                                  sx={{ width: "100%" }}
                                >
                                  {(props) => {
                                    const { name } = props.values;
                                    return (
                                      <Form
                                        className={classes.form}
                                      // style={{ width: "100%" }}
                                      >
                                        {/* First Way */}
                                        <Field
                                          as={TextField}
                                          // label="Idea"
                                          placeholder="Idea"
                                          name="idea"
                                          fullWidth
                                          variant="outlined"
                                          margin="dense"
                                          value={props.values.idea}
                                          inputProps={{
                                            sx: {
                                              border: "1px solid black",
                                            },
                                          }}
                                          helperText={
                                            <ErrorMessage name="idea" />
                                          }
                                          error={
                                            props.errors.idea &&
                                            props.touched.idea
                                          }
                                        />

                                        <Field
                                          as={TextField}
                                          variant="outlined"
                                          fullWidth
                                          sx={{
                                            mb: 2,
                                          }}
                                          inputProps={{
                                            sx: {
                                              border: "1px solid black",
                                              height: "3rem",
                                            },
                                          }}
                                          // maxRows={5}
                                          // rows={4}
                                          name="description"
                                          color="secondary"
                                          placeholder="Startup Description"
                                          value={props.values.description}
                                          helperText={
                                            <ErrorMessage name="description" />
                                          }
                                          error={
                                            props.errors.description &&
                                            props.touched.description
                                          }
                                        // multiline
                                        />

                                        <div
                                          style={{
                                            // border: "1px solid black",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",

                                            // padding: "15px"
                                          }}
                                        >
                                          {/* email */}

                                          <Field
                                            as={TextField}
                                            placeholder="Email"
                                            name="email"
                                            fullWidth
                                            inputProps={{
                                              sx: {
                                                border: "1px solid black",
                                              },
                                            }}
                                            value={props.values.email}
                                            variant="outlined"
                                            // margin="dense"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            helperText={
                                              <ErrorMessage name="email" />
                                            }
                                            error={
                                              props.errors.email &&
                                              props.touched.email
                                            }
                                          />

                                          {/* Second Way */}
                                          <Field
                                            sx={{
                                              marginLeft: "6px",
                                            }}
                                            as={TextField}
                                            placeholder="Twitter Handle"
                                            name="twitter"
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                              sx: {
                                                border: "1px solid black",
                                              },
                                            }}
                                            // margin="dense"
                                            value={props.values.twitter}
                                            helperText={
                                              <ErrorMessage name="twitter" />
                                            }
                                            error={
                                              props.errors.twitter &&
                                              props.touched.twitter
                                            }
                                          />
                                        </div>

                                        <div
                                          style={{
                                            width: "40%",
                                            margin: "auto",
                                          }}
                                        >
                                          <LoadingButton
                                            // loading={loader}
                                            sx={{
                                              backgroundColor: "#f1f1f1",
                                              color: "black",
                                              marginTop: "1rem",
                                            }}
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            fullWidth
                                          >
                                            Generate Site
                                          </LoadingButton>
                                        </div>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                                {/* </Box> */}
                              </div>
                            </Box>
                          </div>
                        ) : (
                          <>
                            {!matrixDisplay && (
                              <RocketIllustration
                                width={"100%"}
                                height={"100%"}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )
                  ) : (
                    <SliderImg />
                  )}
                </Box>
                {/* </Box> */}
                {/* {centerDisplay || galleryDisplay ? (
                  <>
                    <Box>
                      {props.nftData && (
                        <div
                          className={galleryDisplay ? "galleryViewMintBox" : ""}
                        >
                          <div className={classes.dateWrapper}>
                            <div className={classes.price}>
                              <div className={classes.itemswraper}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  Price
                                </div>
                                <div>
                                  {" "}
                                  {props.nftData.nftContract.price}
                                  {(props.nftData.nftContract.network ==
                                    "polygon" ||
                                    props.nftData.nftContract.network ==
                                    "polygon_mainnet") &&
                                    props.nftData.nftContract.token == "matic"
                                    ? "  MATIC"
                                    : props.nftData.nftContract.token == "weth"
                                      ? " CLM"
                                      : " ETH"}{" "}
                                </div>
                              </div>
                            </div>
                            <div className={classes.supplys}>
                              <div className={classes.itemswraper}>
                                <div>Total Items</div>
                                <div className={classes.centeritem}>
                                  {" "}
                                  {props.nftData.nftContract.supply}
                                </div>
                              </div>
                            </div>
                            <div className={classes.collectionWrap}>
                              <div className={classes.itemswraper}>
                                {props.nftData.nftContract.network ==
                                  "polygon_mainnet"
                                  ? "Polygon"
                                  : props.nftData.nftContract.network}
                              </div>
                            </div>
                          </div>
                          <div className={classes.pricewrap}>
                            <div className={classes.MintInput} id="noselect">
                              <div className={classes.mintBtnWrap}>
                                <div
                                  className={classes.btnMint}
                                  onClick={() => {
                                    if (mint > 1) {
                                      setMint(mint - 1);
                                    }
                                  }}
                                >
                                  {" "}
                                  -{" "}
                                </div>
                                <div>{mint}</div>
                                <div
                                  className={classes.btnMint}
                                  onClick={() => {
                                    setMint(mint + 1);
                                  }}
                                >
                                  {" "}
                                  +{" "}
                                </div>
                              </div>
                            </div>
                            <Button
                              className={classes.mintBtn}
                              onClick={handleConnect}
                            >
                              {props.headerAccount && props.headerAccount[0]
                                ? "Mint"
                                : "Connect"}
                            </Button>
                          </div>
                          <div className={classes.mintNo}>
                            {mintNo}/{props.nftData.nftContract.supply}
                          </div>
                        </div>
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </Grid>
          </div>

          {props.data && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              {props.data && props.data.properties.header.youtube && (
                <iframe
                  title="Inline Frame Example"
                  width="80%"
                  height="500"
                  src={`${props.data.properties.header.youtube
                    }?autoplay=1&playlist=${props.data.properties.header.youtube.split("/")[
                    props.data.properties.header.youtube.split("/").length - 1
                    ]
                    }&loop=1`}
                ></iframe>
              )}
            </div>
          )}
        </Grid>
      ) : (
        //   )}
        // </>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "hidden",
          }}
        >
          <CardContent>
            <Grid
              container
              justifyContent="center"
            // spacing={gridSpacing}
            >
              <Grid item xs={12}>
                <CardMediaWrapper>
                  <CardMedia
                    component="img"
                    image={
                      theme.palette.mode === "dark"
                        ? "https://theme404.s3.us-west-2.amazonaws.com/img-error-bg-dark.svg"
                        : "https://theme404.s3.us-west-2.amazonaws.com/img-error-bg.svg"
                    }
                    title="Slider5 image"
                  />
                  <CardMediaBlock
                    src={
                      "https://theme404.s3.us-west-2.amazonaws.com/img-error-text.svg"
                    }
                    title="Slider 1 image"
                  />
                  <CardMediaBlue
                    src={
                      "https://theme404.s3.us-west-2.amazonaws.com/img-error-blue.svg"
                    }
                    title="Slider 2 image"
                  />
                  <CardMediaPurple
                    src={
                      "https://theme404.s3.us-west-2.amazonaws.com/img-error-purple.svg"
                    }
                    title="Slider 3 image"
                  />
                </CardMediaWrapper>
              </Grid>
              <Grid item xs={12}>
                <ErrorWrapper>
                  <Grid
                    container
                  // spacing={gridSpacing}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div">
                        Something is wrong
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        The page you are looking was moved, removed, renamed, or
                        might never exist!{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </ErrorWrapper>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      )}
    </>
  );
};

export default Hero;
