import { Backdrop, CircularProgress } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestForPolicy } from "../CreatePolicies/Redux/action";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";
import {
  getRequestedPendingPoliciesData,
  setRequestPendingLoader
} from "./Redux/action";

function RequestedPendingPolicies() {
  let hostname = window.location.hostname;
  let host = window.location.origin;

  const messages = useSelector(state => state.userData.message);
  const data = useSelector(
    state => state.ReuestedPendingPoliciesReducer.requestedPolicies
  );
  const loading = useSelector(
    state => state.ReuestedPendingPoliciesReducer.loader
  );
  const userData = useSelector(state => state.userData.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(getRequestedPendingPoliciesData());
    }
  }, [userData]);

  const userRequestForPolicy = data => {
    dispatch(setRequestPendingLoader(true));
    let userRequestData = {
      policyId: data.policyId,
      policyName: data.policyName,
      AssignVoter: data.assignUser,
      host,
      hostname
    };
    dispatch(requestForPolicy({ userRequestData }));
  };

  const columns = [
    { field: "policyId", headerName: "Policy Id", width: 320 },
    { field: "policyName", headerName: "Policy Name", width: 150 },
    { field: "Status", headerName: "Status", width: 120 },
    { field: "CreatedOn", headerName: "Created on", width: 180 },
    {
      field: "policyDescription",
      headerName: "Policy Description",
      width: 200
    },
    {
      field: "actions",
      headerName: "Action",
      width: 150,
      renderCell: cellValues => (
        <>
          {cellValues?.row?.statusCheck === "PENDING" ? (
            <span>PENDING</span>
          ) : (
            <Button
              variant="contained"
              onClick={() => userRequestForPolicy(cellValues.row)}
            >
              Request
            </Button>
          )}
        </>
      )
    }
  ];

  const rows = data?.data?.map(e => {
    return {
      id: e._id,
      policyId: e.policyId,
      policyName: e.policyName,
      CreatedOn: moment(e.createdAt).format("MM-DD-YYYY HH:mm:ss"),
      Status: e.Status,
      policyDescription: e.policyDescription,
      assignUser: e.assignUser,
      statusCheck: e.myStatus
    };
  });
  if (!loading) {
    if (data?.data?.length) {
      return (
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            unstable_cellSelection
            disableColumnMenu
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5
                }
              }
            }}
            pageSizeOptions={[5, 10, 25]}
            disableSelectionOnClick
            getRowHeight={() => "auto"}
            loading={loading}
          />
          <ToastMsg
            toastMessage={messages}
            open={true}
            close={() => {
              dispatch(messageDataAction({}));
            }}
          />
        </Box>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          You have no requested policy
        </div>
      );
    }
  } else {
    return (
      <div>
        <Backdrop
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      </div>
    );
  }
}
export default RequestedPendingPolicies;
