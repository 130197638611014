import { call, put, select, takeEvery } from "redux-saga/effects";
// import { messageAction } from "../../LandingPage/actions";
import { messageDataAction } from "../../Login/actions";
import {
  setRequestPendingLoader,
  setRequestedData,
} from "../../RequestedPendingPolicies/Redux/action";
import {
  setAllPolicy,
  setAllPolicyUsers,
  setAllStripeAccounts,
  setCreatePolicyLoader,
  setCreatePolicyModal,
  setDeleteModal,
  setPolicyDocument,
  setSubmitButtonLoader,
  setUserApprovalRequests,
} from "./action";
import {
  assignUserApi,
  createPolicyApi,
  createPolicyStripeApi,
  deletePolicyApi,
  getAllPolicyApi,
  getAllStripeAccountsApi,
  getUserApprovalForPolicies,
  getallpolicyusersapi,
  requestForPolicyByUserApi,
} from "./api";
import {
  ASSIGN_USER_BY_POLICY_ADMIN,
  CREATE_NEW_POLICY,
  DELETE_POLICY,
  GET_ALL_POLICY,
  GET_ALL_POLICY_USERS,
  GET_ALL_STRIPE_ACCOUNTS,
  GET_USER_APPROVAL_RESPONSE_DATA,
  REQUEST_FOR_POLICY_BY_USER,
} from "./constants";

function* getAllPolicyUsers(action) {
  try {
    const response = yield call(getallpolicyusersapi, action.payload);
    if (response.status === 200) {
      yield put(setAllPolicyUsers(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getAllPolicy(action) {
  try {
    const response = yield call(getAllPolicyApi);
    if (response.status === 200) {
      yield put(setAllPolicy(response.data.data));

      yield put(setCreatePolicyLoader(false));
    }
    yield put(setCreatePolicyLoader(false));
  } catch (e) {
    yield put(setCreatePolicyLoader(false));
    console.log(e);
  }
}

function* createPolicySaga(action) {
  try {
    const state = yield select();

    let assignedUsers = action.payload.assignedUsers.map((e) => e.email);

    let userWalletsAndKey = action.payload.assignedUsers
      .filter((e) => e.email !== action.payload.creator)
      .map((e) => {
        return { walletAddress: e.walletAddress, privateKey: e.privateKey };
      });

    if (action.payload.selectedOption === "stripe") {
      let data = {
        policyName: action.payload.policyName,
        policyDescription: action.payload.policyDescription,
        assignedUsers: action.payload.assignedUsers,
        accountId: action.payload.accountId,
        amount: action.payload.amount,
      };

      const response = yield call(createPolicyStripeApi, data);

      if (response.status === 200) {
        // yield put(setSubmitButtonLoader(false));
        // yield put(setCreatePolicyModal(false));
        // yield put(setPolicyDocument(null));

        const newPolicyAdd = [
          response.data.data,
          ...state.CreatePolicesReducer.policies,
        ];

        yield put(setAllPolicy(newPolicyAdd));

        yield put(
          messageDataAction({
            message: "Policy Created",
            status: "success",
          }),
        );

        // yield delay(1000);

        // window.location.reload();

        yield put(setSubmitButtonLoader(false));
        yield put(setCreatePolicyModal(false));
      }

      yield put(setPolicyDocument(null));
    } else {
      const form = new FormData();
      form.append("policyName", action.payload.policyName);
      form.append("policyDescription", action.payload.policyDescription);
      form.append("doc", action.payload.document);
      form.append("assignedUsers", JSON.stringify(assignedUsers));
      form.append("accountID", action.payload.accountID);
      form.append("amount", action.payload.amount);

      form.append("userWalletsAndKey", JSON.stringify(userWalletsAndKey));

      const response = yield call(createPolicyApi, form);

      if (response.status === 200) {
        // yield put(setSubmitButtonLoader(false));
        // yield put(setCreatePolicyModal(false));
        // yield put(setPolicyDocument(null));

        const newPolicyAdd = [
          response.data.data,
          ...state.CreatePolicesReducer.policies,
        ];

        yield put(setAllPolicy(newPolicyAdd));

        yield put(
          messageDataAction({
            message: "Policy Created",
            status: "success",
          }),
        );

        // yield delay(1000);

        // window.location.reload();

        yield put(setSubmitButtonLoader(false));
        yield put(setCreatePolicyModal(false));
      }

      yield put(setPolicyDocument(null));
    }
  } catch (e) {
    yield put(setSubmitButtonLoader(false));
    yield put(setCreatePolicyModal(false));

    yield put(setPolicyDocument(null));
    yield put(
      messageDataAction({
        message: "Something Went Wrong",
        status: "error",
      }),
    );
  }
}

function* deletePolicySaga(action) {
  try {
    const state = yield select();
    let data = {
      id: action.payload.deletePolicyId,
    };

    const response = yield call(deletePolicyApi, data);

    if (response.status === 200) {
      const array = state.CreatePolicesReducer.policies.filter(
        (e) => e._id !== action.payload.deletePolicyId,
      );

      yield put(setAllPolicy(array));
      yield put(setDeleteModal(false));
      yield put(
        messageDataAction({
          message: "Deleted Successfully",
          status: "success",
        }),
      );
    }
  } catch (e) {
    yield put(setDeleteModal(false));
    console.log(e);
  }
}

function* assignUserSaga(action) {
  try {
    let data = {
      id: action.payload.id,
      users: action.payload.assignUserData,
      hostname: action.payload.hostname,
      host: action.payload.host,
      policyId: action.payload.policyId,
      policyName: action.payload.policyName,
    };
    const response = yield call(assignUserApi, data);
    if (response.status === 200) {
      yield put(
        messageDataAction({
          message: "User assigned",
          status: "success",
        }),
      );
    }
    if (response.status === 400) {
      yield put(
        messageDataAction({
          message: "Something Went Wrong",
          status: "error",
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestForPolicyByUser(action) {
  const state = yield select();

  try {
    let data = {
      policyId: action.payload.userRequestData.policyId,
      policyName: action.payload.userRequestData.policyName,
      assignVoter: action.payload.userRequestData.AssignVoter,
      host: action.payload.userRequestData.host,
      hostname: action.payload.userRequestData.hostname,
    };

    const response = yield call(requestForPolicyByUserApi, data);

    if (response.data) {
      yield put(setRequestPendingLoader(false));

      let changeData =
        state.ReuestedPendingPoliciesReducer.requestedPolicies.data.map(
          (policy) => {
            if (policy.policyId === action.payload.userRequestData.policyId) {
              return { ...policy, myStatus: "PENDING" };
            } else {
              return policy;
            }
          },
        );

      yield put(
        setRequestedData({
          ...state.ReuestedPendingPoliciesReducer.requestedPolicies,
          data: changeData,
        }),
      );

      yield put(
        messageDataAction({
          message: "Request has been sended to voters",
          status: "success",
        }),
      );
    }
    if (response.error) {
      yield put(setRequestPendingLoader(false));
      yield put(
        messageDataAction({
          message: "You have already requested for this policy",
          status: "error",
        }),
      );
    }
  } catch (error) {
    yield put(setRequestPendingLoader(false));
    console.log(error);
  }
}

function* getUserApprovalRequests() {
  try {
    const response = yield call(getUserApprovalForPolicies);
    if (response.status === 200) {
      yield put(setUserApprovalRequests(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getAllStripeAccountsSaga() {
  try {
    const response = yield call(getAllStripeAccountsApi);
    if (response.status === 200) {
      yield put(setAllStripeAccounts(response.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* createPoliciesSaga() {
  yield takeEvery(GET_ALL_POLICY_USERS, getAllPolicyUsers);
  yield takeEvery(GET_ALL_POLICY, getAllPolicy);

  yield takeEvery(CREATE_NEW_POLICY, createPolicySaga);
  yield takeEvery(DELETE_POLICY, deletePolicySaga);
  yield takeEvery(ASSIGN_USER_BY_POLICY_ADMIN, assignUserSaga);
  yield takeEvery(REQUEST_FOR_POLICY_BY_USER, requestForPolicyByUser);
  yield takeEvery(GET_USER_APPROVAL_RESPONSE_DATA, getUserApprovalRequests);
  yield takeEvery(GET_ALL_STRIPE_ACCOUNTS, getAllStripeAccountsSaga);
}

export default createPoliciesSaga;
