import {
  GET_REQUESTED_PENDING_POLICIES_DATA,
  SET_LOADER_REQUEST_POLICY,
  SET_REQUESTED_POLICY_DATA
} from "./constant";

export const getRequestedPendingPoliciesData = value => {
  return {
    type: GET_REQUESTED_PENDING_POLICIES_DATA,
    payload: value
  };
};

export const setRequestedData = value => {
  return {
    type: SET_REQUESTED_POLICY_DATA,
    payload: value
  };
};

export const setRequestPendingLoader = value => {
  return {
    type: SET_LOADER_REQUEST_POLICY,
    payload: value
  };
};
