import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../../images/rocket-joypixels.gif";
import AuthCardWrapper from "../Login/CardWrapper/AuthCardWrapper";
import AuthWrapper1 from "../Login/CardWrapper/AuthWrapper1";
import { messageDataAction } from "../Login/actions";
import ToastMsg from "../SnackBar";
import { signupRegister, toPaymentCheckOut } from "./actions";
import AuthRegister from "./signup";


const SignUp = props => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const closeSnackBar = () => {
    props.messageDataAction({});
  };

  const history = useHistory();
  return (
    <AuthWrapper1>
      <ToastMsg
        toastMessage={props.message}
        open={true}
        close={closeSnackBar}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <img src={logo} width="50px" height={"50px"} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Sign up
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthRegister
                      signupRegister={props.signupRegister}
                      messageDataAction={props.messageDataAction}
                      toPaymentCheckOut={props.toPaymentCheckOut}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      xs={12}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        Already have an account?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    signupRegister: values => {
      dispatch(signupRegister(values));
    },
    messageDataAction: data => {
      dispatch(messageDataAction(data));
    },
    toPaymentCheckOut: value => {
      dispatch(toPaymentCheckOut(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    message: state.userData.message
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
