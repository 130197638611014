import { Auth } from "aws-amplify";
import { getAxiosInstance } from "./utils/axios";

export const signInUserApi = async (values) => {
  try {
    const data = await Auth.signIn(values.username, values.password)
      .then((user) => {
        return {
          status: 200,
          data: user,
        };
      })
      .catch((err) => {
        return {
          status: 400,
          data: err,
        };
      });
    return data;
  } catch (err) {
    return err;
  }
};

export const registerUser = async (values) => {
  try {
    const data = await Auth.signUp({
      username: values.username,
      password: values.password,
      attributes: {
        given_name: values.attributes.given_name,
        family_name: values.attributes.family_name,
      },
    });

    return data;
  } catch (err) {
    return { err };
  }
};

export const reSendMailApi = async (values) => {
  let res = await Auth.resendSignUp(values);
};

export const verifyUserApi = async (values) => {
  try {
    const data = await Auth.confirmSignUp(values.username, values.code, {
      forceAliasCreation: true,
    });
    return data;
  } catch (err) {
    return { err };
  }
};

export const forgotPasswordApi = async (values) => {
  try {
    const data = await Auth.forgotPassword(values.username);
    return data;
  } catch (err) {
    return err;
  }
};

export const forgotPasswordSubmitApi = async (values) => {
  try {
    const data = await Auth.forgotPasswordSubmit(
      values.emailId,
      values.code,
      values.password
    );
    return data;
  } catch (err) {
    return { err };
  }
};
export const editprofileApi = async (values) => {
  try {
    let res = await getAxiosInstance().post(
      `http://localhost:5000/updateUserData`,
      values
    );
    return res;
  } catch (err) {
    return { err };
  }
};
export const ChangePassApi = async (values) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(
      user,
      values.oldPassword,
      values.newPassword
    );

    return data;
  } catch (err) {
    return { err };
  }
};
