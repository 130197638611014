import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Auth } from "aws-amplify";
import React, { memo } from "react";
import { connect } from "react-redux";
import { Route, useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  getprofiledata,
  setUserWalletAddress,
  verifyeditdata,
  waitingLoader
} from "./components/Account/actions";
import { isUserAssignPolicy } from "./components/CreatePolicies/Redux/action";
import { postAction } from "./components/Header/actions";
import HeaderLayout from "./components/HeaderLayout/layout/MainLayout/index";
import {
  postLoginSuccess,
  setRefferalCode,
  userDataAction
} from "./components/Login/actions";
// import { setEditDataAction } from "./components/PrepareArtwork/actions";
// import {
//   prepareArtworkIdAction,
//   setQuickMinterDataAction
// } from "./components/QuickMinter/actions";
import { toPaymentCheckOut } from "./components/SignUp/actions";
// import { superAdminGetAction } from "./components/SuperAdmin/actions";
// import { fetchProfileActions } from "./components/profile/Redux/action";
import { getAxiosInstance, setToken } from "./utils/axios";
import { API } from "./utils/config";
const PrivateRoute = props => {
  const history = useHistory();
  const authenticateUser = async () => {
    try {
      let getCurrentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      return getCurrentAuthenticatedUser;
    } catch (err) {
      console.log("err", err);
      return undefined;
    }
  };

  // useEffect(() => {
  //   if (props.path !== "/addQuickMinter") {
  //     props.setQuickMinterDataAction("");
  //     props.prepareArtworkIdAction("");
  //     props.setEditDataAction("");
  //   }
  // }, [props.path]);

  React.useEffect(async () => {
    if (!props.userData) {
      props.waitingLoader(true);
      await authenticateUser()
        .then(async getCurrentAuthenticatedUser => {
          let isAdmin = false;

          if (getCurrentAuthenticatedUser) {
            setToken(
              getCurrentAuthenticatedUser.signInUserSession.idToken.jwtToken
            );
            // const data = {
            //   userId: getCurrentAuthenticatedUser.attributes.sub,
            //   email: getCurrentAuthenticatedUser.attributes.email,
            //   given_name:
            //     getCurrentAuthenticatedUser.attributes.given_name || "",
            //   family_name:
            //     getCurrentAuthenticatedUser.attributes.family_name || "",
            // };
            // if (!props.update_data) {
            //   props.verifyeditdata({data:data,history:history});
            // }
            let data = await getAxiosInstance().post(`${API}verifyUser`, {
              userId: getCurrentAuthenticatedUser.username,
              email: getCurrentAuthenticatedUser.attributes.email,
              given_name:
                getCurrentAuthenticatedUser.attributes.given_name || "",
              family_name:
                getCurrentAuthenticatedUser.attributes.family_name || ""
            });

            if (data.status === 200 && data.data.message === "OK") {
              let res = await getAxiosInstance().get(`${API}is_user_invited`);
              let policyassigndata = res.data;
              props.isUserAssignPolicy({ data: policyassigndata });

              if (data?.data?.walletAddress) {
                props.setUserWalletAddress(data.data.walletAddress);
              }

              props.waitingLoader(false);
              if (
                data.data.data.role === "profileuser" &&
                window.location.hostname !== "polyadmin.com" &&
                window.location.hostname !== "localhost"
              ) {
                props.userDataAction(getCurrentAuthenticatedUser.attributes);
                history.push("/editprofile");
              } else {
                if (data.data.data.role == "admin") {
                  isAdmin = true;
                }
                if (props.path !== "/addQuickMinter") {
                  props.userDataAction(getCurrentAuthenticatedUser.attributes);
                }
              }

              props.getprofiledata(data.data.data);
              props.postLoginSuccess({
                userId: getCurrentAuthenticatedUser.username,
                isAdmin
              });

              props.setRefferalCode(data.data.data.referral_id);
            } else {
              props.waitingLoader(false);
              history.push("/");
            }
          } else {
            props.waitingLoader(false);
            history.push("/");
          }
        })
        .catch(err => {
          props.waitingLoader(false);
          console.log(err);
          history.push("/");
        });
    } else {
      props.waitingLoader(false);
    }
  }, []);

  const { ...rest } = props;
  return (
    <>
      {props.loader ? (
        <div>
          <Backdrop
            sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      ) : (
        <div>
          {props.location.pathname !== "/customLandingPage" &&
            props.location.pathname !== "/ChatGroup" &&
            props.location.pathname !== "/indexdeveloperTheme" &&
            props.location.pathname !== "/editprofile" ? (
            <HeaderLayout
              componentToPassDown={
                <Route
                  {...rest}
                  render={props => (
                    <>
                      <React.Component {...props} />
                    </>
                  )}
                />
              }
            />
          ) : (
            <Route
              {...rest}
              render={props => (
                <>
                  <React.Component {...props} />
                </>
              )}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    postAction: values => {
      dispatch(postAction(values));
    },
    verifyeditdata: values => {
      dispatch(verifyeditdata(values));
    },
    userDataAction: values => {
      dispatch(userDataAction(values));
    },
    waitingLoader: values => {
      dispatch(waitingLoader(values));
    },
    postLoginSuccess: values => {
      dispatch(postLoginSuccess(values));
    },
    toPaymentCheckOut: value => {
      dispatch(toPaymentCheckOut(value));
    },
    getprofiledata: value => {
      dispatch(getprofiledata(value));
    },
    setRefferalCode: value => {
      dispatch(setRefferalCode(value));
    },
    isUserAssignPolicy: value => {
      dispatch(isUserAssignPolicy(value));
    },
    setUserWalletAddress: value => {
      dispatch(setUserWalletAddress(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.userData.user || { userId: "", isAdmin: false },
    userData: state.userData.userData,
    update_data: state.accountData.data,
    loader: state.accountData.loader
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(withRouter(PrivateRoute));
