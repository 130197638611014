import { LOADER_BUTTON_ACTION, PAYMENT_PAGE_REDIRECT, SIGNUP_EMAIL } from "./constant";
const defaultState = {
  email: '',
  temporaryValue: '',
  loaderbutton: false,
};

const signupReducer = (state = defaultState, action) => {

  switch (action.type) {
    case SIGNUP_EMAIL:
      return { ...state, email: action.payload };

    case PAYMENT_PAGE_REDIRECT:
      return { ...state, temporaryValue: action.payload };

    case LOADER_BUTTON_ACTION:
      return { ...state, loaderbutton: action.payload }

    default:
      return state;
  }
};

export default signupReducer;
