import { makeStyles } from "@material-ui/styles";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import FileViewer from "react-file-viewer";
import { IoArrowBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getRequestUserData,
  setReviewUserLoader,
  userResponsAction,
} from "./redux/action";
import "./style.css";

function ReviewUsers() {
  const [pdfDataHandling, setpdfDataHandling] = React.useState({});

  const data = useSelector((state) => state.ReviewUserReducer.requestUserData);

  const userData = useSelector((state) => state.userData.userData);
  const isLoader = useSelector(
    (state) => state.ReviewUserReducer.loaderReviewUser,
  );

  const email = window.location.pathname.split("/")[3];
  const nameOrMail = data?.userFirstName
    ? `${data?.userFirstName} ${data?.userLastName}`
    : email;
  const id = window.location.pathname.split("/")[2];
  const policyName = window.location.pathname.split("/")[4];

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let docType = data?.policyDoc?.split(".")[5];
    setpdfDataHandling({
      pdflink: data?.policyDoc,
      policyName: data?.policyName,
      policyDescription: data?.policyDescription,
      docType,
    });
  }, [data]);

  useEffect(() => {
    if (userData) {
      dispatch(getRequestUserData({ id, email, history }));
    }
  }, [userData]);

  const goBackFunc = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/createPolicy");
    }
  };

  const handleResponse = (data) => {
    dispatch(
      userResponsAction({
        voterResponse: data,
        policyId: id,
        user: email,
        policyName: policyName,
        hostname: window.location.hostname,
        host: window.location.origin,
        history,
      }),
    );
  };

  const useStyles = makeStyles(() => ({
    mainContainer: {
      width: "100%",
      height: "78vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      gap: "10px",
      marginLeft: "10px",
      margin: "auto",
    },
    btns: {
      display: "flex",
      flexDirection: "column",
    },
    approveAndDenyBtn: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "54%",
      cursor: "pointer",
      // maxWidth: "450px",
      alignItems: "center",
      gap: "20px",
    },
    nameAndDescription: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "15px",
      height: "15%",
    },
    backArrow: {
      position: "relative",
      top: "50px",
      left: "10px",
      fontSize: "20px",
      cursor: "pointer",
      height: "20px",
      width: "20px",
    },
  }));

  const classes = useStyles();

  if (isLoader) {
    return (
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress />
        </Backdrop>
      </div>
    );
  } else {
    if (data.userResponse === "PENDING") {
      return (
        <div className={classes.mainContainer}>
          <span
            className={classes.backArrow}
            onClick={() => {
              goBackFunc();
            }}
          >
            <IoArrowBackSharp />
          </span>

          <div className={classes.nameAndDescription}>
            <p
              style={{
                fontSize: "20px",
              }}
            >{`User ${nameOrMail} requested to join ${pdfDataHandling?.policyName}`}</p>
            <p
              style={{
                width: "50%",
                overflowY: "hidden",
                fontSize: "20px",
                height: "6vh",
                textAlign: "center",
                lineHeight: "3vh",
              }}
            >
              {pdfDataHandling?.policyDescription}
            </p>
          </div>

          <div
            style={{
              width: "100%",
              maxWidth: "611px",
              height: "73%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {pdfDataHandling ? (
              <FileViewer
                fileType={`${pdfDataHandling?.docType
                  ?.trim()
                  .split("/")
                  .pop()
                  .trim()
                  .split(".")
                  .pop()
                  .trim()}`}
                filePath={`${pdfDataHandling?.pdflink}`}
              />
            ) : (
              <></>
            )}
          </div>

          <div className={classes.btns}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  padding: "10px",
                  width: "54%",
                  color: "white",
                  fontSize: "19px",
                }}
                href={`${pdfDataHandling?.pdflink}`}
              >
                Download
              </Button>
            </div>
            <div className={classes.approveAndDenyBtn}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleResponse(true);
                  dispatch(setReviewUserLoader(true));
                }}
                style={{
                  padding: "10px",
                  width: "50%",
                  color: "white",
                  fontSize: "19px",
                }}
              >
                Approve
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleResponse(false);
                  dispatch(setReviewUserLoader(true));
                }}
                style={{
                  padding: "10px",
                  width: "50%",
                  color: "white",
                  fontSize: "19px",
                }}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.mainContainer}>
          <h1 style={{ textAlign: "center" }}>
            Your response has been registered already
          </h1>{" "}
        </div>
      );
    }
  }
}
export default ReviewUsers;
