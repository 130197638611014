import {
  CHECKING_CONNECTION_STATUS,
  GET_PROFILE_DATA,
  STORE_WALLET_RELATED_INFOS,
  USER_WALLET_ADDRESS,
  VALIDATION_OF_USERS_WALLET,
  WAITING_LOADER
} from "./constants";

const defaultState = {
  data: "",
  loader: false,
  walletAddress: "",
  walletInfos: {},
  validateWallet: {
    status: false,
    MessageStatement: ""
  },
  connectionStatus: true
};

const accountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return { ...state, data: action.payload };

    case WAITING_LOADER:
      return { ...state, loader: action.payload };

    case USER_WALLET_ADDRESS:
      return { ...state, walletAddress: action.payload };

    case STORE_WALLET_RELATED_INFOS:
      return {
        ...state,
        walletInfos: { ...state.walletInfos, ...action.payload }
      };

    case VALIDATION_OF_USERS_WALLET:
      return { ...state, validateWallet: action.payload };

    case CHECKING_CONNECTION_STATUS:
      return { ...state, connectionStatus: action.payload };
    default:
      return state;
  }
};

export default accountReducer;
