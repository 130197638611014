import { makeStyles } from "@material-ui/styles";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import FileViewer from "react-file-viewer";
import { IoArrowBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ToastMsg from "../SnackBar";

import {
  getUserPolicyResponseData,
  messageDataAction,
  setOnClickUserApproval,
  setReviewLoader,
} from "./redux/action";
import "./style.css";

export function ReviewApproveOrDenied() {
  let id = window.location.pathname.split("/")[2];

  const reviewUserData = useSelector(
    (state) => state.ReviewUserPolicyReducer.userPolicyResponse,
  );

  const userData = useSelector((state) => state.userData.userData);

  const reviewLoader = useSelector(
    (state) => state.ReviewUserPolicyReducer.reviewLoader,
  );

  const accountData = useSelector((state) => state.accountData);

  const allPolicyUsers = useSelector(
    (state) => state.CreatePolicesReducer.isUserAssignPolicy,
  );

  const messages = useSelector((state) => state.userData.message);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userData) {
      dispatch(
        getUserPolicyResponseData({
          id,
          history,
        }),
      );
    }
  }, [userData]);

  const goBackFunc = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/createPolicy");
    }
  };

  const useStyles = makeStyles(() => ({
    mainContainer: {
      width: "100%",
      height: "78vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      gap: "10px",
      marginLeft: "10px",
      margin: "auto",
      alignItems:
        reviewUserData?.userResponse === "APPROVED" ? "center" : "unset",
    },
    btns: {
      display: "flex",
      flexDirection: "column",
    },
    approveAndDenyBtn: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "48%",
      cursor: "pointer",
      // maxWidth: "450px",
      alignItems: "center",
      gap: "20px",
    },
    nameAndDescription: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "15px",
      height: "15%",
      marginTop: "20px",
    },
    backArrow: {
      position: "relative",
      top: "50px",
      left: "10px",
      fontSize: "20px",
      cursor: "pointer",
      height: "20px",
      width: "20px",
    },
  }));
  const classes = useStyles();

  if (!reviewLoader) {
    if (reviewUserData?.userResponse === "PENDING") {
      return (
        <>
          <div className={classes.mainContainer}>
            <span
              className={classes.backArrow}
              onClick={() => {
                goBackFunc();
              }}
            >
              <IoArrowBackSharp />
            </span>
            <div className={classes.nameAndDescription}>
              <p
                style={{
                  fontSize: "25px",
                }}
              >
                Request for Policy Approval
              </p>
              <p
                style={{
                  fontSize: "20px",
                }}
              >
                {reviewUserData?.policyName}
              </p>
              <p
                style={{
                  width: "50%",
                  overflowY: "hidden",
                  fontSize: "15px",
                  height: "6vh",
                  textAlign: "center",
                  lineHeight: "3vh",
                }}
              >
                {reviewUserData?.policyDescription}
              </p>
            </div>
            <h4 style={{ textAlign: "center" }}>
              Eligible Agent ID : {reviewUserData.userWallet}
            </h4>
            {reviewUserData?.policyDoc ? (
              <>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "611px",
                    height: "73%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <FileViewer
                    fileType={reviewUserData?.policyDoc
                      ?.trim()
                      .split("/")
                      .pop()
                      .trim()
                      .split(".")
                      .pop()
                      .trim()}
                    filePath={reviewUserData?.policyDoc}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "611px",
                    height: "40%",
                    marginLeft: "auto",
                    marginRight: "auto",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2>AccountID :{reviewUserData?.userStripeAccountId}</h2>
                  <h2>Amount:{reviewUserData?.holdAmount}</h2>
                </div>
              </>
            )}

            <div className={classes.btns}>
              {reviewUserData?.policyDoc ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      href={`${reviewUserData?.policyDoc}`}
                      style={{
                        padding: "10px",
                        width: "48%",
                        color: "white",
                        fontSize: "19px",
                      }}
                    >
                      Download
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className={classes.approveAndDenyBtn}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={async () => {
                    dispatch(setReviewLoader(true));
                    dispatch(
                      setOnClickUserApproval({
                        userResponse: true,
                        policyName: reviewUserData.policyName,
                        policyId: reviewUserData.policyId,
                        policyAddress: reviewUserData.policyAddress,
                        policyContractAddress: reviewUserData.policyAddress,
                        history,
                      }),
                    );
                  }}
                  style={{
                    padding: "10px",
                    width: "50%",
                    color: "white",
                    fontSize: "19px",
                  }}
                >
                  Approve
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  onClick={async () => {
                    dispatch(setReviewLoader(true));
                    dispatch(
                      setOnClickUserApproval({
                        userResponse: false,
                        policyName: reviewUserData.policyName,
                        policyId: reviewUserData.policyId,
                        policyAddress: reviewUserData.policyAddress,
                        policyContractAddress: reviewUserData.policyAddress,
                        history,
                      }),
                    );
                  }}
                  style={{
                    padding: "10px",
                    width: "50%",
                    color: "white",
                    fontSize: "19px",
                  }}
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>

          <ToastMsg
            toastMessage={messages}
            open={true}
            close={() => dispatch(messageDataAction({}))}
          />
        </>
      );
    } else {
      return (
        <div className={classes.mainContainer}>
          <h1>Your response has been registered already</h1>{" "}
        </div>
      );
    }
  } else {
    return (
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={reviewLoader}
        >
          <CircularProgress />
        </Backdrop>
      </div>
    );
  }
}
