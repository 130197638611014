import { getAxiosInstance } from "../../../utils/axios";
import { API } from "../../../utils/config";

export const getAllChatApi = async () => {
  try {
    let data = await getAxiosInstance().get(`${API}gpt`);
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
export const addChatApi = async () => {
  try {
    let data = await getAxiosInstance().post(`${API}gpt`);
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const deleteChatApi = async ({ id }) => {
  try {
    let data = await getAxiosInstance().delete(`${API}gpt/${id} `);
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
export const editChatApi = async ({ id, chatName }) => {
  try {
    let data = await getAxiosInstance().patch(`${API}gpt/${id}`, {
      chatName: chatName
    });
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const SendUserQuestionApi = async ({ id, formData }) => {
  try {
    let data = await getAxiosInstance().post(`${API}gpt/${id}/chat`, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data"
      }
    });
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};

export const checkAndGetAnswer = async ({ id }) => {
  try {
    const data = await getAxiosInstance().get(`${API}gpt/${id}/chat`);
    return data;
  } catch (err) {
    console.log(err);
    return { err };
  }
};
