import {
  ACTIVE_ITEM,
  DRAWER_OPEN,
  SET_ACCOUNTS,
  SET_MESSAGE,
  SIDE_BAR_DRAWER
} from "./constants";

const defaultState = {
  message: { message: "", status: "" },
  accounts: [],
  sideBarDrawer: false,
  openItem: ["dashboard"],
  drawerOpen: false
};

const headerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIVE_ITEM:
      return { ...state, openItem: action.payload };
    case DRAWER_OPEN:
      return { ...state, drawerOpen: action.payload };
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case SIDE_BAR_DRAWER:
      return { ...state, sideBarDrawer: action.payload };
    case SET_ACCOUNTS:
      return { ...state, accounts: action.payload };
    default:
      return state;
  }
};

export default headerReducer;
