import {
  ASSIGN_USER_BY_POLICY_ADMIN,
  CREATE_NEW_POLICY,
  DELETE_POLICY,
  GET_ALL_POLICY,
  GET_ALL_POLICY_USERS,
  GET_ALL_STRIPE_ACCOUNTS,
  GET_USER_APPROVAL_RESPONSE_DATA,
  IS_USER_ASSIGN_POLICY,
  REQUEST_FOR_POLICY_BY_USER,
  SET_ALL_POLICY,
  SET_ALL_POLICY_USERS,
  SET_ALL_STRIPE_ACCOUNTS,
  SET_CREATE_POLICY_LOADER,
  SET_CREATE_POLICY_MODAL,
  SET_DELETE_MODAL_LOADER,
  SET_POLICY_DOCUMENT,
  SET_SEND_TOKEN_MODAL,
  SET_SUBMIT_BUTTON_LOADER,
  SET_UPDATE_POLICY,
  SET_USER_APPROVAL_RESPONSE_DATA,
  TRANSFER_TOKEN_BUTTON_LOADER,
  UPDATE_POLICY,
} from "./constants";

export const getAllPolicyUsers = (value) => {
  return {
    type: GET_ALL_POLICY_USERS,
    payload: value,
  };
};

export const getAllPolicy = (value) => {
  return {
    type: GET_ALL_POLICY,
    payload: value,
  };
};

export const updatePolicy = (value) => {
  return {
    type: UPDATE_POLICY,
    payload: value,
  };
};

export const setUpdatedPolicy = (value) => {
  return {
    type: SET_UPDATE_POLICY,
    payload: value,
  };
};

export const deletePolicyAction = (value) => {
  return {
    type: DELETE_POLICY,
    payload: value,
  };
};

export const setAllPolicyUsers = (value) => {
  return {
    type: SET_ALL_POLICY_USERS,
    payload: value,
  };
};
export const setAllPolicy = (value) => {
  return {
    type: SET_ALL_POLICY,
    payload: value,
  };
};

export const createNewPolicy = (value) => {
  return {
    type: CREATE_NEW_POLICY,
    payload: value,
  };
};

export const assignUsers = (value) => {
  return {
    type: ASSIGN_USER_BY_POLICY_ADMIN,
    payload: value,
  };
};
export const requestForPolicy = (value) => {
  return {
    type: REQUEST_FOR_POLICY_BY_USER,
    payload: value,
  };
};

export const isUserAssignPolicy = (value) => {
  return {
    type: IS_USER_ASSIGN_POLICY,
    payload: value,
  };
};

export const setUserApprovalRequests = (value) => {
  return {
    type: SET_USER_APPROVAL_RESPONSE_DATA,
    payload: value,
  };
};
export const getUserApprovalRequests = (value) => {
  return {
    type: GET_USER_APPROVAL_RESPONSE_DATA,
    payload: value,
  };
};
export const setCreatePolicyLoader = (value) => {
  return {
    type: SET_CREATE_POLICY_LOADER,
    payload: value,
  };
};
export const setCreatePolicyModal = (value) => {
  return {
    type: SET_CREATE_POLICY_MODAL,
    payload: value,
  };
};
export const setDeleteModal = (value) => {
  return {
    type: SET_DELETE_MODAL_LOADER,
    payload: value,
  };
};

export const setSendTokenMoal = (value) => {
  return {
    type: SET_SEND_TOKEN_MODAL,
    payload: value,
  };
};

export const setSubmitButtonLoader = (value) => {
  return {
    type: SET_SUBMIT_BUTTON_LOADER,
    payload: value,
  };
};

export const setPolicyDocument = (value) => {
  return {
    type: SET_POLICY_DOCUMENT,
    payload: value,
  };
};

export const getAllStripeAccounts = (value) => {
  return {
    type: GET_ALL_STRIPE_ACCOUNTS,
    payload: value,
  };
};

export const setAllStripeAccounts = (value) => {
  return {
    type: SET_ALL_STRIPE_ACCOUNTS,
    payload: value,
  };
};

export const transferTokenButtonLoader = (value) => {
  return {
    type: TRANSFER_TOKEN_BUTTON_LOADER,
    payload: value,
  };
};
