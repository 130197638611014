export const GET_ALL_CHAT_LIST = "GET_ALL_CHAT_LIST";
export const SET_ALL_CHAT_LIST = "SET_ALL_CHAT_LIST";

export const ADD_NEW_CHAT_ON_LIST = "ADD_NEW_CHAT_ON_LIST";

//==============================STATE================================//
export const SELECTED_CHAT_ID = "SELECTED_CHAT_ID";
export const EDIT_CHAT_NAME = "EDIT_CHAT_NAME";

//==============================EDIT & DELETE & ADD==========================//
export const DELETE_CHAT_FROM_LIST = "DELETE_CHAT_FROM_LIST";
export const EDIT_CHAT_NAME_IN_LIST = "EDIT_CHAT_NAME_IN_LIST";
export const ADD_CHAT_IN_LIST = "ADD_CHAT_IN_LIST";
export const SEND_USER_QUESTION_IN_CHAT = "SEND_USER_QUESTION_IN_CHAT";

export const SELECTED_CHAT_MESSAGES_ID = "SELECTED_CHAT_MESSAGES_ID";

export const IS_LOADING_CHAT = "IS_LOADING_CHAT";
