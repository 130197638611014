import { Auth } from "aws-amplify";
import axios from "axios";
let currentAuthToken = null;

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

export function setToken(token) {
  currentAuthToken = token;
}

export function getToken() {
  return currentAuthToken;
}

export const getAxiosInstance = () => {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${currentAuthToken}`
    }
  });
  axiosInstance.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { config, response } = error;
      const originalRequest = config;
      if (response === undefined || response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          Auth.currentAuthenticatedUser().then(user => {
            setToken(user.signInUserSession.idToken.jwtToken);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(user.signInUserSession.idToken.jwtToken);
          });
        }
        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = "Bearer " + access_token;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(data) {
  subscribers.push(data);
}
