// material-ui
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
// assets
import { IconMenu2 } from "@tabler/icons";
import copy from "copy-to-clipboard";
import { useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import useConfig from "../../../hooks/useConfig";
// project imports
import { useSelector } from "react-redux";
import metamask from "../../../../../images/MetaMask_Fox.svg.png";
import coinbase from "../../../../../images/coinbase.png";
import handleConnect from "../../../../../utils/connectWalletFunctions";
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = (props) => {
  const walletAddressAssociatedWithUser = useSelector(
    (state) => state.accountData.walletAddress
  );
  const validateWalletUsersInfo = useSelector(
    (state) => state.accountData.validateWallet
  );
  const theme = useTheme();
  const {
    navType,
    onChangeMenuType,
    borderRadius,
    walletType,
    onChangeWalletType,
  } = useConfig();
  const [showWalletType, setShowWalletType] = useState(false);
  const [walletAccountAddress, setWalletAccountAddress] = useState("");

  // useEffect(async () => {
  //   try {
  //     const acountDetail = await window.ethereum?.request({
  //       method: "eth_requestAccounts"
  //     });
  //     if (acountDetail) {
  //       setWalletAccountAddress(acountDetail);
  //     }
  //   } catch (error) {
  //     if (error.code === -32002) {
  //       props.messageAction({
  //         message:
  //           "Your request is already pending please open your metamask wallet and approve it",
  //         status: "error"
  //       });
  //     }
  //   }
  // }, []);

  const useStyles = makeStyles(() => ({
    modal: {
      backgroundColor: "#10100d",
      width: "940px",
      height: "550px",
      marginLeft: "180px",
      marginTop: "50px",
      overflow: "auto",
    },

    field: {
      width: "350px",
      marginTop: "20px !important",
      marginRight: "20px !important",
      color: "white",
    },
    formControl: {
      width: "300px",
      margin: "30px",
      color: "white !important",
      fontColor: "white !important",
      "& svg": { color: "rgb(232 232 232 / 54%) !important" },
    },
    select: {
      background: "white",
    },
    submitBtn: {
      fontSize:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.fontSize
          : "12px",
      color:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.fontColor
          : "#ffffff",
      backgroundColor:
        props.airDropData && props.airDropData.submitButton
          ? props.airDropData.submitButton.btnColor
          : "#1976d2",
      "&:hover": {
        backgroundColor:
          props.airDropData && props.airDropData.submitButton
            ? props.airDropData.submitButton.btnColor
            : "#1976d2",
      },
    },
    modal2: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "320px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px",
    },
    modal3: {
      backgroundColor: "#10100d",
      width: "400px",
      height: "180px",
      margin: "100px auto",
      borderRadius: "20px",
      color: "white",
      padding: "20px",
    },
    drawerLogo: {
      textAlign: "center",
      color: props.colorgroup,
    },
  }));
  const classes = useStyles();
  if (window.ethereum) {
    window.ethereum?.on("accountsChanged", (accounts) => {
      props.headerAccountAction(accounts);
    });
  }

  const networkMap = {
    1: "https://etherscan.io/",
    4: "https://rinkeby.etherscan.io/",
    137: "http://polygonscan.com/",
    80001: "https://mumbai.polygonscan.com/",
  };

  const getAccount = async () => {
    const acountDetail = await window.ethereum
      ?.request({ method: "eth_requestAccounts" })
      .catch((err) => {
        if (err.code === -32002) {
          props.messageAction({
            message:
              "Your request is already pending please open your metamask wallet and approve it",
            status: "error",
          });
        }
        if (err.code === 4001) {
          props.messageAction({
            message: "Please connect to MetaMask.",
            status: "error",
          });
        } else {
          props.messageAction({
            message: "Please connect to Metamask.",
            status: "error",
          });
          console.error("error", err);
        }
      });
    if (acountDetail === undefined) {
      props.messageAction({
        message: "Please install metamask wallet",
        status: "error",
      });
    }
    if (!validateWalletUsersInfo.status) {
      props.messageAction({
        message: validateWalletUsersInfo.MessageStatement,
        status: "error",
      });
    }
    setWalletAccountAddress(acountDetail);
  };

  return (
    <>
      {/* logo & toggler button */}

      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        {props.path !== "/login" && (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              props.drawerOpenAction(!props.drawerOpen);
            }}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        )}
      </Box>
      {/* header search */}
      {props.path !== "/login" && (
        <>
          {/* <SearchSection /> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />
          {/* mega-menu */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {/* <MegaMenuSection /> */}
          </Box>
          <Box marginX={2}>
            <Button
              variant="contained"
              color="primary"
            // onClick={() => {
            //   getAccount();
            // }}
            >
              {validateWalletUsersInfo.status === true
                ? walletAddressAssociatedWithUser
                : "Connect Agent"}
            </Button>
          </Box>
          {showWalletType && (
            <Modal
              open={showWalletType}
              onClose={() => {
                setShowWalletType(false);
              }}
            >
              <div className={classes.modal3}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    flexDirection: "column",
                  }}
                >
                  Select wallet you want to connect
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    onChangeWalletType("Metamask");
                    setShowWalletType(false);
                    await handleConnect(
                      "Metamask",
                      props.headerAccountAction,
                      props.headerNetworkIdAction,
                      props.setWalletType,
                      props.headerSetBalance,
                      props.messageAction
                    );
                  }}
                >
                  <img
                    src={metamask}
                    width="30px"
                    height="30px"
                    style={{ marginRight: "10px" }}
                  />
                  Metamask
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    onChangeWalletType("Coinbase");
                    setShowWalletType(false);
                    await handleConnect(
                      "Coinbase",
                      props.headerAccountAction,
                      props.headerNetworkIdAction,
                      props.setWalletType,
                      props.headerSetBalance,
                      props.messageAction
                    );
                  }}
                >
                  <img
                    src={coinbase}
                    width="30px"
                    height="30px"
                    style={{ marginRight: "10px" }}
                  />
                  Coinbase
                </div>
              </div>
            </Modal>
          )}
          {props.accountModal && (
            <Modal
              open={props.accountModal}
              onClose={() => {
                props.showAccountModal(false);
              }}
            >
              <div className={classes.modal2}>
                <div style={{ height: "50px", paddingTop: "10px" }}>
                  Account
                </div>
                <div
                  style={{
                    height: "30px",
                    color: "#868686",
                    marginBottom: "10px",
                  }}
                >
                  Connected with {walletType}
                </div>
                <div>
                  {`${props.headerAccount[0].substring(
                    0,
                    6
                  )}...${props.headerAccount[0].substring(
                    props.headerAccount[0].length - 4,
                    props.headerAccount[0].length
                  )}`}
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "#868686",
                    marginTop: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      copy(props.headerAccount[0]);
                      props.messageAction({
                        message: "Successfully copied to clipboard",
                        status: "success",
                      });
                    }}
                  >
                    <FiCopy style={{ marginRight: "10px" }} />
                    Copy Address
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        `${networkMap[props.headerNeworkId]}address/${props.headerAccount[0]
                        }`
                      )
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    View on Explorer
                  </div>
                </div>
                <div>Bridges</div>
                <div
                  style={{
                    display: "flex",
                    color: "#868686",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{ marginRight: "20px", marginBottom: "20px" }}
                    onClick={() =>
                      window.open("https://wallet.polygon.technology/bridge")
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    Polygon Bridge
                  </div>
                  <div
                    onClick={() =>
                      window.open("https://bridge.umbria.network/")
                    }
                  >
                    <BiLinkExternal style={{ marginRight: "10px" }} />
                    Umbria Bridge
                  </div>
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.showAccountModal(false);
                    props.headerAccountAction(() => []);
                  }}
                  style={{ float: "right", marginRight: "30px" }}
                >
                  Logout
                </Button>
              </div>
            </Modal>
          )}
          {/* <Box>
            <IconButton
              onClick={(e) => {
                if (navType === "light") {
                  onChangeMenuType("dark");
                } else {
                  onChangeMenuType("light");
                }
              }}
              aria-label="Dark mode toggler"
              color={navType === "light" ? "primary" : "secondary"}
            >
              {navType === "light" ? (
                <svg
                  width={24}
                  height={24}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
                </svg>
              ) : (
                <svg
                  width={24}
                  height={24}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              )}
            </IconButton>
          </Box> */}
          {/* notification & profile */}
          {/* <NotificationSection /> */}
          <ProfileSection
            postLogoutSuccess={props.postLogoutSuccess}
            userData={props.userData}
            refferal_code={props.refferal_code}
            userprofile={props.userprofile}
            update_data={props.update_data}
            setUserWalletAddress={props.setUserWalletAddress}
            setWalletRelatedInfo={props.setWalletRelatedInfo}
          />
          {/* mobile header */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <MobileSection />
          </Box>
        </>
      )}
    </>
  );
};

export default Header;
