export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const EDIT_PROFILE_DATA = "EDIT_PROFILE_DATA";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const VERIFY_EDIT_DATA = "VERIFY_EDIT_DATA";
export const WAITING_LOADER = "WAITING_LOADER";
export const USER_WALLET_ADDRESS = "USER_WALLET_ADDRESS";
export const STORE_WALLET_RELATED_INFOS = "STORE_WALLET_RELATED_INFOS";
export const VALIDATION_OF_USERS_WALLET = "VALIDATION_OF_USERS_WALLET";
export const CHECKING_CONNECTION_STATUS = "CHECKING_CONNECTION_STATUS";
export const VALIDATE_WALLET_ADDRESS = "VALIDATE_WALLET_ADDRESS"
