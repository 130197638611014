import { FORGET_VERIFY_EMAIL } from "./constant";

const defaultState = {
  email: '',
  
};

const forgetverifyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FORGET_VERIFY_EMAIL:
    
      return { ...state, email: action.payload };
   
    default:
      return state;
  }
};

export default forgetverifyReducer;
