export const GET_ALL_POLICY_USERS = "GET_ALL_POLICY_USERS";
export const SET_ALL_POLICY_USERS = "SET_ALL_POLICY_USERS";
export const GET_POLICY_ADMIN_LIST = "GET_POLICY_ADMIN_LIST";
export const SET_POLICY_ADMIN_LIST = "SET_POLICY_ADMIN_LIST";

export const GET_ALL_POLICY = "GET_ALL_POLICY";
export const SET_ALL_POLICY = "SET_ALL_POLICY";

export const CREATE_NEW_POLICY = "CREATE_NEW_POLICY";

export const DELETE_POLICY = "DELETE_POLICY";

export const UPDATE_POLICY = "UPDATE_POLICY";

export const SET_UPDATE_POLICY = "SET_UPDATE_POLICY";
export const ASSIGN_USER = "ASSIGN_USER";
export const ASSIGN_USER_BY_POLICY_ADMIN = "ASSIGN_USER_BY_POLICY_ADMIN";
export const REQUEST_FOR_POLICY_BY_USER = "REQUEST_FOR_POLICY_BY_USER";
export const UPDATE_ASSIGN_USER = "UPDATE_ASSIGN_USER";

export const IS_USER_ASSIGN_POLICY = "IS_USER_ASSIGN_POLICY";
export const GET_USER_APPROVAL_RESPONSE_DATA =
  "GET_USER_APPROVAL_RESPONSE_DATA";
export const SET_USER_APPROVAL_RESPONSE_DATA =
  "SET_USER_APPROVAL_RESPONSE_DATA";
export const SET_CREATE_POLICY_LOADER = "SET_CREATE_POLICY_LOADER";
export const SET_DELETE_MODAL_LOADER = "SET_DELETE_MODAL_LOADER";
export const SET_CREATE_POLICY_MODAL = "SET_CREATE_POLICY_MODAL";
export const SET_SUBMIT_BUTTON_LOADER = "SET_SUBMIT_BUTTON_LOADER";
export const SET_POLICY_DOCUMENT = "SET_POLICY_DOCUMENT";
export const SET_ASSIGN_USER_MODAL = "SET_ASSIGN_USER_MODAL";
export const GET_ALL_STRIPE_ACCOUNTS = "GET_ALL_STRIPE_ACCOUNTS";
export const SET_ALL_STRIPE_ACCOUNTS = "SET_ALL_STRIPE_ACCOUNTS";

export const SET_SEND_TOKEN_MODAL = "SET_SEND_TOKEN_MODAL";
export const TRANSFER_TOKEN_BUTTON_LOADER = "TRANSFER_TOKEN_BUTTON_LOAFER";
