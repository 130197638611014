import {
  SET_ADDED_USER_EMAILS_POLICIE,
  SET_INVITE_BTN_LOADER,
  SET_MODAL_STATUS,
  USER_TABLE_LOADER
} from "./constant";

const defaultState = {
  emails: [],
  modalStatus: false,
  inviteBtnLoader: false,
  loader: true
};

const PolicieEmailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ADDED_USER_EMAILS_POLICIE:
      return { ...state, emails: action.payload };

    case SET_MODAL_STATUS:
      return { ...state, modalStatus: action.payload };

    case SET_INVITE_BTN_LOADER:
      return { ...state, inviteBtnLoader: action.payload };

    case USER_TABLE_LOADER:
      return { ...state, loader: action.payload }

    default:
      return state;
  }
};

export default PolicieEmailReducer;
