import { Backdrop, Button, CircularProgress, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "aos/dist/aos.css";
import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import ProductSlider from "../../../../../ProductSlider";
import Container from "../../../../../containers/common/Container";
import metamask from "../../../../../images/MetaMask_Fox.svg.png";
import coinbase from "../../../../../images/coinbase.png";
import handleConnect from "../../../../../utils/connectWalletFunctions";
import { mintNftFunction } from "../../../../../utils/handleMint";
import { verifyeditdata } from "../../../../Account/actions";
// import Brentrice from "../../../../Brentrice";
// import "../../../../Brentrice/brentrice.css";
import useConfig from "../../../../HeaderLayout/hooks/useConfig";
import {
  AboutUsData,
  aboutUpModalShow,
  headerAccountAction,
  headerNetworkId,
  headerSetBalance,
  headerWalletType,
  landingPageData,
  loadingAction,
  messageAction,
  registerUserAction,
  showAccountModal
} from "../../../../LandingPage/actions";

import { postLoginSuccess, waitingAction } from "../../../../Login/actions";
// import { getMarketPlaceListAction } from "../../../../MarketPlace/actions";
import ToastMsg from "../../../../SnackBar";
// import SquareSupply from "../../../../SquareSupply";

import {
  About,
  Footer,
  Hero,
  Highlights,
  Main,
  Roadmap,
  Solutions,
  Team
} from "./components";

const Home = props => {
  const seoTagsValue = {
    title: props.seoTagsValue?.title ? props.seoTagsValue.title : "",
    description: props.seoTagsValue?.description
      ? props.seoTagsValue.description
      : "",
    keyword: props.seoTagsValue?.keyword ? props.seoTagsValue.keyword : ""
  };

  const checkUrl = window.location.href;
  const [check, setcheck] = React.useState("");
  const [mintData, setMintData] = React.useState();
  const [mint, setMint] = React.useState(1);
  const [mintNo, setMintNo] = React.useState(0);
  const [showWalletType, setShowWalletType] = React.useState(false);
  const [mintPrice, setMintPrice] = React.useState();
  const [totalMint, setTotalMint] = React.useState(0);
  const [nftAddress, setNftAddress] = React.useState();
  const { walletType, onChangeWalletType } = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    setTotalMint(props.singleMinterData?.nftContract?.supply);
    setMintPrice(props.singleMinterData?.nftContract?.price);
    setNftAddress(props.singleMinterData?.nftContract.nftAddress);
    setMintNo(props.singleMinterData?.mintedNftsCount);

    let nftData =
      props.singleMinterData?.nftContract?.status == "COMPLETED"
        ? props.singleMinterData
        : "";
    setMintData(nftData);
  }, [props.singleMinterData, props.mintDataForPrice]);

  const [showAnimation, setshowAnimation] = React.useState({
    headerScroll: false,
    headerTabBar: true,
    headerContainer: false,
    mintContainer: false,
    statsContainer: false,
    aboutusContainer: false,
    registerUserContainer: false,
    solutionContainer: false,
    roadmapContainer: false,
    discordTitle: false,
    mintScroll: false,
    statsScroll: false,
    roadMap: false,
    aboutUS: false,
    registerUserScroll: false,
    solutionScroll: false
  });

  React.useEffect(() => {
    if (props?.scrollTo?.scrollTo === "headerTitle") {
      gotoHeaderScroll();
    } else if (props?.scrollTo?.scrollTo === "MINT") {
      gotoMintScroll();
    } else if (props?.scrollTo?.scrollTo === "Stats") {
      gotostatsScroll();
    } else if (props?.scrollTo?.scrollTo === "Roadmap") {
      gotoroadMapScroll();
    } else if (props?.scrollTo?.scrollTo === "Aboutus") {
      gotoAboutUsScroll();
    } else if (props?.scrollTo?.scrollTo === "Registeruser") {
      gotoRegisteruserScroll();
    } else if (props?.scrollTo?.scrollTo === "Solution") {
      gotosolutionScroll();
    } else {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: false,
        roadMap: false,
        aboutUS: false,
        registerUserScroll: false,
        solutionScroll: false
      });
    }
  }, [props?.scrollTo?.toggle]);
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    comingSoonLabel: {
      fontSize: "xx-large",
      textAlign: "center",
      margin: "5px 0px"
    },
    mintbannerContainer: {
      margin: "auto",
      background: "rgba(255, 255, 255, 0.95)",
      maxWidth: "900px",
      width: "100%",
      padding: "56px 0 36px"
    },
    heading: {
      margin: "auto",
      // maxWidth: "300px",
      maxWidth: "350px",
      width: "100%",
      "@media(max-width:992px)": {
        maxWidth: "300px"
      },
      "& img": {
        width: "100%",
        height: "auto"
      }
    },
    mintedContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "35px 0 65px",
      "@media screen and (max-width: 576px)": {
        flexDirection: "column-reverse",
        gridGap: "20px"
      }
    },
    totalmintedData: {
      color: "black",
      width: "50%",
      textAlign: "center",
      borderRight: "3px solid black",
      "@media screen and (max-width: 576px)": {
        border: "none",
        borderTop: "3px solid black",
        paddingTop: "20px"
      },

      "& h3": {
        fontSize: "34px",
        fontWeight: " bold",
        marginBottom: "20px"
      }
    },
    addMintedContainer: {
      width: "50%"
    },
    addMinted: {
      margin: "auto",
      maxWidth: "165px",
      width: "100%"
    },
    add_sub_btn: {
      color: "black",
      width: "35px",
      minWidth: "35px",
      height: "35px",
      fontWeight: "bold",
      fontSize: "25px",
      border: "2px solid black"
    },
    mintedQuantity: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    connect: {
      maxWidth: "284px",
      width: "100%",
      padding: "10px",
      color: "white",
      cursor: "pointer",
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
      background: "black",
      fontSize: "24px",
      "&:hover": {
        background: "black",
        boxShadow: "none"
      }
    },
    contractAddressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      "& h6": {
        fontSize: "16px"
      },
      "& span": {
        fontSize: "16px",
        borderBottom: "2px solid transparent",
        "&:hover": {
          borderBottom: "2px solid black"
        }
      }
    },
    modal3: {
      width: "30%",

      transform: "translate( 100% , 100% )"
    },
    innerModal3: {
      // height: "100%",
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "center",
      // alignItems: "center",
      color: theme.palette.mode === "dark" ? "#fff" : "black",
      backgroundColor: theme.palette.mode === "dark" ? "black" : "#fff",
      padding: "20px",
      borderRadius: "10px"
    }
  }));

  const classes = useStyles();
  function gotoHeaderScroll() {
    const element = document.getElementById("headerScroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    setshowAnimation({
      headerScroll: true,
      mintScroll: false,
      statsScroll: false,
      roadMap: false,
      aboutUS: false,
      registerUserScroll: false,
      solutionScroll: false
    });
  }
  function gotoMintScroll() {
    const element = document.getElementById("mintScroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: true,
      statsScroll: false,
      roadMap: false,
      aboutUS: false,
      registerUserScroll: false,
      solutionScroll: false
    });
  }
  function gotostatsScroll() {
    const element = document.getElementById("statsScroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: false,
      statsScroll: true,
      roadMap: false,
      aboutUS: false,
      registerUserScroll: false,
      solutionScroll: false
    });
  }
  function gotoroadMapScroll() {
    const element = document.getElementById("roadMap");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: false,
      statsScroll: false,
      roadMap: true,
      aboutUS: false,
      registerUserScroll: false,
      solutionScroll: false
    });
  }
  function gotoAboutUsScroll() {
    const element = document.getElementById("aboutUS");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: false,
      statsScroll: false,
      aboutUS: true,
      roadMap: false,
      registerUserScroll: false,
      solutionScroll: false
    });
  }
  function gotoRegisteruserScroll() {
    const element = document.getElementById("registerUserScroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: false,
      statsScroll: false,
      roadMap: false,
      aboutUS: false,
      registerUserScroll: true,
      solutionScroll: false
    });
  }
  function gotosolutionScroll() {
    const element = document.getElementById("solutionScroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setshowAnimation({
      headerScroll: false,
      mintScroll: false,
      statsScroll: false,
      roadMap: false,
      aboutUS: false,
      registerUserScroll: false,
      solutionScroll: true
    });
  }

  React.useEffect(() => {
    let checkAddress = window.location.href;
    var check = checkAddress.includes("customLandingPage");
    setcheck(check);
  }, []);

  React.useEffect(() => {
    // if (window.location.host.includes("degendrones.com")) {
    //   props.landingPageData({
    //     subDomain: "metaontheblock-2"
    //   });
    // }
    let locationUrl = window.location.host.replace("www.", "");
    if (props?.location?.pathname === "/" || props?.location?.pathname === "") {
      Authentication();
      props.landingPageData({
        subDomain: process.env.REACT_APP_SUB_DOMAIN,
        userSubDomain: locationUrl
      });
    } else {
      AuthData();

      if (props.tag != "form") {
        props.landingPageData({
          subDomain:
            props.history.location.pathname.split("/")[1] ||
            props.subDomain ||
            props.scrollTo.getSubDomain.subDomain ||
            process.env.REACT_APP_SUB_DOMAIN
        });
      }
    }
    // props.getMarketPlaceListAction();
  }, []);

  const history = useHistory();

  const closeSnackBar = () => {
    props.messageAction({});
  };

  const Authentication = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      if (user && user.username) {
        const data = {
          userId: user.attributes.sub,
          email: user.attributes.email,
          given_name: user.attributes.given_name || "",
          family_name: user.attributes.family_name || ""
        };

        dispatch(verifyeditdata({ data: data, history: history }));
      } else {
        history.push("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const AuthData = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      if (user && user.username) {
        props.postLoginSuccess({
          userId: user.username,
          isAdmin: true
        });
      } else {
        history.push("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleScrollParent = e => {
    if (window.location.href.includes("customLandingPage")) {
      props.sendData(e);
    }

    if (e === "headerContainer") {
      setshowAnimation({
        headerScroll: true,
        mintScroll: false,
        statsScroll: false,
        aboutUS: false,
        roadMap: false,
        registerUserScroll: false,
        solutionScroll: false
      });
    } else if (e === "mintContainer") {
      setshowAnimation({
        headerScroll: false,
        mintScroll: true,
        statsScroll: false,
        aboutUS: false,
        roadMap: false,
        registerUserScroll: false,
        solutionScroll: false
      });
    } else if (e === "statsContainer") {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: true,
        aboutUS: false,
        roadMap: false,
        registerUserScroll: false,
        solutionScroll: false
      });
    } else if (e === "registerUserContainer") {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: false,
        aboutUS: false,
        roadMap: false,
        registerUserScroll: true,
        solutionScroll: false
      });
    } else if (e === "solutionContainer") {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: false,
        aboutUS: false,
        roadMap: false,
        registerUserScroll: false,
        solutionScroll: true
      });
    } else if (e === "roadmapContainer") {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: false,
        aboutUS: false,
        roadMap: true,
        registerUserScroll: false,
        solutionScroll: false
      });
    } else {
      setshowAnimation({
        headerScroll: false,
        mintScroll: false,
        statsScroll: false,
        aboutUS: true,
        roadMap: false,
        registerUserScroll: false,
        solutionScroll: false
      });
    }
  };

  return (
    <Box
      sx={{
        marginTop: "-50px",
        overflow: "hidden",
        background: props.displayType == "matrix" ? "black" : ""

        // color: props.displayType === "matrix" && theme.palette.mode === "dark" ? "green" : "black "
      }}
    >
      <Helmet>
        <title>{seoTagsValue.title}</title>
        <meta
          name="description"
          content={seoTagsValue.description}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={seoTagsValue.keyword}
          data-react-helmet="true"
        />
      </Helmet>

      <ToastMsg
        toastMessage={props.isMessage}
        open={true}
        close={closeSnackBar}
      />
      {!check && props.stateLoading ? (
        <Backdrop
          sx={{
            zIndex: theme => theme.zIndex.drawer + 1
          }}
          open={props.stateLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {props.themeType !== "SquareSupply" &&
            props.themeType !== "brentrice" ? (
            <div>
              {/* header */}

              <Box
                style={{ marginTop: "50px" }}
                bgcolor={
                  props.displayType != "matrix" &&
                  theme?.palette?.alternate?.main
                }
                position={"relative"}
              >
                <Container
                  onClick={() => {
                    handleScrollParent("headerContainer");
                  }}
                  position="relative"
                  zIndex={2}
                  id="headerScroll"
                  style={
                    showAnimation.headerScroll && check
                      ? {
                        border: "2px solid white",
                        padding: "20px",
                        borderRadius: "20px",
                        maxWidth:
                          props.landingsuccessData?.properties.header
                            .displayType == "matrix" && "100%"
                      }
                      : {
                        paddingTop: "100px",
                        maxWidth:
                          props.landingsuccessData?.properties.header
                            .displayType == "matrix" && "100%"
                      }
                  }
                >
                  <Hero
                    data={props.landingsuccessData}
                    user={props.user}
                    // nftData={
                    //   props.marketPlaceList &&
                    //   props.landingsuccessData?.properties?.mintData[0].mint
                    //     .minterId &&
                    //   props.marketPlaceList.find(
                    //     elem =>
                    //       elem.nftContract._id ===
                    //       props.landingsuccessData?.properties?.mintData[0].mint
                    //         .minterId
                    //   )
                    // }
                    messageAction={props.messageAction}
                    waitingAction={props.waitingAction}
                    headerAccountAction={props.headerAccountAction}
                    headerAccount={props.headerAccount}
                  />
                </Container>
              </Box>

              {/* {props?.featuredProductsData?.length > 0 && ( */}
              {/* <Box sx={{ padding: "20px 30px" }}> */}
              {/* <ProductSlider /> */}
              {/* </Box> */}
              {/* )} */}
              {/* mint */}
              {props.landingsuccessData &&
                props.landingsuccessData.properties.mintData[0]?.mint?.title
                  ?.value &&
                props.landingsuccessData.properties.mintData.map(
                  (value, index) => (
                    <Container
                      id="mintScroll"
                      onClick={() => {
                        handleScrollParent("mintContainer");
                      }}
                      style={
                        showAnimation.mintScroll && check
                          ? {
                            border: "2px solid white",
                            padding: "20px",
                            borderRadius: "20px"
                          }
                          : null
                      }
                    >
                      {props.landingsuccessData &&
                        props.landingsuccessData?.properties?.mintData[0]?.mint
                          ?.minterId && (
                          <>
                            <Box
                              className={classes.mintbannerContainer}
                              onClick={() =>
                                setshowAnimation({
                                  headerTabBar: false,
                                  headerContainer: false,
                                  mintContainer: true,
                                  statsContainer: false,
                                  aboutusContainer: false,
                                  registerUserContainer: false,
                                  solutionContainer: false,
                                  roadmapContainer: false
                                })
                              }
                              style={
                                showAnimation.mintContainer
                                  ? {
                                    border: "2px solid white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                    marginBottom: "15px"
                                  }
                                  : { padding: "20px" }
                              }
                            >
                              <Box className={classes.heading}>
                                <img src={props.customImage} />
                              </Box>
                              {props.singleMinterData && (
                                <>
                                  <Box className={classes.mintedContainer}>
                                    <Box className={classes.totalmintedData}>
                                      <h3>
                                        {mintNo}/{totalMint}
                                      </h3>
                                      <span>minted</span>
                                    </Box>

                                    <Box className={classes.addMintedContainer}>
                                      <Box className={classes.addMinted}>
                                        <Box className={classes.mintedQuantity}>
                                          <Button
                                            className={classes.add_sub_btn}
                                            onClick={() => {
                                              if (mint > 1) {
                                                setMint(mint - 1);
                                              }
                                            }}
                                          >
                                            -
                                          </Button>
                                          <h4
                                            style={{
                                              fontSize: "36px",
                                              color: "black"
                                            }}
                                          >
                                            {mint}
                                          </h4>
                                          <Button
                                            className={classes.add_sub_btn}
                                            onClick={() => {
                                              setMint(mint + 1);
                                            }}
                                          >
                                            +
                                          </Button>
                                        </Box>

                                        <Box
                                          sx={{
                                            textAlign: "center",
                                            color: "black",
                                            paddingTop: "10px",
                                            "& h5": {
                                              fontSize: "21px",
                                              lineHeight: "23px",
                                              marginTop: "10px"
                                            },
                                            "& h6": {
                                              fontSize: "16px",
                                              marginTop: "10px"
                                            }
                                          }}
                                        >

                                          <h5>
                                            {props.singleMinterData &&
                                              props.singleMinterData
                                                ?.nftContract &&
                                              props.singleMinterData?.nftContract
                                                ?.price
                                              ? props.singleMinterData
                                                .nftContract?.price
                                              : ""}

                                            {(props.singleMinterData
                                              ?.nftContract.network ==
                                              "polygon" ||
                                              props.singleMinterData
                                                ?.nftContract.network ==
                                              "polygon_mainnet") &&
                                              props.singleMinterData?.nftContract
                                                .token == "matic"
                                              ? " MATIC"
                                              : props.singleMinterData
                                                ?.nftContract.token == "weth"
                                                ? " CLM"
                                                : " ETH"}
                                          </h5>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>

                                  {props.singleMinterData.nftContract &&
                                    props.singleMinterData.nftContract.status &&
                                    props.singleMinterData.nftContract.status ==
                                    "COMPLETED" ? (
                                    <>
                                      <Box
                                        sx={{
                                          margin: "auto",
                                          textAlign: "center",
                                          paddingBottom: "33px",
                                          "@ media(max-width:500px)": {
                                            paddingBottom: "20px"
                                          }
                                        }}
                                      >
                                        <Button
                                          onClick={async () => {
                                            if (
                                              props.headerAccount &&
                                              props.headerAccount[0]
                                            ) {
                                              const isMinted = await mintNftFunction(
                                                props,
                                                () => { },
                                                mint,
                                                mintData
                                              );
                                              if (isMinted) {
                                                setMintNo(
                                                  prevState => +prevState + mint
                                                );
                                              }
                                            } else {
                                              {
                                                props.headerAccount[0]
                                                  ? props.showAccountModal(true)
                                                  : setShowWalletType(true);
                                              }
                                            }
                                          }}
                                          className={classes.connect}
                                        >
                                          {props.headerAccount &&
                                            props.headerAccount[0]
                                            ? "MINT"
                                            : "CONNECT"}
                                        </Button>
                                      </Box>

                                      <Box
                                        className={
                                          classes.contractAddressContainer
                                        }
                                      >
                                        <h6>Contract:</h6>

                                        <span>{nftAddress}</span>
                                      </Box>
                                    </>
                                  ) : (
                                    <h3
                                      style={{
                                        color:
                                          props.displayType === "matrix"
                                            ? "#000"
                                            : ""
                                      }}
                                      className={classes.comingSoonLabel}
                                    >
                                      {" "}
                                      {props.comingSoonText}
                                    </h3>
                                  )}
                                </>
                              )}

                              <></>
                            </Box>
                          </>
                        )}

                      <Highlights data={value} />

                      <div
                        style={{
                          color: props.displayType === "matrix" ? "#fff" : ""
                        }}
                      >
                        <About
                          data={value}
                          messageAction={props.messageAction}
                          loadingAction={props.loadingAction}
                          headerAccountAction={props.headerAccountAction}
                          headerAccount={props.headerAccount}
                          nftfactoryContract={props.nftfactoryContract}
                          minterData={
                            props.landingsuccessData &&
                            props.landingsuccessData.airdropData.find(
                              elem => elem._id === value.mint.airdropId
                            ) &&
                            props.landingsuccessData &&
                            props.landingsuccessData.airdropData.find(
                              elem => elem._id === value.mint.airdropId
                            ).minterData
                          }
                          // nftData={props.marketPlaceList && value.mint.minterId && props.marketPlaceList.find(
                          //   elem => elem.nftContract._id === value.mint.minterId
                          // )}
                          waitingAction={props.waitingAction}
                        />
                      </div>
                    </Container>
                  )
                )}

              {/* stats  */}
              {props.landingsuccessData?.properties?.stats?.title?.value && (
                <Box
                  color={props.displayType === "matrix" ? "#fff" : ""}
                  bgcolor={
                    props.displayType != "matrix" &&
                    theme.palette?.alternate?.main
                  }
                >
                  <Container
                    id="statsScroll"
                    onClick={() => {
                      handleScrollParent("statsContainer");
                    }}
                    style={
                      showAnimation.statsScroll && check
                        ? {
                          border: "2px solid white",
                          padding: "20px",
                          borderRadius: "20px"
                        }
                        : null
                    }
                  >
                    <Main data={props.landingsuccessData} />
                  </Container>
                </Box>
              )}

              {/* roadMap */}
              {props.landingsuccessData?.properties?.roadmap?.title?.value && (
                <Box
                  color={props.displayType === "matrix" ? "#fff" : ""}
                  bgcolor={
                    props.displayType != "matrix" &&
                    theme.palette?.alternate?.main
                  }
                >
                  {props.landingsuccessData ? (
                    <Container>
                      <div
                        id="roadMap"
                        onClick={() => {
                          handleScrollParent("roadmapContainer");
                        }}
                        style={
                          showAnimation.roadMap && check
                            ? {
                              border: "2px solid white",
                              padding: "20px",
                              borderRadius: "20px"
                            }
                            : null
                        }
                      >
                        <Roadmap data={props.landingsuccessData} />
                      </div>
                    </Container>
                  ) : (
                    ""
                  )}
                </Box>
              )}

              {/* aboutUS */}
              {props.landingsuccessData?.properties?.aboutUS?.title?.value && (
                <Container
                  id="aboutUS"
                  onClick={() => {
                    handleScrollParent("aboutusContainer");
                  }}
                  style={
                    showAnimation.aboutUS && check
                      ? {
                        border: "2px solid white",
                        padding: "20px",
                        borderRadius: "20px"
                      }
                      : null
                  }
                >
                  <Team
                    data={props.landingsuccessData}
                    aboutUpModalShow={props.aboutUpModalShow}
                    modalShow={props.modalShow}
                    aboutusData={props.aboutusData}
                    AboutUsDataAction={props.AboutUsDataAction}
                    aboutusIndex={props.aboutusIndex}
                    messageAction={props.messageAction}
                  />
                </Container>
              )}

              {/* register User */}
              {props.landingsuccessData &&
                props.landingsuccessData.properties?.registerUser?.title
                  .value && (
                  <Box
                    color={props.displayType === "matrix" ? "#fff" : ""}
                    bgcolor={theme.palette.primary.dark}
                    id="registerUserScroll"
                    onClick={() => {
                      handleScrollParent("registerUserContainer");
                    }}
                    style={
                      showAnimation.registerUserScroll && check
                        ? {
                          border: "2px solid white",
                          padding: "20px",
                          borderRadius: "20px"
                        }
                        : null
                    }
                  >
                    <Container>
                      <Footer
                        data={props.landingsuccessData}
                        messageAction={props.messageAction}
                        registerUserAction={props.registerUserAction}
                      />
                    </Container>
                  </Box>
                )}

              {/* Solution */}
              {props.landingsuccessData?.properties?.solution?.title?.value && (
                <Container
                  color={props.displayType === "matrix" ? "#fff" : ""}
                  id="solutionScroll"
                  onClick={() => {
                    handleScrollParent("solutionContainer");
                  }}
                  style={
                    showAnimation.solutionScroll && check
                      ? {
                        border: "2px solid white",
                        padding: "20px",
                        borderRadius: "20px"
                      }
                      : null
                  }
                >
                  <Solutions data={props.landingsuccessData} />
                </Container>
              )}
            </div>
          ) : props.themeType === "SquareSupply" ? (
            // <SquareSupply props={props} />
            <></>
          ) : (
            // <Brentrice />
            <></>
          )}
        </>
      )}
      {showWalletType && (
        <Modal
          open={showWalletType}
          onClose={() => {
            setShowWalletType(false);
          }}
        >
          <div className={classes.modal3}>
            <div className={classes.innerModal3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  flexDirection: "column"
                }}
              >
                Select wallet you want to connect
              </div>
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={async () => {
                  onChangeWalletType("Metamask");
                  setShowWalletType(false);
                  await handleConnect(
                    "Metamask",
                    props.headerAccountAction,
                    props.headerNetworkIdAction,
                    props.setHeaderWalletType,
                    props.headerSetBalance,
                    props.messageAction
                  );
                }}
              >
                <img
                  src={metamask}
                  width="30px"
                  height="30px"
                  style={{ marginRight: "10px" }}
                />
                Metamask
              </div>
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={async () => {
                  onChangeWalletType("Coinbase");
                  setShowWalletType(false);
                  await handleConnect(
                    "Coinbase",
                    props.headerAccountAction,
                    props.headerNetworkIdAction,
                    props.setHeaderWalletType,
                    props.headerSetBalance,
                    props.messageAction
                  );
                }}
              >
                <img
                  src={coinbase}
                  width="30px"
                  height="30px"
                  style={{ marginRight: "10px" }}
                />
                Coinbase
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Box>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    waitingAction: values => {
      dispatch(waitingAction(values));
    },
    messageAction: data => {
      dispatch(messageAction(data));
    },
    landingPageData: data => {
      dispatch(landingPageData(data));
    },
    postLoginSuccess: data => {
      dispatch(postLoginSuccess(data));
    },
    aboutUpModalShow: data => {
      dispatch(aboutUpModalShow(data));
    },
    AboutUsDataAction: data => {
      dispatch(AboutUsData(data));
    },
    registerUserAction: data => {
      dispatch(registerUserAction(data));
    },
    loadingAction: data => {
      dispatch(loadingAction(data));
    },
    showAccountModal: data => {
      dispatch(showAccountModal(data));
    },
    headerAccountAction: data => {
      dispatch(headerAccountAction(data));
    },
    // getMarketPlaceListAction: values => {
    //   dispatch(getMarketPlaceListAction(values));
    // },
    headerNetworkIdAction: data => {
      dispatch(headerNetworkId(data));
    },
    headerSetBalance: data => {
      dispatch(headerSetBalance(data));
    },
    setHeaderWalletType: data => {
      dispatch(headerWalletType(data));
    },
    headerAccountAction: data => {
      dispatch(headerAccountAction(data));
    }
  };
};

const mapStateToProps = state => {
  return {
    displayType:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.displayType,
    galleryViewImageUrl:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.galleryViewImageUrl,
    // stateEditData: state.formData.editData?.properties?.header?.themeType,
    isMessage: state.landingPageData.isMessage,
    user: state.userData.user || { userId: "", isAdmin: false },
    stateLoading: state.landingPageData.isLoading,
    landingsuccessData: state.landingPageData.landingsuccessData,
    modalShow: state.landingPageData.modalShow,
    aboutusData: state.landingPageData.aboutusData,
    aboutusIndex: state.landingPageData.aboutusIndex,
    headerAccount: state.landingPageData.headerAccount,
    headerWalletType: state.landingPageData.headerWalletType,
    nftfactoryContract: state.landingPageData.nftfactoryContract,
    // marketPlaceList: state.marketPlaceMinter.marketPlaceList,
    themeType:
      state?.landingPageData?.landingsuccessData?.properties?.header?.themeType,
    seoTagsValue: state?.landingPageData?.landingsuccessData?.properties?.seo,
    headerTitle:
      state?.landingPageData?.landingsuccessData?.properties?.header?.title,
    customImage:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.customImageUrl,
    headerDesc:
      state?.landingPageData?.landingsuccessData?.properties?.header
        ?.description,
    roadmapData:
      state?.landingPageData?.landingsuccessData?.properties?.roadmap,
    mintData: state?.landingPageData?.landingsuccessData?.properties?.mintData,
    teamData:
      state?.landingPageData?.landingsuccessData?.properties?.aboutUs?.team,
    solutionData:
      state?.landingPageData?.landingsuccessData?.properties?.solution?.problem,
    twitterLink:
      state?.landingPageData?.landingsuccessData?.properties?.headerTabBar
        .headerIcon?.twitterUrl,
    openSeaLink:
      state?.landingPageData?.landingsuccessData?.properties?.header?.openSea
        ?.link,
    discordLink:
      state?.landingPageData?.landingsuccessData?.properties?.headerTabBar
        ?.headerIcon?.discordUrl,
    balance: state.landingPageData.balance,
    mintDataForPrice:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0],
    reviews:
      state?.landingPageData?.landingsuccessData?.properties?.reviews?.About,
    subdomainToRedirect: state.landingPageData.subdomainToRedirect,
    message: state.userData.message,

    mintDataForPrice:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0],
    singleMinterData:
      state?.landingPageData?.landingsuccessData?.properties?.marketPlace,

    mintDataForComingSoonText:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0]?.mint
        ?.minterId,

    comingSoonText:
      state?.landingPageData?.landingsuccessData?.properties?.mintData[0]
        ?.mintNft?.comingSoon
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
