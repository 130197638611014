import { MESSAGE_DATA, SET_REVIEW_LOADER, SET_USER_POLICY_RESPONSE } from "./constant";

const defaultState = {
  userPolicyResponse: "",
  reviewLoader: true
};

const ReviewUserPolicyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_POLICY_RESPONSE:
      return { ...state, userPolicyResponse: action.payload };
    case SET_REVIEW_LOADER:
      return { ...state, reviewLoader: action.payload };
    case MESSAGE_DATA:

      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export default ReviewUserPolicyReducer;
