import React from "react";
import { useHistory } from "react-router-dom";
import "../../index.css";

const NewChat = (props) => {
  const history = useHistory();

  return (
    <div
      className="sideMenuButton"
      style={{ marginTop: "80px" }}
      onClick={() => {
        props.addChatInListAction({ history: history });
      }}
    >
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
