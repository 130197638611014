import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { ConfigProvider } from "./components/HeaderLayout/contexts/ConfigContext";
import ThemeCustomization from "./components/HeaderLayout/themes";
import Locales from "./components/HeaderLayout/ui-component/Locales";
import store from "./store/store";

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <ThemeCustomization>
        <Locales>
          <App />
        </Locales>
      </ThemeCustomization>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
