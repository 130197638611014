import { FORGOT_PASSWORD_EMAIL,FORGET_VERIFY_EMAIL,FORGOT_VERIFICATION_PASSWORD } from "./constant"

export const forgetverifyEmail = (value) =>{
    
    return{
        type:FORGOT_PASSWORD_EMAIL,
        payload:value,
    }
}
export const forgotEmail = (value) =>{

    return{
        type:FORGET_VERIFY_EMAIL,
        payload:value,
    }
}
export const forgetverification = (value) =>{
    return{
        type:FORGOT_VERIFICATION_PASSWORD,
        payload:value,
    }
}